import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import { Fragment } from 'react/jsx-runtime';

import { columnComparator } from './comparator';
import { KeyedObject, TableProps } from './types';

export const MobileTable = <T extends KeyedObject>({
  columns,
  data,
  footer,
  loading,
}: TableProps<T>) => {
  const sortedColumns = columns.toSorted(columnComparator);

  if (loading || data.length === 0) {
    return (
      <div
        className={clsx(
          'sm:hidden p-3 border border-gray-200 rounded-lg',
          'hover:border-gray-500 text-gray-400',
          'flex justify-center items-center',
        )}
      >
        {loading && <Spinner />}
        {!loading && data.length === 0 && 'No data'}
      </div>
    );
  }

  return (
    <div className='space-y-4 sm:hidden'>
      {footer && (
        <div
          className={clsx(
            'flex justify-center text-sm text-gray-500',
            'bg-gray-100 p-2 rounded-lg sticky top-0',
          )}
        >
          {footer}
        </div>
      )}
      {data.map((datum, index) => {
        return (
          <div className='group' key={datum.id}>
            <div className='flex justify-end pe-2 text-xs italic'>
              <div
                className={clsx(
                  'px-2 bg-gray-100 rounded-t text-gray-400',
                  'group-hover:bg-gray-400 group-hover:text-gray-50',
                )}
              >
                {index + 1}
              </div>
            </div>
            <div
              className={clsx(
                'p-3 border border-gray-100 rounded-lg',
                'group-hover:border-gray-400 text-gray-700 text-sm',
                'grid grid-cols-3 gap-2 items-center group-hover:bg-gray-50',
              )}
            >
              {sortedColumns.map((column) => (
                <Fragment key={column.key}>
                  <div className='text-gray-400'>{column.title}</div>
                  <div className='col-span-2 overflow-x-auto text-pretty'>
                    {column.render(datum)}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
