import { FormSummary, FormType } from '@dakota/platform-client';
import { PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useCheckPermission } from 'auth/AuthHelper';
import PermissionGuard from 'auth/PermissionGuard';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Chip from 'components/Chip';
import ClearAllButton from 'components/ClearAll';
import Schedule from 'components/Inspections/Schedule';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import { ColumnProps } from 'components/Table/types';
import TemplatesMenu from 'components/TemplatesMenu';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import { getAllFormSummaries } from 'features/forms/formsActions';
import { formsSlice } from 'features/forms/formsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import Fuse from 'fuse.js';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import FormBuilderStepperDialog from 'Pages/FormBuilder/stepper/stepperDialog';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { formatBackendDateTime } from 'utils/date';
import { alphabeticalCompare } from 'utils/functional';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';
interface FacilityProps {
  id: string;
  inactive: boolean;
  name: string;
}
const InspectionsTemplates = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const appName = useSelector(configSlice.selectors.appName);
  const allForms = useSelector(formsSlice.selectors.forms);
  const allFacilities = useSelector(userSlice.selectors.accessibleFacilities);
  const loadingFacilities = useSelector(
    userSlice.selectors.isLoadingAccessibleFacilities,
  );
  const isAdminUser = useCheckPermission(Permission.Admin);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('Active');
  const [selectedArea, setSelectedArea] = useState('All');
  const [filteredForms, setFilteredForms] = useState<FormSummary[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<FacilityProps[]>(
    [],
  );
  const [selectableFacilities, setSelectableFacilities] = useState<
    FacilityProps[]
  >([]);
  const [isFormBuilderOpen, setIsFormBuilderOpen] = useState(false);

  const { stopTracking } = usePageLoadTracking();

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    setSelectableFacilities(
      allFacilities.toSorted(alphabeticalCompare((e) => e.name)),
    );
  }, [allFacilities]);

  useEffect(() => {
    let newForms = allForms;

    if (selectedStatus === 'Active') {
      newForms = newForms.filter((f) => !f.inactive);
    } else if (selectedStatus === 'Inactive') {
      newForms = newForms.filter((f) => f.inactive);
    }

    if (selectedArea === 'Global') {
      newForms = newForms.filter((f) => f.type === FormType.Global);
    } else if (selectedArea === 'Facility') {
      newForms = newForms.filter((f) => f.type === FormType.Facility);
    }

    let selectedFacilityForms = [];
    let globalForms = [];

    if (selectedFacilities.length > 0) {
      selectedFacilityForms = newForms.filter(
        (form) =>
          form.type !== FormType.Global &&
          selectedFacilities.find(
            (facility) => form.facility?.id === facility.id,
          ),
      );
      globalForms = newForms.filter((form) => form.type === FormType.Global);
      newForms = [...selectedFacilityForms, ...globalForms];
    }

    if (searchTerm) {
      const fuse = new Fuse(newForms, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: ['name'],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newForms = fuse.search(searchTerm).map((e) => e.item);
    }

    setFilteredForms(newForms);
  }, [allForms, searchTerm, selectedArea, selectedFacilities, selectedStatus]);

  const resetFilters = () => {
    setSearchTerm('');
    setSelectedFacilities([]);
    setSelectedStatus('Active');
    setSelectedArea('All');
  };

  useEffect(() => {
    void dispatch(getAllFormSummaries({ baseUrl, token })).then(() =>
      stopTracking(DataStatus.Fetched),
    );
  }, [token, dispatch, baseUrl, stopTracking]);

  const removeFacilityChip = (facility: FacilityProps) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const textGray400 = 'text-gray-400';
  const getInactiveClassName = (inactive: boolean) =>
    `flex flex-col space-y-1 ${inactive ? textGray400 : ''}`;

  const highlight = getHighlighter(searchTerm);
  const canSchedule = useCheckPermission(Permission.CreateInspection);

  let templateColumns: Array<ColumnProps<FormSummary>> = [
    {
      key: 'title',
      render: (record) => {
        return (
          <div className={getInactiveClassName(record.inactive)}>
            <div className='font-semibold'>{highlight(record.name)}</div>
            <div className='text-xs font-medium leading-tight text-gray-400'>
              <Tooltip
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={
                  record.lastUsed
                    ? 'The last inspection completed using this template was on ' +
                      formatBackendDateTime(record.lastUsed, false)
                    : 'No inspections have been completed using this template'
                }
              >
                <div className='inline-flex items-center gap-1'>
                  {record.lastUsed
                    ? 'Last used: ' +
                      formatBackendDateTime(record.lastUsed, false)
                    : 'Not used'}
                  <QuestionMarkCircleIcon className='w-4 h-4' />
                </div>
              </Tooltip>
            </div>
          </div>
        );
      },
      title: 'Title',
    },
    {
      key: 'description',
      render: (record) => {
        return (
          <div className={getInactiveClassName(record.inactive)}>
            <p>{record.description}</p>
          </div>
        );
      },
      title: 'Description',
    },
    {
      key: 'type',
      render: (record) => {
        if (record.type === FormType.Global) {
          return (
            <div className={getInactiveClassName(record.inactive)}>Global</div>
          );
        } else {
          return (
            <div className={getInactiveClassName(record.inactive)}>
              Facility
              <div className='hidden text-xs font-medium leading-tight md:block'>
                {record.facility ? record.facility.name : ''}
              </div>
            </div>
          );
        }
      },
      title: 'Area',
    },
    {
      key: 'inactive',
      render: (record) => {
        return (
          <div className={getInactiveClassName(record.inactive)}>
            {record.inactive ? 'Inactive' : 'Active'}
          </div>
        );
      },
      title: 'Status',
    },
    {
      key: 'schedule',
      render: (record) => (
        <div>
          <Schedule form={record} key={record.id} />
        </div>
      ),
      title: '',
    },
    {
      key: 'menu',
      render: (record) => (
        <div className='hidden sm:block'>
          <PermissionGuard
            permissions={[
              Permission.CreateGlobalForm,
              Permission.CreateFacilityForm,
            ]}
          >
            <TemplatesMenu form={record} />
          </PermissionGuard>
        </div>
      ),
      title: '',
    },
  ];

  if (!isAdminUser) {
    templateColumns = templateColumns.filter((c) => c.key !== 'inactive');
  }
  if (!canSchedule) {
    templateColumns = templateColumns.filter((c) => c.key !== 'schedule');
  }

  return (
    <div className='p-4 bg-white md:p-8' data-testid='templates'>
      <Helmet>
        <title>Inspection Templates | Manage Content | {appName}</title>
      </Helmet>
      <div className='justify-between hidden w-full pb-8 md:flex'>
        <h2 className='text-2xl font-medium' data-testid='templates-title'>
          Templates
        </h2>
        <div className='flex gap-2.5 items-center'>
          <PermissionGuard
            permissions={[
              Permission.CreateGlobalForm,
              Permission.CreateFacilityForm,
            ]}
          >
            <Button
              className='px-4 py-2 bg-green-base'
              icon={<PlusIcon />}
              onClick={() => setIsFormBuilderOpen(true)}
            >
              Add Template
            </Button>
          </PermissionGuard>
        </div>
      </div>
      <div className='filters-container'>
        <SearchInput
          className='w-full sm:w-[238px] h-[38px]'
          onSearch={(value) => handleSearch(value)}
          placeholder='Search by template title'
          value={searchTerm}
        />
        <Autocomplete
          className='w-full sm:w-[238px] h-[38px]'
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          id='facility-selector'
          label='Facilities'
          loading={loadingFacilities}
          multiple
          onChange={setSelectedFacilities}
          options={selectableFacilities}
          value={selectedFacilities}
        />
        <div className='hidden sm:block'>
          <Autocomplete
            className='w-28'
            id='area-selector'
            label='Area'
            onChange={(value) => setSelectedArea(value)}
            options={['All', 'Global', 'Facility']}
            value={selectedArea}
          />
        </div>
        {isAdminUser && (
          <div className='hidden sm:block'>
            <Autocomplete
              className='w-28'
              id='status-selector'
              label='Status'
              onChange={(value) => setSelectedStatus(value)}
              options={['All', 'Active', 'Inactive']}
              value={selectedStatus}
            />
          </div>
        )}
        {(selectedFacilities.length > 0 ||
          selectedStatus !== 'Active' ||
          selectedArea !== 'All' ||
          searchTerm.length > 0) && <ClearAllButton onClick={resetFilters} />}
      </div>
      {selectedFacilities.length > 0 && (
        <div className='flex items-center gap-2 pb-2'>
          <span className='text-sm font-normal pr-1'>Facilities:</span>
          {selectedFacilities.map((facility) => (
            <Chip
              key={facility.id}
              onRemove={() => removeFacilityChip(facility)}
              testId='facility-chip'
              text={facility.name}
            />
          ))}
        </div>
      )}
      <Table
        columns={templateColumns}
        data={filteredForms}
        footer={`${filteredForms.length} results`}
        id='templates-table'
        loading={useSelector(formsSlice.selectors.isLoadingForms)}
      />
      {isFormBuilderOpen && (
        <FormBuilderStepperDialog onClose={() => setIsFormBuilderOpen(false)} />
      )}
    </div>
  );
};

export default InspectionsTemplates;
