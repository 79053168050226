import {
  FacilityForm,
  Form,
  FormSummary,
  GlobalForm,
  Question,
  Section,
  Summary,
} from '@dakota/platform-client';
import { ArrowsUpDownIcon, TrashIcon } from '@heroicons/react/24/outline';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { clsx } from 'clsx';
import Button from 'components/Button';
import EditableText from 'components/EditableText';
import Tooltip from 'components/Tooltip';
import { getAnswerTypes } from 'features/answers/answersActions';
import { configSlice } from 'features/config/configSlice';
import {
  createFacilityForm,
  createGlobalForm,
  getForm,
  updateFacilityForm,
  updateGlobalForm,
} from 'features/forms/formsActions';
import { formsSlice } from 'features/forms/formsSlice';
import { getAllItemTypes } from 'features/items/itemTypesActions';
import { templatesSlice } from 'features/templates/templatesSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, forwardRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import Confirmation from '../../components/SimpleConfirmation';
import Header from './layout/header';
import Questions from './layout/questions';
import ReorderSections from './layout/reorderSections';
import Sections from './layout/sections';
import TemplateSummary from './layout/summary';

type Props = {
  /**
   * If present, it creates a facility-specific form.
   * Otherwise, a global form.
   * @default `undefined`
   */
  facility?: Summary;
  /**
   * If present, it will *edit* the form with this ID.
   * @default `undefined`
   */
  formId?: string;
  /**
   * Function to call when the dialog is closed.
   * It should hide and unmount this component.
   */
  onClose: () => void;
} & FormProps;

type FormProps =
  | {
      /**
       * If present, it creates a form from the recently added questions.
       * templateSummary cannot be present if questions are present.
       * Otherwise, the form is created from scratch (no initial questions).
       * @default `undefined`
       */
      questions?: Question[];
      templateSummary?: never;
    }
  | {
      questions?: never;
      /**
       * If present, it creates a form based on an existing template.
       * Questions cannot be present if templateSummary is present.
       * Otherwise, the form is created from scratch (no initial questions).
       * @default `undefined`
       */
      templateSummary?: FormSummary;
    };

/**
 * From an example in Material-UI's documentation.
 * See https://mui.com/material-ui/react-dialog/#full-screen-dialogs
 */
const Transition = forwardRef(function Transition(
  props: {
    children: React.ReactElement;
  } & TransitionProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const FormBuilder: FC<Props> = ({
  facility = undefined,
  formId = undefined,
  onClose,
  questions = undefined,
  templateSummary = undefined,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const appName = useSelector(configSlice.selectors.appName);

  const title = useSelector(templatesSlice.selectors.title);
  const description = useSelector(templatesSlice.selectors.description);
  const template = useSelector(formsSlice.selectors.form);

  const sections = useSelector(templatesSlice.selectors.sections);

  // List of all forms, to check that we're not repeating a title
  const allForms = useSelector(formsSlice.selectors.forms);
  const titleExists = allForms.some((f) => f.name === title);
  const titleErrorMessage =
    titleExists && title !== template?.name
      ? 'This title is already in use'
      : '';

  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [isReorderingSections, setIsReorderingSections] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { setErrorMessage, setSuccessMessage } = useToast();

  useEffect(
    () =>
      void Promise.all([
        dispatch(getAnswerTypes({ baseUrl, token })),
        dispatch(getAllItemTypes({ baseUrl, token })),
      ]),
    [baseUrl, dispatch, token],
  );

  useEffect(() => {
    dispatch(templatesSlice.actions.setFacility(facility));
    dispatch(templatesSlice.actions.setTemplateSummary(templateSummary));
  }, [dispatch, facility, templateSummary]);

  useEffect(() => {
    if (templateSummary) {
      void dispatch(getForm({ baseUrl, id: templateSummary.id, token }))
        .unwrap()
        .then((form) => dispatch(templatesSlice.actions.loadTemplate(form)));
    }
  }, [baseUrl, dispatch, setErrorMessage, templateSummary, token]);

  useEffect(() => {
    if (questions) {
      questions.forEach((question) =>
        dispatch(
          templatesSlice.actions.addQuestionToTemplate({
            question: {
              id: question.id,
              itemTypeId: question.itemType.id,
              itemTypeName: question.itemType.name,
              order: 0,
              text: question.text,
            },
          }),
        ),
      );
    }
  }, [dispatch, questions]);
  useEffect(() => {
    if (formId) {
      dispatch(getForm({ baseUrl, id: formId, token }))
        .unwrap()
        .then((form) =>
          dispatch(templatesSlice.actions.loadTemplateForEditing(form)),
        )
        .catch(() =>
          setErrorMessage('Failed to load the template. Please try again.'),
        );
    }
  }, [baseUrl, dispatch, formId, setErrorMessage, token]);

  const deleteAllSections = () => {
    dispatch(templatesSlice.actions.deleteAllSections());
    setShowDeleteConfirmation(false);
  };

  const setTemplateTitle = (value: string) => {
    dispatch(templatesSlice.actions.updateTitle(value));
  };

  const setTemplateDescription = (value: string) => {
    dispatch(templatesSlice.actions.updateDescription(value));
  };

  const buttonContainerClass = clsx(
    'border-l h-full flex items-center justify-end p-3',
    'has-[:hover]:bg-gray-100',
  );

  /**
   * Reset the template state and close the dialog.
   */
  const clearAndClose = () => {
    setIsSaving(false);
    dispatch(templatesSlice.actions.clearTemplate());
    onClose();
  };

  const saveTemplate = () => {
    setIsSaving(true);

    const form = {
      description,
      name: title,
      sections: sections.map(
        (section, order) =>
          ({
            header: section.header,
            itemType: { id: section.itemTypeId, name: section.itemTypeName },
            order,
            questions: section.questions.map((question, qOrder) => ({
              id: question.id,
              order: qOrder,
              text: question.text,
            })),
            showHeader: section.headerVisible,
          }) as Section,
      ),
    } as Form;
    if (facility) {
      const facilityForm = { ...form, facility } as FacilityForm;
      if (formId) {
        dispatch(
          updateFacilityForm({
            baseUrl,
            form: facilityForm,
            id: formId,
            token,
          }),
        )
          .unwrap()
          .then(() => {
            setSuccessMessage('Template updated successfully');
            clearAndClose();
          })
          .catch(() => {
            setIsSaving(false);
            setErrorMessage('Failed to update the template. Please try again.');
          });
      } else {
        dispatch(createFacilityForm({ baseUrl, form: facilityForm, token }))
          .unwrap()
          .then(() => {
            setSuccessMessage('Template created successfully');
            clearAndClose();
          })
          .catch(() => {
            setIsSaving(false);
            setErrorMessage('Failed to save the template. Please try again.');
          });
      }
    } else {
      const globalForm = GlobalForm.fromJS(form);
      if (formId) {
        dispatch(
          updateGlobalForm({ baseUrl, form: globalForm, id: formId, token }),
        )
          .unwrap()
          .then(() => {
            setSuccessMessage('Template updated successfully');
            clearAndClose();
          })
          .catch(() => {
            setIsSaving(false);
            setErrorMessage('Failed to update the template. Please try again.');
          });
      } else {
        dispatch(createGlobalForm({ baseUrl, form: globalForm, token }))
          .unwrap()
          .then(() => {
            setSuccessMessage('Template created successfully');
            clearAndClose();
          })
          .catch(() => {
            setIsSaving(false);
            setErrorMessage('Failed to save the template. Please try again.');
          });
      }
    }
  };

  /**
   * The template can be saved if all the mandatory components are present
   * and the title doesn't already exist in the list of forms.
   */
  let canSave =
    !formId && title !== '' && sections.length > 0 && !titleErrorMessage;

  /**
   * ...unless it's an edit, in which case the logic is a bit more complex.
   */
  if (formId && template) {
    canSave ||= template.name !== title || template.description !== description;

    canSave ||= template.sections.some(
      (ts) => !sections.some((s) => s.itemTypeId === ts.itemType.id),
    );

    canSave ||= sections.some((s) => {
      const existingSection = template.sections.find(
        (ts) => ts.itemType.id === s.itemTypeId,
      );
      if (!existingSection) {
        return true;
      }
      return (
        existingSection.header !== s.header ||
        existingSection.showHeader !== s.headerVisible ||
        existingSection.questions.length !== s.questions.length ||
        existingSection.order !== s.order ||
        s.questions.some((q) => {
          const existingQuestion = existingSection.questions.find(
            (eq) => eq.id === q.id,
          );
          if (!existingQuestion) {
            return true;
          }
          return (
            existingQuestion.text !== q.text ||
            existingQuestion.order !== q.order
          );
        })
      );
    });
  }

  /**
   * Close the dialog if there are no unsaved changes,
   * otherwise show a confirmation first.
   */
  const checkChangesAndClose = () => {
    if (canSave) {
      setShowCloseConfirmation(true);
    } else {
      clearAndClose();
    }
  };

  return (
    <Dialog
      data-testid='form-builder'
      disableEscapeKeyDown
      fullScreen
      onClose={checkChangesAndClose}
      open
      TransitionComponent={Transition}
    >
      <Helmet>
        <title>Builder | Templates | {appName}</title>
      </Helmet>
      <div className='flex flex-col h-screen'>
        <Header
          facility={facility}
          onClose={checkChangesAndClose}
          title={`${formId ? 'Edit' : 'Create New'} Inspection Template`}
        />
        <div className='flex-1 flex overflow-auto'>
          <Questions />
          <div className='flex-1 flex flex-col'>
            <div className='flex-none h-16 border-b p-3'>
              <EditableText
                containerClasses='w-full text-lg'
                errorMessage={titleErrorMessage}
                id='template-title'
                initialEditableValue={''}
                maxLength={50}
                onEdit={setTemplateTitle}
                required
                saveOnEnter
                styleAfterSave
                text={title || 'Give your template a title...'}
              />
            </div>
            <div className='flex-none h-16 flex items-center border-b'>
              <EditableText
                allowEmpty
                containerClasses='flex-1 text-sm p-3 w-64'
                id='template-description'
                initialEditableValue={''}
                maxLength={500}
                onEdit={setTemplateDescription}
                saveOnEnter
                showIcon={false}
                styleAfterSave
                text={description ?? 'Add an optional description...'}
              />
              <div className={buttonContainerClass}>
                <button
                  aria-label='Open template summary'
                  id='open-template-summary-button'
                  onClick={() => setIsSummaryOpen(true)}
                >
                  <Tooltip
                    placement='bottom-start'
                    title='View template summary'
                  >
                    <SummarizeOutlinedIcon
                      className={clsx(
                        'w-6 h-6 text-gray-400 hover:text-gray-700',
                      )}
                    />
                  </Tooltip>
                </button>
              </div>
              <div className={buttonContainerClass}>
                <button
                  aria-label='Reorder sections'
                  className='group'
                  disabled={sections.length < 2}
                  id='reorder-sections-button'
                  onClick={() => setIsReorderingSections(true)}
                >
                  <Tooltip placement='bottom-start' title='Reorder sections'>
                    <ArrowsUpDownIcon
                      className={clsx(
                        'w-6 h-6 text-gray-400 hover:text-gray-700',
                        'group-disabled:text-gray-200',
                        'group-disabled:hover:text-gray-300',
                        'group-disabled:cursor-not-allowed',
                      )}
                    />
                  </Tooltip>
                </button>
              </div>
              <div
                className={clsx(
                  'border-l h-full flex items-center justify-end p-3',
                  'has-[:hover]:bg-red-lightest',
                )}
              >
                <button
                  aria-label='Delete all sections'
                  className={'group'}
                  disabled={sections.length === 0}
                  id='delete-all-sections-button'
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  <Tooltip placement='bottom-start' title='Delete all sections'>
                    <TrashIcon
                      className={clsx(
                        'w-6 h-6 text-red-base hover:text-red-dark',
                        'group-disabled:text-red-lightest',
                        'group-disabled:hover:text-red-lighter',
                        'group-disabled:cursor-not-allowed',
                      )}
                    />
                  </Tooltip>
                </button>
              </div>
            </div>
            <div className='flex-1 overflow-y-auto overscroll-contain'>
              <Sections templateId={templateSummary?.id ?? formId} />
            </div>
            <div className='flex-none h-16 border-t p-3 flex gap-2 text-sm'>
              <Button
                disabled={!canSave || isSaving}
                id='save-form-builder'
                loading={isSaving}
                onClick={saveTemplate}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
              <Button
                id='cancel-form-builder'
                onClick={checkChangesAndClose}
                secondary
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isSummaryOpen && (
        <Dialog onClose={() => setIsSummaryOpen(false)} open>
          <TemplateSummary />
        </Dialog>
      )}
      {isReorderingSections && (
        <Dialog onClose={() => setIsReorderingSections(false)} open>
          <ReorderSections onClose={() => setIsReorderingSections(false)} />
        </Dialog>
      )}
      {showDeleteConfirmation && (
        <Confirmation
          cancelText='Continue editing the template'
          confirmText='Delete all'
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={deleteAllSections}
          title='Delete all questions?'
        >
          This will remove the questions from the template.
        </Confirmation>
      )}
      {showCloseConfirmation && (
        <Confirmation
          cancelText='Continue editing the template'
          confirmText='Close without saving'
          onCancel={() => setShowCloseConfirmation(false)}
          onConfirm={clearAndClose}
          title='You have unsaved changes'
        >
          If you close now, the changes you made to the template will be lost.
        </Confirmation>
      )}
    </Dialog>
  );
};

export default FormBuilder;
