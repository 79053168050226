import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import NavIcon from 'components/NavIcon';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';
import logo from '../../assets/Dakota-Logo.png';
import Navigation from './Navigation';

const { theme } = resolveConfig(tailwindConfig);

type Props = {
  isOpen: boolean;
  toggleSidebar: () => void;
};

const SidebarDesktop = ({ isOpen, toggleSidebar }: Props) => (
  <div
    className={clsx(
      isOpen ? 'w-56 shrink-0' : 'w-20',
      'px-2 py-4 hidden sm:flex justify-between',
      'flex-col transition-all',
    )}
  >
    <div>
      <img
        alt='Dakota Software'
        className={`w-${isOpen ? '36' : '14'}
            ${isOpen ? 'mb-5' : 'mb-2 sm:mb-6'} mx-auto`}
        src={logo}
      />
      <Navigation isSidebarOpen={isOpen} />
    </div>
    <div className='w-full'>
      <button
        className={clsx(
          'flex w-full items-center',
          isOpen ? 'justify-end' : 'justify-center',
        )}
        onClick={toggleSidebar}
      >
        {isOpen ? (
          <NavIcon
            expanded
            icon={ArrowLeftIcon}
            iconColor={theme.colors.green.base}
            title='Close'
          />
        ) : (
          <NavIcon
            icon={ArrowRightIcon}
            iconColor={theme.colors.green.base}
            title='Open'
          />
        )}
      </button>
    </div>
  </div>
);

export default SidebarDesktop;
