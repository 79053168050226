import { TaskInstance, TaskInstanceDetails } from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';

import { getTaskDetails, listTasks } from './tasksActions';

type TasksState = {
  /**
   * True when fetching task details from the `getTask` API.
   */
  isLoadingTask: boolean;
  /**
   * True when fetching tasks from the `listTasks` API.
   */
  isLoadingTasks: boolean;
  /**
   * The details object for a Task.
   */
  task?: TaskInstanceDetails;
  /**
   * The list of tasks as returned by the `listTasks` API.
   */
  tasks: TaskInstance[];
};

const initialState: TasksState = {
  isLoadingTask: false,
  isLoadingTasks: false,
  task: undefined,
  tasks: [],
};

export const tasksSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getTaskDetails.fulfilled, (state, action) => {
      state.isLoadingTask = false;
      state.task = action.payload;
    });
    builder.addCase(getTaskDetails.pending, (state) => {
      state.isLoadingTask = true;
    });
    builder.addCase(getTaskDetails.rejected, (state) => {
      state.isLoadingTask = false;
    });
    builder.addCase(listTasks.fulfilled, (state, action) => {
      state.isLoadingTasks = false;
      state.tasks = action.payload;
    });
    builder.addCase(listTasks.pending, (state) => {
      state.isLoadingTasks = true;
    });
    builder.addCase(listTasks.rejected, (state) => {
      state.isLoadingTasks = false;
    });
  },
  initialState,
  name: 'tasks',
  reducers: {},
  selectors: {
    isLoadingTasks: (state: TasksState) => state.isLoadingTasks,
    tasks: (state: TasksState) => state.tasks,
  },
});
