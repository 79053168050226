import { Question } from '@dakota/platform-client';
import { PencilIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import MenuDropdown from 'components/MenuDropdown';
import Toggle from 'components/Toggle';
import { configSlice } from 'features/config/configSlice';
import {
  activateQuestion,
  deactivateQuestion,
} from 'features/questions/questionsActions';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import AddEditQuestions from '../AddEditQuestions';

type Props = {
  question: Question;
};

const QuestionsMenu = ({ question }: Props) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false);
  const [inactive, setInactive] = useState(question.inactive);

  const toggleActive = (event: boolean) => {
    setInactive(event);

    if (event) {
      dispatch(activateQuestion({ baseUrl, id: question.id, token }))
        .unwrap()
        .then(() => setSuccessMessage('Question activated successfully'))
        .catch(() => setErrorMessage('Failed to activate question'));
    } else {
      dispatch(deactivateQuestion({ baseUrl, id: question.id, token }))
        .unwrap()
        .then(() => setSuccessMessage('Question deactivated successfully'))
        .catch(() => setErrorMessage('Failed to deactivate question'));
    }
  };

  return (
    <div>
      <MenuDropdown buttonTestId='questions-menu-button'>
        <div
          className={clsx(
            'bg-white w-44 flex flex-col p-4 gap-3',
            'rounded-md shadow-md text-sm font-medium text-gray-700',
          )}
        >
          <button
            className={`flex items-center gap-2 ${
              question.isPlatformDefined
                ? 'text-gray-300 cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            disabled={question.isPlatformDefined}
            onClick={() => setIsSidepanelOpen(true)}
          >
            <PencilIcon className='w-4' />
            Edit
          </button>
          <PermissionGuard permissions={Permission.DeactivateQuestion}>
            {question.itemType.inactive && (
              <Tooltip
                arrow
                placement='top'
                title='This question cannot be reactivated because the item type it
              is associated with is disabled. Reactivating the item type will allow you to reactivate the question.'
              >
                <div>
                  <Toggle
                    disabled={question.itemType.inactive}
                    id='question-status-toggle'
                    isOn={!inactive}
                    label='Active'
                    onToggle={toggleActive}
                    showIcon={true}
                  />
                </div>
              </Tooltip>
            )}
            {!question.itemType.inactive && (
              <Toggle
                disabled={question.isPlatformDefined}
                id='question-status-toggle'
                isOn={!inactive}
                label='Active'
                onToggle={toggleActive}
                showIcon={true}
              />
            )}
          </PermissionGuard>
        </div>
      </MenuDropdown>
      {isSidepanelOpen && (
        <AddEditQuestions
          isOpen={isSidepanelOpen}
          question={question}
          setIsOpen={setIsSidepanelOpen}
        />
      )}
    </div>
  );
};

export default QuestionsMenu;
