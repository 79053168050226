import { BellSlashIcon } from '@heroicons/react/24/outline';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { clsx } from 'clsx';
import { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Notification } from './Notifications';

type Props = {
  notifications: Notification[];
  onClose: () => void;
};

export const NotificationsPanel: FC<Props> = ({
  notifications,
  onClose,
}: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className={clsx(
        'max-sm:w-80 sm:w-96 flex flex-col',
        '*:px-4 *:py-2 text-gray-700 divide-y text-sm',
      )}
    >
      <div className='flex justify-between items-center text-lg font-semibold'>
        <div>Notifications</div>
        <IconButton
          aria-label='Close notifications'
          onClick={onClose}
          size='small'
        >
          <CloseIcon />
        </IconButton>
      </div>
      {notifications.length === 0 && (
        <div className='flex flex-col items-center text-gray-300'>
          <BellSlashIcon className='w-12 h-12' />
          No notifications yet
        </div>
      )}
      {notifications.map((notification) => {
        const content = (
          <Fragment key={notification.key}>
            <div>{notification.message}</div>
            <div className='flex justify-end text-xs'>
              {typeof notification.cta.link === 'string' && (
                <a
                  className='text-blue-base hover:text-blue-dark'
                  href={notification.cta.link}
                  onClick={onClose}
                >
                  {notification.cta.text}
                </a>
              )}
            </div>
          </Fragment>
        );
        return (
          <button
            aria-label='Notification action'
            className={clsx('block text-left space-y-2 hover:bg-gray-100', {
              'bg-red-lightest hover:bg-red-lighter':
                notification.severity === 'warning',
            })}
            key={notification.key}
            onClick={() => {
              typeof notification.cta.link === 'string'
                ? navigate(notification.cta.link)
                : notification.cta.link();
              onClose();
            }}
          >
            {content}
          </button>
        );
      })}
    </div>
  );
};
