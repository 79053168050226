import { Summary } from '@dakota/platform-client';
import { configSlice } from 'features/config/configSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { listZones } from 'features/zones/zonesActions';
import { zonesSlice } from 'features/zones/zonesSlice';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

const useFetchZones = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const zones = useSelector(zonesSlice.selectors.zonesPerFacility);
  const isLoadingAllZones = useSelector(zonesSlice.selectors.isLoadingAllZones);

  const fetchAndFilterZones = useCallback(
    async (facilityId: string, zoneIds: string[]): Promise<Summary[]> => {
      if (!zones.has(facilityId)) {
        await dispatch(listZones({ baseUrl, facilityId, token }));
      }

      const zonesForFacility = zones.get(facilityId) ?? [];
      const filteredZones = zonesForFacility.filter((zone) =>
        zoneIds.includes(zone.id),
      );

      const zoneSummaries = filteredZones.map((zone) => ({
        id: zone.id,
        inactive: zone.inactive,
        name: zone.name,
      }));

      return zoneSummaries as Summary[];
    },
    [zones, baseUrl, token, dispatch],
  );

  return {
    fetchAndFilterZones,
    isLoadingAllZones,
  };
};

export default useFetchZones;
