import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ToastState = {
  errorMessage: string;
  infoMessage: string;
  successMessage: string;
  warningMessage: string;
};

const initialState: ToastState = {
  errorMessage: '',
  infoMessage: '',
  successMessage: '',
  warningMessage: '',
};

export const toastSlice = createSlice({
  initialState,
  name: 'toast',
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setInfoMessage: (state, action: PayloadAction<string>) => {
      state.infoMessage = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
    },
    setWarningMessage: (state, action: PayloadAction<string>) => {
      state.warningMessage = action.payload;
    },
  },
  selectors: {
    errorMessage: (state: ToastState) => state.errorMessage,
    infoMessage: (state: ToastState) => state.infoMessage,
    successMessage: (state: ToastState) => state.successMessage,
    warningMessage: (state: ToastState) => state.warningMessage,
  },
});
