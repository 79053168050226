import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Skeleton } from '@mui/material';
import { clsx } from 'clsx';
import Legend from 'components/Legend';
import { Spinner } from 'components/Spinner';
import { Chart } from 'react-google-charts';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

type Props = {
  data: (number | string)[][];
  loading?: boolean;
  type: 'results' | 'status';
};

const DashboardPieChart = ({ data, loading = false, type }: Props) => {
  if (loading) {
    return (
      <div className='flex flex-col gap-2 items-center'>
        <Skeleton height={210} variant='circular' width={210} />
        <Skeleton height={28} variant='rounded' width={120} />
        <Skeleton height={28} variant='rounded' width={120} />
      </div>
    );
  }

  let noDataText = 'No inspections scheduled during the selected date range.';
  if (type === 'results') {
    noDataText = 'No inspections completed during the selected date range.';
  }

  if (data.slice(1).every((d) => d[1] === 0)) {
    return (
      <div className='h-full flex flex-col justify-center items-center'>
        <InformationCircleIcon className='w-24 h-24 text-green-base' />
        <div className='text-lg font-light text-gray-700 text-center min-h-16'>
          {noDataText}
        </div>
      </div>
    );
  }

  const colors = [
    theme.colors.green.base,
    theme.colors.yellow.base,
    theme.colors.red.base,
    theme.colors.gray[400],
    theme.colors.gray[300],
  ];

  const labelMapping = {
    result: ['Pass', 'Warning', 'Fail', 'N/A'],
    status: ['Completed', 'In Progress', 'Overdue', 'Pending', 'Canceled'],
  };

  const selectedLabels =
    type === 'status' ? labelMapping.status : labelMapping.result;
  const maxLabels = Math.min(colors.length, selectedLabels.length);

  const labels = colors.slice(0, maxLabels).map((color, index) => ({
    color,
    label:
      type === 'status'
        ? labelMapping.status[index]
        : labelMapping.result[index],
  }));

  // https://developers.google.com/chart/interactive/docs/gallery/piechart#configuration-options
  const options = {
    backgroundColor: 'transparent',
    chartArea: { height: '92%' },
    colors,
    legend: { position: 'none' },
    pieSliceText: 'none',
    width: 320,
  };

  return (
    <div
      className={clsx(
        'h-full flex flex-col gap-3 items-center justify-center',
        'google-pie-chart' /* for flickering fix, see index.css */,
      )}
    >
      <Chart
        chartType='PieChart'
        data={data}
        loader={<Spinner />}
        options={options}
      />
      <Legend labels={labels} />
    </div>
  );
};

export default DashboardPieChart;
