import { clsx } from 'clsx';
import { FC, useLayoutEffect, useState } from 'react';
import Datepicker, {
  Configs,
  DateValueType,
  PopoverDirectionType,
} from 'react-tailwindcss-datepicker';

import { shortcutsInTheFuture, shortcutsInThePast } from './shortcuts';

interface DatePickerProps {
  asSingle?: boolean;
  disabled?: boolean;
  /**
   * Used both for ID and data-testid on the container.
   * @default 'datepicker-container'
   */
  id?: string;
  onChange: (newValue: DateValueType) => void;
  placeholder?: string;
  /**
   * @default 'down'
   */
  popoverDirection?: PopoverDirectionType;
  /**
   * @default `true`
   */
  scrollOnFocus?: boolean;
  /**
   * Used to determine the type of shortcuts to show (next or last with today
   * always being returned) Set to last by default
   * @default 'last'
   */
  shortcutsType?: 'last' | 'next';
  /**
   * When `true`, it displays date range shortcuts
   * (e.g. "Today", "Last 7 days", "Last 30 days", "Next 7 days", "Next 30
   * days"). Only useful when `useRange` is `true`.
   */
  showShortcuts?: boolean;
  /**
   * @default `false`
   */
  useRange?: boolean;
  value: DateValueType;
}

const DatePicker: FC<DatePickerProps> = ({
  asSingle,
  disabled,
  id = 'datepicker-container',
  onChange,
  placeholder = undefined,
  popoverDirection = 'down',
  scrollOnFocus = true,
  shortcutsType = 'last',
  showShortcuts = false,
  useRange = false,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Per requirement from product, our shortcuts are either:
   *   > Today
   *   > Last 7 days
   *   > Last 30 days
   *   > Last 60 days
   *   > Last 365 days
   * or:
   *   > Today
   *   > Next 7 days
   *   > Next 30 days
   *   > Next 60 days
   *   > Next 365 days
   */
  const shortcuts =
    shortcutsType === 'last' ? shortcutsInThePast : shortcutsInTheFuture;

  const configs: Configs = {
    shortcuts,
  };

  let focusProps = {};
  if (scrollOnFocus) {
    focusProps = {
      onFocus: () =>
        document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' }),
    };
  }

  useLayoutEffect(() => {
    if (!id) {
      return;
    }

    /**
     * This query will return the actual element that contains
     * the floating calendar, which is opened when the user clicks
     * on the input or the toggle button of the DatePicker.
     *
     * This is the 3rd and last element inside the main container
     * provided by the DatePicker component, which has this structure:
     *
     *   <div id={id}>      <-- we create this one below
     *     <div>            <-- main div from DatePicker
     *       <input>        <-- input where the user types the date
     *       <button>       <-- calendar "toggle"
     *       <div>          <-- *** what we're capturing here ***
     *     </div>
     *   </div>
     */
    const calendar = document.evaluate(
      `//*[@id="${id}"]/div/div`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
    ).singleNodeValue as Node;

    /**
     * Callback to pass to a "mutation observer".
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
     */
    const onCalendarMutated: MutationCallback = (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          const classes = (mutation.target as HTMLDivElement).classList;
          // If we're here, it means a class was added or removed
          // from the calendar container. If the list of classes contains
          // 'opacity-0', it means the calendar was just closed,
          // so we set the 'open' state to false. If it contains
          // 'opacity-1', the calendar was just opened so we set the
          // state to true, which will enable the custom height as well.
          if (classes.contains('opacity-0')) {
            setIsOpen(false);
          } else if (classes.contains('opacity-1')) {
            setIsOpen(true);
          }
        }
      }
    };

    const observer = new MutationObserver(onCalendarMutated);

    /**
     * Start observing changes to the calendar's class attribute.
     * The callback will be executed when the class attribute changes.
     */
    observer.observe(calendar, { attributeFilter: ['class'] });

    return () => observer.disconnect();
  }, [id]);

  return (
    <div
      {...focusProps}
      className={clsx('transition-all', isOpen ? 'h-[400px]' : 'h-9')}
      data-testid={id}
      id={id}
    >
      <Datepicker
        asSingle={asSingle}
        configs={configs}
        containerClassName={`relative rounded-md ${useRange ? 'w-64' : ''}`}
        disabled={disabled}
        displayFormat={'MM/DD/YYYY'}
        inputClassName={clsx(
          'w-full h-full px-3 border border-gray-300 rounded-md font-sans',
          'text-gray-700 disabled:opacity-40 disabled:cursor-not-allowed',
          'focus:outline-0 focus:outline-none focus:ring-1',
          'focus:ring-blue-base focus:border-blue-base',
        )}
        onChange={onChange}
        placeholder={placeholder}
        popoverDirection={popoverDirection}
        primaryColor='emerald' // See tailwind.config.ts for details
        readOnly={true}
        showShortcuts={showShortcuts}
        toggleClassName={clsx(
          'absolute bg-gray-50 rounded-r-md text-black right-0 h-full px-3',
          'border border-gray-300 focus:border-1',
          'disabled:opacity-40 disabled:cursor-not-allowed',
        )}
        useRange={useRange}
        value={value}
      />
    </div>
  );
};

export default DatePicker;
