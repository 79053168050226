import { Inspection, InspectionStatus, Summary } from '@dakota/platform-client';
import { MapIcon } from '@heroicons/react/24/outline';
import { Dialog, Tooltip } from '@mui/material';
import { useCheckPermission } from 'auth/AuthHelper';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import { configSlice } from 'features/config/configSlice';
import { getAllFacilities } from 'features/facilities/facilitiesActions';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { updateInspection } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { zonesSlice } from 'features/zones/zonesSlice';
import useToast from 'hooks/useToast';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import ReorderZones from './ReorderZones';

type Props = {
  inspection: Inspection;
};

export const Rezone = ({ inspection }: Props) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const allFacilities = useSelector(facilitiesSlice.selectors.allFacilities);
  const facility = allFacilities.find((f) => f.id === inspection.facility.id);
  const orderedZones = useSelector(zonesSlice.selectors.orderedZones);
  const isLoadingZones = useSelector(zonesSlice.selectors.isLoadingAllZones);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const canUpdateInspection = useCheckPermission(Permission.UpdateInspection);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectableZones, setSelectableZones] = useState<Summary[]>([]);
  const [isZoneMenuOpen, setIsZoneMenuOpen] = useState(false);

  const canListFacilities = useCheckPermission(Permission.ReadFacility);

  const isDisabled =
    !canUpdateInspection ||
    inspection.status === InspectionStatus.InProgress ||
    inspection.status === InspectionStatus.Completed ||
    inspection.status === InspectionStatus.Canceled ||
    !facility?.zones;

  useEffect(() => {
    if (facility?.zones) {
      setSelectableZones(facility.zones.filter((zone) => !zone.inactive));
    }
  }, [facility?.zones]);

  useEffect(() => {
    if (!facility && canListFacilities) {
      void dispatch(getAllFacilities({ baseUrl, token }));
    }
  }, [baseUrl, canListFacilities, dispatch, facility, token]);

  const setZones = (zones: Summary[]) => {
    dispatch(zonesSlice.actions.setZones(zones));
  };

  const handleSave = () => {
    setIsUpdating(true);

    const updateData = {
      baseUrl,
      id: inspection.id,
      scheduledDate: inspection.timeline.scheduledDate.toString(),
      token,
      userId: inspection.userId,
      zones: orderedZones.map((zone) => zone.id),
    };
    dispatch(updateInspection(updateData))
      .unwrap()
      .then(() => {
        setSuccessMessage('Inspection zones updated successfully');
        setIsDialogOpen(false);
      })
      .catch(() => {
        setErrorMessage('Failed to update inspection zones');
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setZones([]);
  };

  return (
    <>
      <button
        aria-label='Rezone Inspection'
        className={clsx(
          'flex items-center gap-3 cursor-pointer text-gray-700',
          'disabled:cursor-not-allowed disabled:text-gray-300',
        )}
        data-testid='rezone-inspection-button'
        disabled={isDisabled}
        onClick={() => setIsDialogOpen(true)}
      >
        <Tooltip arrow placement='top' title='Rezone Inspection'>
          <MapIcon className='w-4' />
        </Tooltip>
        Rezone
      </button>
      <Dialog
        fullWidth
        maxWidth={orderedZones.length > 1 ? 'md' : 'sm'}
        onClose={handleClose}
        open={isDialogOpen}
      >
        <div
          className={clsx('flex flex-col gap-3 p-8', {
            'min-h-[540px]': isZoneMenuOpen,
          })}
        >
          <div className='flex-none text-lg font-medium'>Rezone Inspection</div>
          <div className='flex-1 flex'>
            <div className='flex-none gap-2 space-y-4 w-96'>
              <div className='flex gap-2'>
                <Button
                  className='text-xs'
                  disabled={orderedZones.length === selectableZones.length}
                  onClick={() => setZones(selectableZones)}
                  secondary
                >
                  Select all
                </Button>
                <Button
                  className='text-xs'
                  disabled={orderedZones.length === 0}
                  onClick={() => setZones([])}
                  secondary
                >
                  Clear selection
                </Button>
              </div>
              <Autocomplete
                getOptionKey={(zone) => zone.id}
                getOptionLabel={(zone) => zone.name}
                id='zone-selector'
                isOptionEqualToValue={(zone, value) => zone.id === value.id}
                label='Zone(s)'
                loading={isLoadingZones}
                multiple
                onChange={setZones}
                onClose={() => setIsZoneMenuOpen(false)}
                onOpen={() => setIsZoneMenuOpen(true)}
                options={selectableZones}
                value={orderedZones}
              />
            </div>
            <div className='flex-none'>
              <ReorderZones inspection={inspection} />
            </div>
          </div>
          <div className='flex-none'>
            <Button
              className='self-start'
              data-testid='schedule-button'
              disabled={!canUpdateInspection}
              loading={isUpdating}
              onClick={handleSave}
            >
              {isUpdating ? 'Updating...' : 'Save'}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
