import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import Auth0ProviderWithHistory from 'auth/Auth0ProviderWIthHistory';
import { CacheBuster } from 'cacheBuster';
import Loading from 'components/Loading';
import { OnError } from 'OnError';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { useInterval } from 'usehooks-ts';
import versionInfo from 'version.json';

import { fetchConfig } from '../src/features/config/configActions';
import App from './App';
import { persistor, store } from './store/store';
import { appInsights } from './telemetry/service';

const isProduction = process.env.NODE_ENV === 'production';
const configCheckInterval = 5 * 60 * 1000; // 5 minutes in ms

// cannot await top-level statements
void store.dispatch(fetchConfig());

export const Root = () => {
  // refresh on an interval BUT also allow the user to trigger it out-of-band
  const refreshConfig = async () => {
    try {
      return await store.dispatch(fetchConfig()).unwrap();
    } catch (error) {
      console.error('Error fetching configuration:', error);
      throw error;
    }
  };

  useInterval(() => void refreshConfig(), configCheckInterval);

  return (
    <AppInsightsErrorBoundary appInsights={appInsights} onError={OnError}>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <CacheBuster
            autoReloadEnabled={isProduction}
            currentVersion={versionInfo.version}
            forceCheck={refreshConfig}
          >
            <AppInsightsContext.Provider value={appInsights}>
              <BrowserRouter>
                <Auth0ProviderWithHistory>
                  <HelmetProvider>
                    <App />
                  </HelmetProvider>
                </Auth0ProviderWithHistory>
              </BrowserRouter>
            </AppInsightsContext.Provider>
          </CacheBuster>
        </PersistGate>
      </Provider>
    </AppInsightsErrorBoundary>
  );
};
