import { InspectionSection } from '@dakota/platform-client';
import Tooltip from 'components/Tooltip';

import {
  countAnsweredQuestionsInSections,
  countTotalQuestionsInSections,
} from './inspectionSlice';

/**
 * Return the count of answered questions and total questions
 * in a collection of sections.
 */
export const countSectionQuestions = (sections: InspectionSection[]) => ({
  answered: countAnsweredQuestionsInSections(sections),
  total: countTotalQuestionsInSections(sections),
});

/**
 * Return a string representing the count of answered questions,
 * in the format "answered/total".
 */
export const sectionCounts = (sections: InspectionSection[]) => {
  const { answered, total } = countSectionQuestions(sections);
  return (
    <Tooltip arrow title={`${answered} answered questions, ${total} total`}>
      <div>
        {answered}/{total}
      </div>
    </Tooltip>
  );
};
