import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Loading';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Login: FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate('/');
    }
    if (!isLoading && !isAuthenticated) {
      void loginWithRedirect({ appState: { returnTo: '/' } });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, navigate]);

  return <Loading />;
};

export default Login;
