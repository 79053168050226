import {
  InspectionDetails,
  InspectionStatus,
  isZonedInspection,
} from '@dakota/platform-client';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Fade } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatBackendDate } from 'utils/date';

import logo from '../../assets/DakotaLogo_wht.png';
import { getStatusText } from './format';

type HeaderProps = {
  inspectionDetails: InspectionDetails;
  onClose: () => void;
};

export const Header: FC<HeaderProps> = ({ inspectionDetails, onClose }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const totalAnsweredQuestions = useSelector(
    inspectionSlice.selectors.totalAnsweredQuestions,
  );
  const totalQuestions = useSelector(inspectionSlice.selectors.totalQuestions);

  return (
    <div
      className={clsx(
        'flex-none p-2 sm:px-8 bg-green-base text-white',
        'flex flex-col justify-center',
      )}
    >
      <div
        className={clsx(
          'flex-none h-12 sm:h-16 *:flex',
          'flex justify-between items-center gap-4',
        )}
      >
        <img
          alt='Dakota Logo'
          className='flex-none w-28 max-sm:w-16'
          src={logo}
        />
        <div className='flex-1 flex-col items-start overflow-hidden'>
          <div className='w-full sm:text-lg text-pretty'>
            {inspectionDetails.form.name}
          </div>
          <div className='text-xs font-light hidden sm:flex gap-4'>
            <div>
              <span className='font-semibold'>Facility:</span>{' '}
              {inspectionDetails.facility.name}
            </div>
            <div>
              <span className='font-semibold'>Due Date:</span>{' '}
              {formatBackendDate(inspectionDetails.timeline.scheduledDate)}
            </div>
            <div>
              <span className='font-semibold'>Inspector:</span>{' '}
              {inspectionDetails.userName}
            </div>
            <div>
              <span className='font-semibold'>Status:</span>{' '}
              {getStatusText(inspectionDetails.status)}
            </div>
            {inspectionDetails.status === InspectionStatus.Completed && (
              <div>
                <span className='font-semibold'>Completed on:</span>{' '}
                {formatBackendDate(
                  inspectionDetails.timeline.endDate as string,
                )}
              </div>
            )}
          </div>
        </div>
        <div className='flex-none flex-row items-center'>
          {totalQuestions > 0 && (
            <div className='flex-none flex flex-col items-end'>
              <div className='text-base sm:text-xl flex justify-end gap-2'>
                {totalAnsweredQuestions}/{totalQuestions}
                <button
                  aria-label='Show inspection details'
                  className='block sm:hidden transition-all'
                  onClick={() => setDetailsOpen((pre) => !pre)}
                >
                  {detailsOpen ? (
                    <ChevronUpIcon className='w-5 h-5' />
                  ) : (
                    <ChevronDownIcon className='w-5 h-5' />
                  )}
                </button>
              </div>
              <div className='text-xs font-normal hidden sm:block'>
                Answered Questions
              </div>
            </div>
          )}
          <div className='pl-4 flex-none hidden sm:flex justify-center'>
            <button aria-label='Close' onClick={onClose}>
              <XMarkIcon className='w-8 h-8' />
            </button>
          </div>
        </div>
      </div>
      <div className='sm:hidden'>
        <Fade in={detailsOpen} unmountOnExit>
          <div
            aria-label='Inspection details'
            className='flex flex-col gap-4 mt-4'
          >
            <div
              className={clsx(
                'grid grid-cols-3 gap-x-2 gap-y-0.5 text-sm even:*:col-span-2',
                'odd:*:font-semibold even:*:font-light',
              )}
            >
              <div>Facility</div>
              <div>{inspectionDetails.facility.name}</div>
              {isZonedInspection(inspectionDetails) && (
                <>
                  <div>Zones</div>
                  <div className='*:list-decimal *:list-inside'>
                    {inspectionDetails.zones.map((zone) => (
                      <li key={zone.id}>{zone.name}</li>
                    ))}
                  </div>
                </>
              )}
              <div>Due Date</div>
              <div>
                {formatBackendDate(inspectionDetails.timeline.scheduledDate)}
              </div>
              <div>Inspector</div>
              <div>{inspectionDetails.userName}</div>
              <div>Status</div>
              <div>{getStatusText(inspectionDetails.status)}</div>
              {inspectionDetails.status === InspectionStatus.Completed && (
                <>
                  <div>Completed on</div>
                  <div>
                    {formatBackendDate(
                      inspectionDetails.timeline.endDate as string,
                    )}
                  </div>
                </>
              )}
              {inspectionDetails.status === InspectionStatus.InProgress && (
                <>
                  <div>Questions</div>
                  <div>
                    {totalAnsweredQuestions} answered,{' '}
                    {totalQuestions - totalAnsweredQuestions} pending
                  </div>
                </>
              )}
            </div>
            <div>
              <Button className='text-xs' onClick={onClose} secondary>
                Close inspection
              </Button>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
