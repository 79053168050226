import { FileType, PromptMedia } from '@dakota/platform-client';
import {
  ArrowDownOnSquareIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import Button from 'components/Button';
import { Spinner } from 'components/Spinner';
import { configSlice } from 'features/config/configSlice';
import { getFile } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

type AttachmentContentProps = {
  attachment: PromptMedia;
  downloadAttachment: () => void;
};

export const AttachmentContent: FC<AttachmentContentProps> = ({
  attachment,
  downloadAttachment,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const [loading, setLoading] = useState(false);
  const [forceDownloadButton, setForceDownloadButton] = useState(false);

  const getFileByAttachmentId = useSelector(
    inspectionSlice.selectors.getFileByAttachmentId,
  );
  const cachedFile = getFileByAttachmentId(attachment.id);

  useEffect(() => {
    if (!cachedFile && attachment.filePath) {
      setLoading(true);
      void dispatch(
        getFile({
          attachmentId: attachment.id,
          baseUrl,
          file: attachment.filePath,
          token,
          ttl: 300,
        }),
      )
        .unwrap()
        .finally(() => setLoading(false));
    }
  }, [
    attachment.filePath,
    attachment.fileType,
    attachment.id,
    baseUrl,
    cachedFile,
    dispatch,
    getFileByAttachmentId,
    token,
  ]);

  return (
    <div className='flex justify-center items-center'>
      {loading && <Spinner />}
      {!loading && attachment.securityRisk && (
        <div className='flex flex-col items-center'>
          <ExclamationTriangleIcon className='h-14 w-14 text-red-base' />
          <span className='text-red-base'>
            {attachment.originalName} has been removed due to a security risk
          </span>
        </div>
      )}
      {!loading &&
        cachedFile &&
        attachment.fileType === FileType.Image &&
        !forceDownloadButton && (
          <img
            alt={attachment.description}
            className='max-h-[600px] object-contain'
            onError={() => setForceDownloadButton(true)}
            src={cachedFile.fileUrl}
          />
        )}
      {(forceDownloadButton ||
        (!loading && cachedFile && attachment.fileType !== FileType.Image)) && (
        <div className='text-lg text-center space-y-2'>
          This file cannot be previewed. Please download to view.
          <br />
          <Button
            aria-label='Download attachment'
            className='flex gap-1'
            disabled={!cachedFile}
            loading={loading}
            onClick={downloadAttachment}
          >
            <ArrowDownOnSquareIcon className='w-6' /> Download
          </Button>
        </div>
      )}
    </div>
  );
};
