import { Nullable, SetSource } from './types';

export class NullSafeSet<T> extends Set<T> {
  constructor(values?: SetSource<T>) {
    super(
      Array.from(values || []).filter(
        (e) => typeof e !== 'undefined' && e !== null,
      ) as T[],
    );
  }
  add(value: T) {
    if (typeof value === 'undefined' || value === null) {
      return this;
    }
    return super.add(value);
  }
}

export class CaseInsensitiveSet extends NullSafeSet<string> {
  private readonly transform: (str: string) => Nullable<string>;

  /**
   * @param values - array or iterable of strings
   * @param localeAware - culture/locale to use for locale aware comparisons
   */
  constructor(values?: SetSource<string>, localeAware?: string) {
    const func = localeAware
      ? (str: string) => str.toLocaleLowerCase(localeAware)
      : (str: string) => str.toLowerCase();
    const transform = (str: string) =>
      typeof str === 'string' ? func(str) : undefined;

    super(Array.from((values || []) as string[], transform));
    // must set after super() call
    this.transform = transform;
  }
  add(value: string) {
    // transform will be null here during constructor, but we'll have
    // called the transform manually during initialization so this is safe
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return super.add(this.transform?.(value) ?? value);
  }
  delete(value: string) {
    return super.delete(this.transform(value) as string);
  }
  has(value: string) {
    // this 'cast' is safe as the base class will remove the undefined values
    return super.has(this.transform(value) as string);
  }
}
