import { XMarkIcon } from '@heroicons/react/16/solid';
import { clsx } from 'clsx';
import { FC } from 'react';
import { noOp } from 'utils/functional';

export type ChipProps = {
  /**
   * Callback invoked when the chip is removed.
   * @default `noOp`.
   */
  onRemove?: () => void;
  /**
   * ID used in testing.
   */
  testId?: string;
  /**
   * The text to be displayed in the chip.
   */
  text: string;
};

const Chip: FC<ChipProps> = ({ onRemove = noOp, testId, text }) => {
  return (
    <div
      className={clsx(
        'inline-flex items-center gap-1 rounded',
        'bg-green-lightest hover:bg-green-lighter',
      )}
      data-testid={testId}
    >
      <div className='p-1 pl-2 font-medium text-gray-700 text-xs'>{text}</div>
      <button
        aria-label='Remove chip'
        className={clsx(
          'p-1 border-l border-gray-400 cursor-pointer outline-none ring-0',
          'focus:ring-2 focus:ring-inset focus:ring-green-base focus:rounded-r',
        )}
        onClick={onRemove}
      >
        <XMarkIcon className='w-4 h-4 text-gray-400 hover:text-gray-600' />
      </button>
    </div>
  );
};

export default Chip;
