import { InspectionDetails, PromptMedia } from '@dakota/platform-client';
import { ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useCheckPermission } from 'auth/AuthHelper';
import { clsx } from 'clsx';
import EditableText from 'components/EditableText';
import { configSlice } from 'features/config/configSlice';
import { updateAttachment } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import useToast from 'hooks/useToast';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import { AttachmentIcon } from './AttachmentIcon';
import { CarouselData } from './CarouselData';

export type UploadState = 'error' | 'pending' | 'uploaded' | 'uploading';

type AttachmentProps = {
  attachment: PromptMedia;
  attachmentIndex: number;
  promptIndex: number;
  sectionIndex: number;
};

export const Attachment: FC<AttachmentProps> = ({
  attachment,
  attachmentIndex,
  promptIndex,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const inspection = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  ) as InspectionDetails;

  const { setErrorMessage } = useToast();

  const [editingDescription, setEditingDescription] = useState(false);
  const [savingDescription, setSavingDescription] = useState(false);

  const isAdmin = useCheckPermission(Permission.Admin);
  const canEdit = isAdmin || attachment.userId === currentUser.id;

  const blobFromBase64 = (base64: string) => {
    return new Blob([
      new Uint8Array(
        atob(base64)
          .split('')
          .map((char) => char.charCodeAt(0)),
      ),
    ]);
  };

  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    if (attachment.thumbnail?.data) {
      const objectUrl = URL.createObjectURL(
        blobFromBase64(attachment.thumbnail.data),
      );
      setThumbnailUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [attachment.thumbnail?.data]);

  const onEditDescription = (description: string) => {
    setSavingDescription(true);

    dispatch(
      updateAttachment({
        attachmentId: attachment.id,
        attachmentIndex,
        baseUrl,
        description,
        inspectionId: inspection.id,
        promptId: attachment.promptId,
        promptIndex,
        sectionIndex,
        token,
      }),
    )
      .unwrap()
      .catch(() => setErrorMessage('Failed to update description'))
      .finally(() => {
        setSavingDescription(false);
        setEditingDescription(false);
      });
  };

  const showInCarousel = () => {
    dispatch(
      inspectionSlice.actions.openCarousel({
        attachment,
        attachmentIndex,
        promptIndex,
        sectionIndex,
      } as CarouselData),
    );
  };

  return (
    <div
      className={clsx(
        'border border-gray-200 rounded-lg',
        'h-36 flex flex-col justify-between',
        'bg-center bg-no-repeat bg-cover',
      )}
      style={{
        backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : '',
      }}
    >
      <button
        aria-label='View attachment'
        className={clsx(
          'flex-none flex justify-center items-center text-gray-300',
          'rounded-t-lg h-16 group',
          {
            'hover:bg-green-lightest':
              !thumbnailUrl && !attachment.securityRisk,
            'hover:bg-red-lightest': attachment.securityRisk,
          },
        )}
        disabled={attachment.securityRisk}
        onClick={showInCarousel}
      >
        {!thumbnailUrl && !attachment.securityRisk && (
          <AttachmentIcon fileType={attachment.fileType} />
        )}
        {attachment.securityRisk && (
          <div className='flex flex-col items-center justify-center h-full'>
            <ExclamationTriangleIcon className='w-8 h-8 sm:w-6 sm:h-6 text-red-base' />
            <span className='text-sm sm:text-xs text-red-base'>
              File has been Quarantined
            </span>
          </div>
        )}
      </button>
      <div
        className={clsx(
          'rounded-b-lg text-xs p-1 flex-1',
          'text-left flex flex-col justify-between gap-1',
          thumbnailUrl ? 'bg-gray-100/75' : 'bg-gray-100',
        )}
      >
        <button
          className='flex-none font-bold truncate'
          disabled={attachment.securityRisk}
          onClick={showInCarousel}
        >
          {attachment.originalName}
        </button>
        {attachment.description || editingDescription ? (
          <EditableText
            allowEmpty
            containerClasses='w-full'
            disabled={!canEdit}
            editOnMount={!attachment.description}
            growVertically
            id={`edit-description-${attachment.id}`}
            loading={savingDescription}
            maxLength={50}
            onCancelEdit={() => setEditingDescription(false)}
            onEdit={onEditDescription}
            saveOnEnter
            text={attachment.description ?? ''}
            textClasses='text-gray-700 p-0 hover:cursor-text'
          />
        ) : (
          <button
            aria-label='Add description'
            className='flex-1 text-green-base flex place-items-center pt-3'
            disabled={!canEdit}
            onClick={() => setEditingDescription(true)}
          >
            <PlusIcon className='w-4' /> Add description
          </button>
        )}
      </div>
    </div>
  );
};
