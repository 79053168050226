import { CalendarIcon } from '@heroicons/react/24/solid';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { clsx } from 'clsx';

type Props = {
  onClose: () => void;
  title: string;
};

const DialogHeader = ({ onClose, title }: Props) => {
  return (
    <div
      className={clsx(
        'px-8 py-4 border-b border-solid border-gray-200',
        'flex-none flex justify-between items-center gap-2',
      )}
    >
      <div className='flex items-center gap-2'>
        <CalendarIcon className='w-6 h-6 text-green-base' />
        <h3 className='text-lg font-medium text-green-base'>
          Schedule Inspection — {title}
        </h3>
      </div>
      <div>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default DialogHeader;
