import { Summary, UserProfile } from '@dakota/platform-client';

/**
 * A default user with empty values.
 */
export const defaultUser = {
  displayName: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  roles: [] as Summary[],
} as UserProfile;
