import { TaskInstance, TaskPriority, TaskState } from '@dakota/platform-client';

// TODO: move this file to test mocks once the tasks are actually being fetched
// from the backend
export const mockTasks = [
  TaskInstance.fromJS({
    assignedTo: {
      id: '1',
      name: 'John Doe',
    },
    attachments: { comments: 1 },
    description:
      'Evaluate the hazardous waste in the storage shed for potential violations. Document and add Files/images to support the evaluation. Link to any new dependant correctional tasks.',
    facility: { name: 'Springfield Nuclear Plant' },
    id: '1',
    overdue: false,
    priority: TaskPriority.High,
    seriesId: 'series-1',
    status: TaskState.Open,
    timeline: { scheduledDate: '2024-12-31' },
    title: 'Hazardous Waste Determination',
  }),
  TaskInstance.fromJS({
    assignedTo: {
      id: '1',
      name: 'Joe Doe',
    },
    attachments: { comments: 2, media: 7 },
    description:
      'Cras quis tempor neque, tempor tempus risus. Sed commodo et nibh eget tincidunt. Morbi convallis nisi ex, quis congue nisi cursus at. Aliquam elit sem, gravida vitae magna pretium, molestie vulputate sem. Aliquam euismod mi et augue sodales, a tincidunt magna scelerisque. Sed hendrerit condimentum quam.',
    facility: { name: 'Waste Management Facility' },
    id: '2',
    overdue: false,
    priority: TaskPriority.Medium,
    seriesId: 'series-1',
    status: TaskState.Completed,
    timeline: { scheduledDate: '2024-08-12' },
    title: 'Nulla sagittis elementum vehicula',
    zone: { name: 'Sector 7' },
  }),
  TaskInstance.fromJS({
    assignedTo: {
      id: '1',
      name: 'Jane Doe',
    },
    description:
      'Suspendisse fringilla lacus est, in feugiat turpis efficitur sed. Nulla commodo, sapien non pretium elementum, ex magna tristique odio, sit amet fringilla quam nulla a erat.',
    facility: { name: 'Power Plant' },
    id: '3',
    overdue: false,
    priority: TaskPriority.Medium,
    seriesId: 'series-1',
    status: TaskState.Canceled,
    timeline: { scheduledDate: '2024-04-07' },
    title: 'Proin eu urna vel purus',
  }),
  TaskInstance.fromJS({
    assignedTo: {
      id: '1',
      name: 'John Doe',
    },
    description:
      'Vestibulum et arcu lectus. Aliquam sagittis metus quis aliquet faucibus. Donec non nisl placerat, hendrerit lacus iaculis, consequat est.',
    facility: { name: 'Power Plant' },
    id: '4',
    overdue: true,
    priority: TaskPriority.Low,
    seriesId: 'series-2',
    status: TaskState.InProgress,
    timeline: { scheduledDate: '2024-06-30' },
    title: 'An overdue task with a moderately long title',
  }),
];
