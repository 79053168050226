import { Tooltip } from '@mui/material';
import { FC } from 'react';

import { HeroIcon } from './types';

type Props = {
  expanded?: boolean;
  icon: HeroIcon;
  iconColor?: string;
  title: string;
};

const NavIcon: FC<Props> = ({
  expanded = false,
  icon: Icon,
  iconColor = 'text-gray-900',
  title,
}: Props) => {
  const commonProps =
    'flex items-center hover:bg-gray-200 rounded-lg transition';

  return expanded ? (
    <div className={`${commonProps} p-3 gap-3`} data-testid='nav-with-title'>
      <Icon color={iconColor} width={24} />
      <p className='text-sm font-medium text-gray-900'>{title}</p>
    </div>
  ) : (
    <div
      className={`${commonProps} w-14 aspect-square justify-center`}
      data-testid='nav-icon-only'
    >
      <Tooltip placement='right' title={title}>
        <Icon color={iconColor} width={24} />
      </Tooltip>
    </div>
  );
};

export default NavIcon;
