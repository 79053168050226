import {
  ArrowUturnLeftIcon,
  EyeIcon,
  EyeSlashIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import EditableText from 'components/EditableText';
import Tooltip from 'components/Tooltip';
import {
  BuilderSection,
  templatesSlice,
} from 'features/templates/templatesSlice';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/store';

import SectionContent from './sectionContent';

type Props = {
  index: number;
  section: BuilderSection;
};

const Section: FC<Props> = ({ index, section }) => {
  const dispatch = useAppDispatch();

  const [rendered, setRendered] = useState(false);
  useEffect(() => setRendered(true), []);

  useEffect(() => {
    if (section.questionJustAdded) {
      // Scroll to section when adding a new question
      document
        .getElementById(`section-${index}`)
        ?.scrollIntoView({ behavior: 'instant' });
      dispatch(templatesSlice.actions.resetQuestionJustAdded());
    }
  }, [section.questionJustAdded, index, dispatch]);

  const resetHeader = () => {
    dispatch(
      templatesSlice.actions.updateSectionHeader({
        header: section.itemTypeName,
        itemTypeId: section.itemTypeId,
      }),
    );
  };

  const updateHeader = (text: string) =>
    dispatch(
      templatesSlice.actions.updateSectionHeader({
        header: text,
        itemTypeId: section.itemTypeId,
      }),
    );

  const hideHeader = () =>
    dispatch(templatesSlice.actions.hideHeader(section.itemTypeId));

  const showHeader = () =>
    dispatch(templatesSlice.actions.showHeader(section.itemTypeId));

  const removeSection = () => {
    dispatch(templatesSlice.actions.removeSection(section.itemTypeId));
  };

  const buttonClass = 'group disabled:cursor-not-allowed';
  const iconClass = clsx(
    'w-5 h-5 text-gray-400 hover:text-gray-700 active:text-gray-900',
    'group-disabled:text-gray-200',
  );

  return (
    <div
      className={clsx(
        'w-full scroll-mt-4 transition-all duration-300',
        rendered ? 'opacity-100' : 'opacity-0',
      )}
      data-testid='template-section'
      id={`section-${index}`}
    >
      <div className='flex justify-between items-center mb-1'>
        <EditableText
          containerClasses='w-full'
          id={`section-${index}-header`}
          onEdit={updateHeader}
          saveOnEnter
          showIcon='inline'
          text={section.header ?? ''}
          textClasses={clsx(
            'text-lg hover:bg-gray-200',
            section.headerVisible ? 'text-gray-800' : 'text-gray-400',
          )}
        />
        <div className='flex items-center gap-2 pr-2'>
          {section.headerModified && (
            <button
              aria-label='Reset header'
              className={buttonClass}
              id={`reset-header-${index}`}
              onClick={resetHeader}
            >
              <Tooltip title='Reset header'>
                <ArrowUturnLeftIcon className={iconClass} />
              </Tooltip>
            </button>
          )}
          {section.headerVisible && (
            <button
              aria-label='Hide header'
              className={buttonClass}
              onClick={hideHeader}
            >
              <Tooltip title='Header will be visible on Inspection. Click to make invisible.'>
                <EyeIcon className={iconClass} />
              </Tooltip>
            </button>
          )}
          {!section.headerVisible && (
            <button
              aria-label='Show header'
              className={buttonClass}
              onClick={showHeader}
            >
              <Tooltip title='Header will be invisible on Inspection. Click to make visible.'>
                <EyeSlashIcon className={iconClass} />
              </Tooltip>
            </button>
          )}
          <button
            aria-label='Remove section'
            className={buttonClass}
            onClick={removeSection}
          >
            <Tooltip title='Remove section from template'>
              <TrashIcon className={iconClass} />
            </Tooltip>
          </button>
        </div>
      </div>
      <SectionContent
        itemTypeId={section.itemTypeId}
        questions={section.questions}
      />
    </div>
  );
};

export default Section;
