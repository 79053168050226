import { Switch } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';
import { FC } from 'react';

interface ToggleProps {
  className?: string;
  disabled?: boolean;
  /**
   * Used as id and data-testid for the toggle.
   */
  id?: string;
  isOn: boolean;
  label?: string;
  onToggle: (isOn: boolean) => void;
  showIcon?: boolean;
}

const Toggle: FC<ToggleProps> = ({
  className,
  disabled = false,
  id,
  isOn,
  label,
  onToggle,
  showIcon,
}) => {
  let toggleClasses = clsx(
    'relative inline-flex items-center h-6 w-11',
    'rounded-full transition ease-in-out',
  );

  if (disabled) {
    toggleClasses += ' bg-gray-100 cursor-not-allowed';
  } else if (isOn) {
    toggleClasses += ' bg-green-base';
  } else {
    toggleClasses += ' bg-gray-200';
  }

  const iconClasses = clsx(
    isOn ? 'translate-x-6' : 'translate-x-1',
    'inline-block w-4 h-4 transform bg-white',
    'rounded-full transition duration-200',
  );

  return (
    <Switch.Group
      as='div'
      className={`flex items-center ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${className}`}
    >
      <Switch
        checked={isOn}
        className={toggleClasses}
        data-testid={id}
        disabled={disabled}
        id={id}
        onChange={onToggle}
      >
        <span className='sr-only'>Toggle option</span>
        <span className={iconClasses}>
          {showIcon && (isOn ? <CheckIcon /> : <XMarkIcon />)}
        </span>
      </Switch>
      {label && (
        <Switch.Label
          as='span'
          className={`ml-3 ${disabled ? 'text-gray-300' : 'text-gray-700'}`}
        >
          {label}
        </Switch.Label>
      )}
    </Switch.Group>
  );
};

export default Toggle;
