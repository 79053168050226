import { XMarkIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';

import logo from '../../assets/Dakota-Logo.png';
import Navigation from './Navigation';

type Props = {
  isOpen: boolean;
  toggleSidebar: () => void;
};

const SidebarMobile = ({ isOpen, toggleSidebar }: Props) => {
  return (
    <>
      {isOpen && (
        <div
          className='block sm:hidden bg-black fixed w-full h-full opacity-50 z-30'
          role='overlay'
        />
      )}
      <div
        className={clsx(
          `absolute sm:hidden ${isOpen ? 'left-0' : '-left-full'}`,
          'transition-all duration-300 ease-in-out',
          'h-screen w-max min-w-56 z-30 bg-gray-100 py-5',
        )}
        id='mobile-sidebar'
      >
        <button
          className='absolute p-2 -right-12 top-3'
          id='close-sidebar'
          onClick={toggleSidebar}
        >
          <XMarkIcon color='white' width={24} />
        </button>
        <div className='px-6 '>
          <img alt='Dakota Software' className='w-32 mb-5' src={logo} />
        </div>
        <div className='px-2'>
          <Navigation
            isMobile
            isSidebarOpen={isOpen}
            toggleSidebar={toggleSidebar}
          />
        </div>
      </div>
    </>
  );
};
export default SidebarMobile;
