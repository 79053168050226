import { useContext } from 'react';

import { AddMultipleContext } from './context';

export const useAddMultipleContext = () => {
  const context = useContext(AddMultipleContext);
  if (!context) {
    throw new Error(
      'useAddMultipleContext must be used within an AddMultipleContextProvider',
    );
  }
  return context;
};
