import {
  ListTasksParams,
  SearchStatus,
  TaskState,
} from '@dakota/platform-client';
import {
  Bars4Icon,
  PlusIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import { listTasks } from 'features/tasks/tasksActions';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { TaskCard } from '../Card';
import { TaskInstanceWithId } from '../idWrap';
import TasksTable from '../TasksTable';

export const ScheduledTasks: FC = () => {
  const dispatch = useAppDispatch();
  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);

  // Default to card view
  const [listView, setListView] = useState(false);

  const { stopTracking } = usePageLoadTracking();

  const appName = useSelector(configSlice.selectors.appName);
  const loadingTasks = useSelector(tasksSlice.selectors.isLoadingTasks);

  const backendTasks = useSelector(tasksSlice.selectors.tasks);
  const tasks = backendTasks.map((task) => ({
    ...task,
    id: `${task.seriesId}-${task.timeline.scheduledDate}`,
  })) as TaskInstanceWithId[];

  useEffect(() => {
    if (tasks.length === 0) {
      // TODO: incorporate dates from date picker
      const params = {
        dateRange_endDate: '',
        dateRange_startDate: '',
        status: [
          SearchStatus.Overdue,
          SearchStatus.InProgress,
          SearchStatus.Scheduled,
        ],
      } as ListTasksParams;

      void dispatch(listTasks({ baseUrl, ...params, token })).then(() =>
        stopTracking(DataStatus.Fetched),
      );
    } else {
      stopTracking(DataStatus.PreviouslyLoaded);
    }
  }, [dispatch, tasks, token, baseUrl, stopTracking]);

  const switchView = () => {
    setListView((prev) => !prev);
  };

  const completeTaskInstance = (task: TaskInstanceWithId) => {
    console.log('completing the task', task.id);
  };

  const openTaskInstance = (task: TaskInstanceWithId) => {
    console.log('opening task details', task.id);
  };

  const setTaskInstanceStatus = (
    task: TaskInstanceWithId,
    status: TaskState,
  ) => {
    if (status === TaskState.Completed) {
      return completeTaskInstance(task);
    }
    console.log('changing task status', task.id);
  };

  const buttonClasses = clsx(
    'w-5 h-5 text-gray-300',
    'hover:text-gray-700 disabled:text-gray-700',
  );

  return (
    <div className='p-4 sm:p-8'>
      <Helmet>
        <title>Scheduled Tasks | {appName}</title>
      </Helmet>
      <div className='justify-between hidden w-full pb-8 sm:flex'>
        <h2 className='text-2xl font-semibold'>Scheduled Tasks</h2>
        <Button
          className='bg-green-base px-4 py-2'
          icon={<PlusIcon />}
          id='create-task-button'
        >
          Create New Task
        </Button>
      </div>
      <div className='flex justify-between items-center gap-2 pb-4'>
        <div>Filters</div>
        <div className='flex gap-2'>
          <button
            aria-label='Switch to list view'
            className={buttonClasses}
            disabled={listView}
            onClick={switchView}
          >
            <Tooltip arrow disabled={listView} title='Switch to list view'>
              <Bars4Icon />
            </Tooltip>
          </button>
          <button
            aria-label='Switch to card view'
            className={buttonClasses}
            disabled={!listView}
            onClick={switchView}
          >
            <Tooltip arrow disabled={!listView} title='Switch to card view'>
              <TableCellsIcon />
            </Tooltip>
          </button>
        </div>
      </div>
      {listView ? (
        <TasksTable
          data={tasks}
          loading={loadingTasks}
          openTaskInstance={openTaskInstance}
          setTaskInstanceStatus={setTaskInstanceStatus}
        />
      ) : (
        <div className='space-y-4'>
          {tasks.map((task) => (
            <TaskCard
              completeTaskInstance={completeTaskInstance}
              key={task.id}
              openTaskInstance={openTaskInstance}
              setTaskInstanceStatus={setTaskInstanceStatus}
              task={task}
            />
          ))}
        </div>
      )}
    </div>
  );
};
