import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback } from 'react';
import { isTelemetryEnabled } from 'telemetry/service';

export enum DataStatus {
  /**
   * Indicates that the timer was stopped after data was fetched
   * from the backend for the specific page.
   */
  Fetched,
  /**
   * Indicates that the data needed for the page was previously
   * loaded so there was no need to fetch again.
   */
  PreviouslyLoaded,
}

export const usePageLoadTracking = () => {
  const appInsightsCtx = useAppInsightsContext();

  /**
   * Stop tracking page load time, to be used
   * after all the data the current page fetches is loaded,
   * whether it was fetched from the backend or
   * was already present in the state.
   *
   * It must be called exactly once in each page.
   */
  const stopTracking = useCallback(
    (dataStatus: DataStatus) => {
      if (isTelemetryEnabled()) {
        appInsightsCtx.getAppInsights().stopTrackPage(
          /**
           * defaults to window title, if specified must match value in
           * startTrackPage
           */
          undefined,
          undefined /* defaults to window.location.href */,
          { Data: dataStatus.toString() },
        );
      }
    },
    [appInsightsCtx],
  );

  return { stopTracking };
};
