import { IdentityClaims } from 'auth/DakotaUser';

export interface Identity {
  firstName?: string;
  lastName?: string;
  orgId: string;
  orgName?: string;
  userId: string;
  userName: string;
}
export interface OrgInfo {
  id: string;
  name?: string;
}

export interface User {
  email?: string;
  family_name?: string;
  given_name?: string;
  name?: string;
  sub?: string;
  userId: string;
}

export interface UserWithClaims extends User {
  [IdentityClaims.OrgInfo]?: OrgInfo;
  [IdentityClaims.UserInfo]?: User;
}

export function toIdentity(user: null | UserWithClaims): Identity | null {
  if (!user) {
    return null;
  }
  const oInfo: OrgInfo = user[IdentityClaims.OrgInfo] || { id: '', name: '' };
  const uInfo: User = user[IdentityClaims.UserInfo] || {
    email: '',
    name: '',
    sub: '',
    userId: '',
  };
  const identity: Identity = {
    firstName: user.given_name,
    lastName: user.family_name,
    orgId: oInfo.id,
    orgName: oInfo.name,
    userId: uInfo.userId,
    userName: user.email ?? user.name ?? user.sub ?? '',
  };
  return identity;
}
