import { Group } from '@dakota/platform-client';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';
import Input from 'components/Input';
import SidePanel from 'components/SidePanel';
import { configSlice } from 'features/config/configSlice';
import {
  addItemGroup,
  updateItemGroup,
} from 'features/items/itemGroupsActions';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export type AddEditItemGroupProps = {
  /**
   * If present, the component will be in edit mode.
   * Otherwise it will be in add mode.
   */
  itemGroup?: Group;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const AddEditItemGroup: FC<AddEditItemGroupProps> = ({
  itemGroup,
  setIsOpen,
}) => {
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const dispatch = useAppDispatch();

  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [name, setName] = useState(itemGroup?.name ?? '');
  const [nameError, setNameError] = useState('');

  const [saving, setSaving] = useState(false);

  // To validate the form, we check that the name doesn't clash with
  // any existing item group
  let existingItemGroups = itemGroups;
  if (itemGroup) {
    existingItemGroups = existingItemGroups.filter(
      (f) => f.id !== itemGroup.id,
    );
  }

  useEffect(() => {
    if (
      existingItemGroups.find(
        (f) => f.name.toLowerCase() === name.trim().toLowerCase(),
      )
    ) {
      setNameError('There is already an Item Group with this name');
    } else {
      setNameError('');
    }
  }, [name, existingItemGroups]);

  const clearFormData = useCallback(() => {
    setName(itemGroup?.name ?? '');
  }, [itemGroup?.name]);

  // Form is valid if all required fields are present
  const isFormValid =
    !!name.trim() &&
    !existingItemGroups.find(
      (f) => f.name.toLowerCase() === name.trim().toLowerCase(),
    );

  const isEditValid = !itemGroup || name.trim() !== itemGroup.name;

  const submitHandler = () => {
    setSaving(true);

    if (itemGroup) {
      const updatedItemGroup = {
        ...itemGroup,
        name: name.trim(),
      } as Group;

      const payload = {
        baseUrl,
        itemGroup: updatedItemGroup,
        token,
      };

      dispatch(updateItemGroup(payload))
        .unwrap()
        .then(() => {
          clearFormData();
          setSuccessMessage('Item group updated successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to update item group');
        })
        .finally(() => {
          setIsOpen(false);
          setSaving(false);
        });
    } else {
      const payload = {
        baseUrl,
        itemGroup: {
          name: name.trim(),
        } as Group,
        token,
      };

      dispatch(addItemGroup(payload))
        .unwrap()
        .then(() => {
          clearFormData();
          setSuccessMessage('Item group added successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to add item group');
        })
        .finally(() => {
          setIsOpen(false);
          setSaving(false);
        });
    }
  };

  const cancelHandler = () => {
    if (!itemGroup) {
      // Clear the form only if we are adding a new item type
      clearFormData();
    }
    setIsOpen(false);
  };

  return (
    <SidePanel
      isOpen
      onClose={() => setIsOpen(false)}
      PanelTitle={
        <div className='flex text-green-base gap-2'>
          {itemGroup ? (
            <PencilIcon className='w-6' />
          ) : (
            <PlusIcon className='w-6' />
          )}
          <p className='text-lg font-medium'>
            {itemGroup ? 'Edit' : 'Add'} Item Group
          </p>
        </div>
      }
      testId='add-edit-item-group-panel'
    >
      <div className='h-full flex flex-col justify-between'>
        <div className='p-6 relative'>
          <Input
            className='w-full mt-2'
            errorExplanation={nameError}
            id='item-group-name'
            label='Item Group Name'
            onChange={(e) => setName(e.target.value)}
            required
            value={name}
          />
          <br />
        </div>
        <div className='flex gap-2.5 px-6 py-4 border-t border-gray-200'>
          <Button
            className={`bg-green-base py-2 px-4 disabled:bg-gray-400 ${
              !isFormValid ? 'cursor-not-allowed' : ''
            }`}
            data-testid='add-item-group-submit-button'
            disabled={!isFormValid || !isEditValid || saving}
            loading={saving}
            onClick={submitHandler}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
          <Button className='py-2 px-4' onClick={cancelHandler} secondary>
            Cancel
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};

export default AddEditItemGroup;
