import {
  InspectionStatus,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/20/solid';
import PermissionGuard from 'auth/PermissionGuard';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Chips from 'components/Chip/Chips';
import ClearAllButton from 'components/ClearAll';
import DatePicker from 'components/DatePicker';
import InspectionsTable from 'components/Inspections/InspectionsTable';
import SearchInput from 'components/SearchInput';
import { configSlice } from 'features/config/configSlice';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { userSlice } from 'features/user/userSlice';
import { useFilteredInspections } from 'hooks/useFilteredInspections';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getStatusLabel } from 'utils/inspectionStatusLabel';
import { Permission } from 'utils/permissions';

const ScheduledInspections = () => {
  const appName = useSelector(configSlice.selectors.appName);
  const isLoadingInspections = useSelector(
    inspectionSlice.selectors.isLoadingInspections,
  );
  const accessibleFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );
  const isLoadingAccessibleFacilities = useSelector(
    userSlice.selectors.isLoadingAccessibleFacilities,
  );
  const users = useSelector(userSlice.selectors.allUserSummaries);
  const activeUsers = users.filter((u) => !u.inactive);
  const isLoadingUserSummaries = useSelector(
    userSlice.selectors.isLoadingAllUserSummaries,
  );

  const [selectedStatus, setSelectedStatus] = useState<InspectionStatus[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);

  const { stopTracking } = usePageLoadTracking();

  const statuses = [
    InspectionStatus.InProgress,
    InspectionStatus.Overdue,
    InspectionStatus.Pending,
  ];

  const {
    dateRange,
    filteredInspections,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  } = useFilteredInspections({
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
  });

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedStatus([]);
    setSelectedAssignees([]);
    setSearchQuery('');
    resetDateRange();
  };

  const removeFacility = (facility: Summary) => {
    setSelectedFacilities(selectedFacilities.filter((f) => f !== facility));
  };

  const removeAssignee = (assignee: UserSummary) => {
    setSelectedAssignees(selectedAssignees.filter((a) => a !== assignee));
  };

  return (
    <div className='p-4 md:p-8'>
      <Helmet>
        <title>Scheduled | Inspections | {appName}</title>
      </Helmet>
      <div className='justify-between hidden w-full pb-8 md:flex'>
        <h2 className='text-2xl font-semibold'>Scheduled</h2>
        <PermissionGuard permissions={Permission.CreateInspection}>
          <div className='flex gap-2.5 items-center'>
            <Link to='/manage/inspection-templates'>
              <Button
                className='bg-green-base px-4 py-2'
                data-testid='schedule-inspection-button'
                icon={<PlusIcon />}
              >
                Schedule Inspection
              </Button>
            </Link>
          </div>
        </PermissionGuard>
      </div>
      <div className='filters-container'>
        <SearchInput
          className='w-full'
          data-testid='search-input'
          id='search-input'
          onSearch={setSearchQuery}
          value={searchQuery}
        />
        <DatePicker
          id='scheduled-date-picker'
          onChange={setDateRange}
          scrollOnFocus={false}
          shortcutsType='next'
          showShortcuts
          useRange
          value={dateRange}
        />
        <Autocomplete
          className='w-60'
          getOptionKey={(facility) => facility.id}
          getOptionLabel={(facility) => facility.name}
          id='facility-selector'
          label='Facilities'
          loading={isLoadingAccessibleFacilities}
          multiple
          onChange={setSelectedFacilities}
          options={accessibleFacilities}
          value={selectedFacilities}
        />
        <Autocomplete
          className='max-sm:w-60 w-40'
          getOptionKey={(user) => user.id}
          getOptionLabel={(user) => user.name}
          id='assignee-selector'
          label='Assignee'
          loading={isLoadingUserSummaries}
          multiple
          onChange={setSelectedAssignees}
          options={activeUsers}
          value={selectedAssignees}
        />
        <Autocomplete
          className='max-sm:w-60 w-32'
          getOptionLabel={getStatusLabel}
          id='status-selector'
          label='Status'
          multiple
          onChange={setSelectedStatus}
          options={statuses}
          value={selectedStatus}
        />
        {hasFilters && <ClearAllButton onClick={resetFilters} />}
      </div>
      {selectedFacilities.length > 0 && (
        <Chips
          elements={selectedFacilities}
          getKey={(f) => f.id}
          getLabel={(f) => f.name}
          onRemove={removeFacility}
          title='Facilities'
        />
      )}
      {selectedAssignees.length > 0 && (
        <Chips
          elements={selectedAssignees}
          getKey={(a) => a.id}
          getLabel={(a) => a.name}
          onRemove={removeAssignee}
          title='Assignees'
        />
      )}
      <InspectionsTable
        data={filteredInspections}
        loading={isLoadingInspections}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default ScheduledInspections;
