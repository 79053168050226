import { clsx } from 'clsx';
import { FC, ReactNode } from 'react';

type Props = {
  content: ReactNode;
  title: string;
};

const ChartContainer: FC<Props> = ({ content, title }) => {
  return (
    <div
      className={clsx(
        'h-96 overflow-auto flex flex-col justify-stretch items-center',
        'rounded-lg border border-gray-300 p-6 hover:bg-gray-50',
      )}
    >
      <div className='text-gray-900 text-xl font-medium text-center'>
        {title}
      </div>
      <div className='mt-3 h-full'>{content}</div>
    </div>
  );
};

export default ChartContainer;
