import { Group } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useCheckPermission } from 'auth/AuthHelper';
import PermissionGuard from 'auth/PermissionGuard';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import ClearAllButton from 'components/ClearAll';
import DakotaLibraryLogo from 'components/DakotaLibraryLogo';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import { ColumnProps } from 'components/Table/types';
import { configSlice } from 'features/config/configSlice';
import { getAllItemGroups } from 'features/items/itemGroupsActions';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { alphabeticalCompare } from 'utils/functional';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';

import AddEditItemGroup from './addItemGroup';
import ItemGroupsMenu from './itemGroupsMenu';

const ItemGroups: React.FC = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const appName = useSelector(configSlice.selectors.appName);

  const { stopTracking } = usePageLoadTracking();

  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);
  const isLoadingItemGroups = useSelector(
    itemGroupsSlice.selectors.isLoadingItemGroups,
  );

  useEffect(() => {
    if (itemGroups.length === 0) {
      void dispatch(getAllItemGroups({ baseUrl, token })).then(() =>
        stopTracking(DataStatus.Fetched),
      );
    } else {
      stopTracking(DataStatus.PreviouslyLoaded);
    }
  }, [baseUrl, dispatch, itemGroups.length, stopTracking, token]);

  const [searchQuery, setSearchQuery] = useState('');
  const [isAddItemGroupPanelOpen, setIsAddItemGroupPanelOpen] = useState(false);

  // We *only* display the filtered item groups
  const [filteredItemGroups, setFilteredItemGroups] = useState<Group[]>([]);

  type Status = 'Active' | 'All' | 'Inactive';
  const statuses = ['All', 'Active', 'Inactive'] as const;
  const [selectedStatus, setSelectedStatus] = useState<Status>('Active');

  useEffect(() => {
    let newItemGroups = itemGroups;

    if (selectedStatus === 'Active') {
      newItemGroups = newItemGroups.filter((q) => !q.inactive);
    } else if (selectedStatus === 'Inactive') {
      newItemGroups = newItemGroups.filter((q) => q.inactive);
    }

    if (searchQuery) {
      const fuse = new Fuse(newItemGroups, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: ['name'],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newItemGroups = fuse.search(searchQuery).map((r) => r.item);
    }

    setFilteredItemGroups(
      newItemGroups.toSorted(alphabeticalCompare((i) => i.name)),
    );
  }, [itemGroups, searchQuery, selectedStatus]);

  const resetFilters = () => {
    setSelectedStatus('Active');
    setSearchQuery('');
  };

  const canSeeContextualMenu = useCheckPermission(
    Permission.DeleteItemMetadata,
  );

  const highlight = getHighlighter(searchQuery);

  const itemTypesLength = (group: Group) => {
    const total = group.itemTypes.length;
    const inactive = group.itemTypes.filter(
      (itemType) => itemType.inactive,
    ).length;

    return (
      <span className='inline-flex items-baseline font-normal text-gray-700'>
        {total - inactive}
        {inactive > 0 && (
          <span className='text-sm italic text-gray-400 ml-2'>
            (+{inactive} inactive)
          </span>
        )}
      </span>
    );
  };

  const columns: ReadonlyArray<ColumnProps<Group>> = [
    {
      cellDataTestId: 'item-group',
      key: 'item-group',
      render: (group) => {
        return (
          <p className={group.inactive ? 'text-gray-400' : ''}>
            {highlight(group.name)}
            {group.isPlatformDefined && <DakotaLibraryLogo />}
          </p>
        );
      },
      title: 'Item Group Name',
    },
    {
      cellDataTestId: 'no-of-item-types',
      key: 'no-of-item-types',
      render: itemTypesLength,
      title: 'Number of Item types',
    },
    {
      cellDataTestId: 'menu',
      key: 'menu',
      render: (group) =>
        canSeeContextualMenu && !group.isPlatformDefined && !group.isDefault ? (
          <ItemGroupsMenu itemGroup={group} />
        ) : (
          <></>
        ),
      title: '',
    },
  ];

  return (
    <div className='p-4 md:p-8'>
      <Helmet>
        <title>Item Groups | Manage Content | {appName}</title>
      </Helmet>
      <div className='justify-between hidden w-full pb-8 md:flex'>
        <h2 className='text-2xl font-semibold'>Groups</h2>
        <div className='flex gap-2.5 items-center'>
          <PermissionGuard permissions={Permission.CreateItemMetadata}>
            <Button
              className='bg-green-base px-4 py-2'
              data-testid='add-item-group-button'
              icon={<PlusIcon />}
              onClick={() => setIsAddItemGroupPanelOpen(true)}
            >
              Add Item Group
            </Button>
          </PermissionGuard>
        </div>
      </div>
      <div className='filters-container'>
        <SearchInput
          className='w-full'
          data-testid='search-input'
          onSearch={setSearchQuery}
          value={searchQuery}
        />
        <PermissionGuard permissions={Permission.Admin}>
          <Autocomplete
            className='w-28'
            id='status-selector'
            label='Status'
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
        </PermissionGuard>
        {(selectedStatus !== 'Active' || searchQuery.length > 0) && (
          <ClearAllButton onClick={resetFilters} />
        )}
      </div>
      <Table
        columns={columns}
        data={filteredItemGroups}
        footer={`${filteredItemGroups.length} results`}
        id='item-groups-table'
        loading={isLoadingItemGroups}
      />
      {isAddItemGroupPanelOpen && (
        <AddEditItemGroup setIsOpen={setIsAddItemGroupPanelOpen} />
      )}
    </div>
  );
};

export default ItemGroups;
