import { ErrorDetail } from '@dakota/platform-client';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { questionsSlice } from 'features/questions/questionsSlice';
import { FC } from 'react';
import { useAppDispatch } from 'store/store';

import { useAddMultipleContext } from './useContext';

type ResultsProps = {
  onClose: () => void;
};

export const Results: FC<ResultsProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const { results } = useAddMultipleContext();

  const success = results.filter((r) => !r.error);
  const errors = results
    .filter((r) => r.error)
    .map((r) => r.error as ErrorDetail);

  return (
    <>
      {success.length > 0 && (
        <div
          className={clsx(
            'w-1/2 p-8 bg-green-lightest border border-green-light rounded-lg',
            'text-green-base flex gap-8 items-center mx-auto',
          )}
        >
          <div className='flex-none'>
            <CheckCircleIcon className='w-8' />
          </div>
          <div className='flex-1 space-y-4'>
            <div className='text-lg font-semibold text-green-dark'>
              {success.length} question{success.length > 1 ? 's were' : ' was'}{' '}
              added to your library.
            </div>
            <div>
              Would you like to create a template based on these new questions?
            </div>
            <div className='space-x-2 text-xs'>
              <Button hasShadow={false} onClick={onClose}>
                Create Template
              </Button>
              <Button
                hasShadow={false}
                onClick={() => {
                  dispatch(questionsSlice.actions.clearAddedQuestions());
                  onClose();
                }}
                secondary
              >
                Back to Library
              </Button>
            </div>
          </div>
        </div>
      )}
      {errors.length > 0 && (
        <div className=''>
          The following questions could not be added:
          {errors.map((error, index) => (
            <div className='text-sm' key={`${error.message}+${index}`}>
              {error.message}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
