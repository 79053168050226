import { clsx } from 'clsx';
import { FC } from 'react';

import { Step } from './step';

type StepsProps = {
  step: number;
};

export const Steps: FC<StepsProps> = ({ step }) => {
  return (
    <div
      className={clsx(
        'flex-none h-16 flex justify-center items-center gap-12 text-sm',
        'border-b border-gray-300',
      )}
    >
      <Step current={step} number={1} text='Type Questions' />
      <Step current={step} number={2} text='Categorize Questions' />
    </div>
  );
};
