import { tokenSlice } from 'features/token/tokenSlice';
import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';

type AccessToken = {
  permissions: readonly string[];
};

export const useCheckPermission = (
  requestedPermissions: Array<string> | string,
  matchAll: boolean = false,
) => {
  const token = useSelector(tokenSlice.selectors.token);

  // Fail gracefully (returning false) if the token
  // is not available yet, or is not valid.
  let decodedToken: AccessToken | undefined;
  try {
    decodedToken = jwtDecode<AccessToken>(token);
  } catch (_) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!decodedToken) {
    return false;
  }

  const permissions = Array.isArray(requestedPermissions)
    ? requestedPermissions
    : [requestedPermissions];
  const { permissions: userPermissions = [] } = decodedToken;
  if (userPermissions.length === 0) {
    return false;
  }
  if (permissions.length === 0) {
    return true;
  }
  return matchAll
    ? permissions.every((v) => userPermissions.includes(v))
    : permissions.some((v) => userPermissions.includes(v));
};
