import { RolesClient } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getAllRoles = createAsyncThunk(
  'roles/getRoles',
  async (params: ClientData) => {
    const client = getClient(RolesClient, params);
    const response = await client.listRoles();
    return response.result;
  },
);
