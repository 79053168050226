import { Inspection } from '@dakota/platform-client';
import { clsx } from 'clsx';
import MenuDropdown from 'components/MenuDropdown';

import { Cancel } from './Cancel';
import { Print } from './Print';
import { Reassign } from './Reassign';
import { Reschedule } from './Reschedule';
import { Rezone } from './Rezone';

type Props = {
  inspection: Inspection;
};

const ActionMenu = ({ inspection }: Props) => {
  return (
    <div>
      <MenuDropdown buttonTestId='action-menu-button'>
        <div
          className={clsx(
            'bg-white w-44 flex flex-col p-4 gap-3',
            'rounded-md shadow-md text-sm font-medium',
          )}
        >
          <Reassign inspection={inspection} />
          <Reschedule inspection={inspection} />
          <Rezone inspection={inspection} />
          <Cancel inspection={inspection} />
          <Print inspection={inspection} />
        </div>
      </MenuDropdown>
    </div>
  );
};

export default ActionMenu;
