import { clsx } from 'clsx';
import Button from 'components/Button';

type Props = {
  canSchedule: boolean;
  handleScheduleInspection: () => void;
  onClose: () => void;
  scheduling: boolean;
};

const DialogFooter = ({
  canSchedule,
  handleScheduleInspection,
  onClose,
  scheduling,
}: Props) => {
  return (
    <div
      className={clsx(
        'flex-none px-8 py-4 flex space-x-2',
        'border-solid border-gray-200 border-t',
      )}
    >
      <Button
        data-testid='schedule-button'
        disabled={!canSchedule}
        loading={scheduling}
        onClick={handleScheduleInspection}
      >
        {scheduling ? 'Scheduling...' : 'Schedule'}
      </Button>
      <Button data-testid='close-button' onClick={onClose} secondary>
        Cancel
      </Button>
    </div>
  );
};

export default DialogFooter;
