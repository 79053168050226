import { AnswersClient } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getAnswerTypes = createAsyncThunk(
  'answers/setAnswerTypes',
  async (params: ClientData) => {
    const client = getClient(AnswersClient, params);
    const response = await client.listAnswers();
    return response.result;
  },
);
