import { clsx } from 'clsx';
import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  disabled?: boolean;
  /**
   * When present, it is assumed that the input is currently invalid,
   * and we display this error message below the input field.
   */
  errorExplanation?: string;
  label?: string;
  required?: boolean;
}

const Input: React.FC<InputProps> = ({
  className,
  disabled = false,
  errorExplanation = '',
  label,
  required = false,
  ...props
}) => {
  const containerStyles = 'text-gray-700 font-weight-500';

  const inputBaseStyles = clsx(
    'text-base text-sans text-sm',
    'py-2 rounded-md shadow-sm',
    'placeholder:text-gray-400 leading-5',
    'disabled:bg-gray-200 disabled:cursor-not-allowed',
    'border-none ring-1 ring-gray-300',
    'focus:ring-2 focus:ring-inset focus:ring-blue-base',
    className,
  );
  const inputErrorStyles = errorExplanation
    ? clsx('outline-red-base focus:outline-red-base text-red-base')
    : '';

  return (
    <div className={containerStyles}>
      {label && (
        <label
          className={`block mb-2 ${required && 'required-field'}`}
          data-testid={`${label}-id`}
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
      )}
      <input
        className={`${inputBaseStyles} ${inputErrorStyles}`}
        data-testid={props.id}
        disabled={disabled}
        {...props}
      />
      {errorExplanation && (
        <div
          className='text-xs text-right text-red-base mt-1'
          data-testid='input-error-message'
          role='alert'
        >
          {errorExplanation}
        </div>
      )}
    </div>
  );
};

export default Input;
