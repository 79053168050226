import { Prompt as InspectionPrompt } from '@dakota/platform-client';
import { clsx } from 'clsx';
import { FC } from 'react';

import { Answers } from './Answers';
import { Attachments } from './Attachments';
import { Notes } from './Notes';
import { PromptMenu } from './PromptMenu';

type PromptProps = {
  prompt: InspectionPrompt;
  /**
   * The index of the prompt in the section.
   * This is strictly to facilitate the state update when a user
   * answers the question.
   */
  promptIndex: number;
  /**
   * The index of the section in the conduct inspection state.
   * This is strictly to facilitate the state update when a user
   * answers the question.
   */
  sectionIndex: number;
};

export const Prompt: FC<PromptProps> = ({
  prompt,
  promptIndex,
  sectionIndex,
}) => {
  return (
    <div className='flex flex-col p-6 border-t border-gray-300' key={prompt.id}>
      <div className='flex flex-col md:flex-row items-start md:items-center gap-4 relative'>
        <div className='flex-1'>
          <p className='font-semibold text-gray-800 text-sm leading-5'>
            {prompt.question.text}
          </p>
          <div className='text-sm text-gray-500'>
            {prompt.question.citation}
          </div>
        </div>
        <div
          className={clsx(
            'flex items-center gap-1',
            'max-sm:justify-between max-sm:w-full',
          )}
        >
          <Answers {...{ prompt, promptIndex, sectionIndex }} />
          <PromptMenu {...{ prompt, promptIndex, sectionIndex }} />
        </div>
      </div>
      <Attachments {...{ prompt, promptIndex, sectionIndex }} />
      <Notes {...{ prompt, promptIndex, sectionIndex }} />
    </div>
  );
};
