import useToast from 'hooks/useToast';
import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useCheckPermission } from './AuthHelper';

type ProtectedRouteProps = {
  /**
   * Whether all permissions must be matched.
   * @default `false`
   */
  matchAll?: boolean;
  /**
   * The permission(s) required to access the route.
   */
  permissions: Array<string> | string;
};

/**
 * Provides access to a child route based on the permissions provided,
 * redirecting to the home page if the user does not have the permissions.
 */
export const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
  children,
  matchAll = false,
  permissions,
}) => {
  const { setErrorMessage } = useToast();
  const hasPermission = useCheckPermission(permissions, matchAll);

  if (!hasPermission) {
    setErrorMessage(
      'You do not have the required permissions to view this page',
    );
  }

  return hasPermission ? children : <Navigate to='/' />;
};
