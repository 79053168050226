import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  GlobeAmericasIcon,
  HomeIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import PermissionGuard from 'auth/PermissionGuard';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { FC } from 'react';
import { FeatureFlag } from 'utils/featureFlags';
import { noOp } from 'utils/functional';
import { Permission } from 'utils/permissions';

import NavLink from './NavLink';
import { Tutorial } from './Tutorial';

type Props = {
  isMobile?: boolean;
  isSidebarOpen: boolean;
  toggleSidebar?: () => void;
};

const Navigation: FC<Props> = ({
  isMobile = false,
  isSidebarOpen,
  toggleSidebar = noOp,
}) => {
  const { isEnabled: isTasksEnabled } = useFeatureFlag(FeatureFlag.Tasks);

  const id = (name: string) =>
    `main-navigation-${name}${isMobile ? '-mobile' : ''}`;

  const commonProps = {
    isMobile,
    isSidebarOpen,
    toggleSidebar,
  };

  return (
    <div
      className={`flex flex-col justify-center gap-2.5`}
      data-testid={`navigation${isMobile ? '-mobile' : ''}`}
      id={`navigation${isMobile ? '-mobile' : ''}`}
    >
      <NavLink
        icon={HomeIcon}
        id={id('dashboard')}
        title='Dashboard'
        to='/charts'
        {...commonProps}
      />
      <NavLink
        icon={MagnifyingGlassIcon}
        id={id('inspections')}
        title='Inspections'
        to='/inspections'
        {...commonProps}
      />
      <PermissionGuard
        permissions={[
          Permission.Admin,
          Permission.CreateItemMetadata,
          Permission.CreateInspection,
        ]}
      >
        <NavLink
          icon={ClipboardDocumentListIcon}
          id={id('manage-content')}
          title='Manage Content'
          to='/manage'
          {...commonProps}
        />
      </PermissionGuard>
      <PermissionGuard
        permissions={[
          Permission.Admin,
          Permission.CreateFacility,
          Permission.ManageUsers,
        ]}
      >
        <NavLink
          icon={GlobeAmericasIcon}
          id={id('organization')}
          title='My Organization'
          to='/organization'
          {...commonProps}
        />
      </PermissionGuard>
      {isTasksEnabled && (
        <PermissionGuard permissions={[Permission.ReadTasks]}>
          <NavLink
            icon={ClipboardDocumentCheckIcon}
            id={id('tasks')}
            title='Tasks'
            to='/tasks'
            {...commonProps}
          />
        </PermissionGuard>
      )}
      {!isMobile && <Tutorial />}
    </div>
  );
};
export default Navigation;
