import { ReportRequest, Summary } from '@dakota/platform-client';
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline';
import { LocalDate } from '@js-joda/core';
import { Tooltip } from '@mui/material';
import Button from 'components/Button';
import { configSlice } from 'features/config/configSlice';
import { getResponseDataReport } from 'features/reports/reportsActions';
import { reportsSlice } from 'features/reports/reportsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { saveBlobToDisk } from 'utils/file';

type Props = {
  endDate: LocalDate;
  // The selected facilities are of type Summary in the parent,
  // but we only need the ids
  selectedFacilityIds: Summary['id'][];
  startDate: LocalDate;
};

const DownloadDashboardButton: FC<Props> = ({
  endDate,
  selectedFacilityIds,
  startDate,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const isLoadingResponseDataReport = useSelector(
    reportsSlice.selectors.isLoadingResponseDataReport,
  );

  const { setErrorMessage } = useToast();

  const handleDownload = () => {
    void dispatch(
      getResponseDataReport({
        baseUrl,
        body: ReportRequest.fromJS({
          dateRange: {
            endDate,
            startDate,
          },
          facilities: selectedFacilityIds,
        }),
        token,
      }),
    )
      .unwrap()
      .then((result) => saveBlobToDisk(result.data, result.fileName))
      .catch(() => setErrorMessage('Unable to download dashboard data'));
  };

  return (
    <Tooltip
      arrow
      placement='top'
      PopperProps={{ style: { maxWidth: 220 } }}
      title='Download data for completed inspections in selected date range'
    >
      <div>
        <Button
          aria-label='Download Dashboard'
          data-testid='download-dashboard-button'
          disabled={isLoadingResponseDataReport}
          icon={<ArrowDownOnSquareIcon />}
          onClick={handleDownload}
          secondary
        />
      </div>
    </Tooltip>
  );
};

export default DownloadDashboardButton;
