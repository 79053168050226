type OrgInfo = {
  id: string;
  name: string;
};

type UserInfo = {
  userId: string;
};

export const IdentityClaims = {
  OrgInfo: 'https://app.dakotasoft.com/org_info',
  UserInfo: 'https://app.dakotasoft.com/user_info',
} as const;

export default interface DakotaUser {
  [IdentityClaims.OrgInfo]: OrgInfo;
  [IdentityClaims.UserInfo]: UserInfo;
  email: string;
  family_name: string; // last name, this is how it's returned from in the identity token from Auth0
  given_name: string; // first name, this is how it's returned from in the identity token from Auth0
  name?: string;
  picture?: string;
}

Object.freeze(IdentityClaims);
