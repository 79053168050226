import {
  BuildingOfficeIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentListIcon,
  ClipboardIcon,
  EyeSlashIcon,
  GlobeAmericasIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { DialogContent, DialogTitle } from '@mui/material';
import { clsx } from 'clsx';
import { templatesSlice } from 'features/templates/templatesSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';

const TemplateSummary: FC = () => {
  const facility = useSelector(templatesSlice.selectors.facility);
  const sections = useSelector(templatesSlice.selectors.sections);
  const templateSummary = useSelector(templatesSlice.selectors.templateSummary);
  const totalSections = sections.length;
  const totalQuestions = sections.reduce((a, s) => a + s.questions.length, 0);
  const totalCustomHeaders = sections.filter((s) => s.headerModified).length;
  const totalHiddenHeaders = sections.filter((s) => !s.headerVisible).length;

  const suffix = (n: number) => (n === 1 ? '' : 's');

  const dataPoints = [
    {
      icon: facility ? BuildingOfficeIcon : GlobeAmericasIcon,
      label: 'scope',
      text: facility ? `Facility: ${facility.name}` : 'Global',
    },
    {
      icon: templateSummary ? ClipboardDocumentIcon : ClipboardIcon,
      label: 'template',
      text: templateSummary
        ? `Based on "${templateSummary.name}"`
        : 'Not based on another template',
    },
    {
      icon: ClipboardDocumentListIcon,
      label: 'sections',
      text: `${totalSections} section${suffix(totalSections)}`,
    },
    {
      icon: QuestionMarkCircleIcon,
      label: 'questions',
      text: `${totalQuestions} question${suffix(totalQuestions)}`,
    },
    {
      icon: PencilIcon,
      label: 'custom-headers',
      text: `${totalCustomHeaders} custom header${suffix(totalCustomHeaders)}`,
    },
    {
      icon: EyeSlashIcon,
      label: 'hidden-headers',
      text: `${totalHiddenHeaders} hidden header${suffix(totalHiddenHeaders)}`,
    },
  ];

  return (
    <div className={clsx('w-96 text-gray-700 text-lg')} id='template-summary'>
      <DialogTitle>Template Summary</DialogTitle>
      <DialogContent className='flex flex-col gap-2'>
        {dataPoints.map((dp) => (
          <div className='w-full flex items-center gap-3' key={dp.text}>
            <div className='bg-gray-100 p-2 rounded-full'>
              <dp.icon className='w-8 h-8' />
            </div>
            <div aria-label={dp.label}>{dp.text}</div>
          </div>
        ))}
      </DialogContent>
    </div>
  );
};

export default TemplateSummary;
