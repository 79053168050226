import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TutorialState = {
  currentStep: number;
  isTutorialOpen: boolean;
};

const initialState: TutorialState = {
  currentStep: 0,
  isTutorialOpen: false,
};

export const tutorialSlice = createSlice({
  initialState,
  name: 'tutorial',
  reducers: {
    closeTutorial: (state) => {
      state.isTutorialOpen = false;
      state.currentStep = 0;
    },
    moveToNext: (state, action: PayloadAction<number>) => {
      if (state.currentStep < action.payload) {
        state.currentStep += 1;
      }
    },
    moveToPrevious: (state) => {
      if (state.currentStep > 0) {
        state.currentStep -= 1;
      }
    },
    openTutorial: (state) => {
      state.isTutorialOpen = true;
    },
  },
  selectors: {
    currentStep: (state: TutorialState) => state.currentStep,
    isTutorialOpen: (state: TutorialState) => state.isTutorialOpen,
  },
});
