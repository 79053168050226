import { TaskInstance } from '@dakota/platform-client';
import { ChatBubbleLeftIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { FC } from 'react';

import { TaskBadge } from './badge';

type CountersProps = {
  onClick: () => void;
  task: TaskInstance;
};

export const Counters: FC<CountersProps> = ({ onClick, task }) => {
  const plural = (val: number | undefined, txt: string) =>
    `${val ?? 0} ${txt}${val === 1 ? '' : 's'}`;

  return (
    <button
      className={clsx('flex-none text-gray-300 space-x-1')}
      onClick={onClick}
    >
      <div className='sm:hidden text-gray-700 *:flex *:gap-2 *:items-center space-y-4'>
        <div>
          <PaperClipIcon className='w-5 h-5' />
          {plural(task.attachments.media, 'attachment')}
        </div>
        <div>
          <ChatBubbleLeftIcon className='w-5 h-5' />
          {plural(task.attachments.comments, 'comment')}
        </div>
      </div>
      <div className='max-sm:hidden sm:space-x-2 flex flex-nowrap'>
        <Tooltip title={plural(task.attachments.media, 'attachment')}>
          <TaskBadge badgeContent={task.attachments.media}>
            <PaperClipIcon className='w-5 h-5' />
          </TaskBadge>
        </Tooltip>
        <div
          className={clsx('sm:hidden max-sm:col-span-5', {
            'text-gray-500': task.attachments.media > 0,
          })}
        >
          {plural(task.attachments.media, 'attachment')}
        </div>
        <Tooltip title={plural(task.attachments.comments, 'comment')}>
          <TaskBadge badgeContent={task.attachments.comments}>
            <ChatBubbleLeftIcon
              className={clsx('w-5 h-5', {
                'text-gray-500': task.attachments.comments > 0,
              })}
            />
          </TaskBadge>
        </Tooltip>
        <div
          className={clsx('sm:hidden max-sm:col-span-5', {
            'text-gray-500': task.attachments.comments > 0,
          })}
        >
          {plural(task.attachments.comments, 'comment')}
        </div>
      </div>
    </button>
  );
};
