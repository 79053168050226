import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/16/solid';
import {
  Fade,
  mobileStepperClasses,
  MobileStepperProps,
  MobileStepper as MuiMobileStepper,
  Popover,
  styled,
} from '@mui/material';
import { useCheckPermission } from 'auth/AuthHelper';
import { tutorialSlice } from 'features/tutorial/tutorialSlice';
import { FC, PropsWithChildren, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import resolveConfig from 'tailwindcss/resolveConfig';
import { Permission } from 'utils/permissions';

import tailwindConfig from '../../../tailwind.config';

const { theme: dakotaTheme } = resolveConfig(tailwindConfig);

const MobileStepper = styled((props: MobileStepperProps) => (
  <MuiMobileStepper {...props} />
))(() => ({
  [`& .${mobileStepperClasses.dotActive}`]: {
    backgroundColor: dakotaTheme.colors.green.base,
  },
}));

export const TutorialBackdrop: FC = () => {
  return <div className='z-30 fixed w-full h-dvh bg-black bg-opacity-50' />;
};

type TutorialPopoverProps = {
  anchorEl: HTMLElement;
};

const TutorialPopover: FC<PropsWithChildren<TutorialPopoverProps>> = ({
  anchorEl,
  children,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      onClose={() => dispatch(tutorialSlice.actions.closeTutorial())}
      open
      sx={{
        ml: 3,
        transitionDuration: 1000,
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'center',
      }}
      TransitionComponent={Fade}
    >
      {children}
    </Popover>
  );
};

type TutorialStepProps = {
  buttonRef: HTMLElement;
  content: string[];
  stepNumber: number;
  title: string;
};

const TutorialStep: FC<TutorialStepProps> = ({
  buttonRef,
  content,
  stepNumber,
  title,
}) => {
  const dispatch = useAppDispatch();
  const canSeeManageContent = useCheckPermission([
    Permission.Admin,
    Permission.CreateItemMetadata,
    Permission.CreateInspection,
  ]);
  const canSeeOrganization = useCheckPermission([
    Permission.Admin,
    Permission.CreateFacility,
    Permission.CreateUser,
  ]);

  let totalSteps = 2;
  if (canSeeManageContent) {
    totalSteps += 1;
  }
  if (canSeeOrganization) {
    totalSteps += 1;
  }

  useLayoutEffect(() => {
    buttonRef.classList.add('get-started-highlighted');

    return () => buttonRef.classList.remove('get-started-highlighted');
  }, [buttonRef.classList]);

  const buttonStyles =
    'flex items-center uppercase text-sm disabled:text-gray-300';

  return (
    <div
      className='p-4 w-96 text-gray-700'
      data-testid={`tutorial-step-${stepNumber}`}
    >
      <div className='h-full flex flex-col justify-between'>
        <div>
          <div className='font-medium text-3xl mb-4'>{title}</div>
          <div className='font-light text-xl text-gray-500'>
            {content.map((item) => (
              <div className='flex items-start gap-1 mb-1' key={item}>
                <div className='flex-none'>
                  <CheckIcon className='w-6 h-6 text-green-base relative top-1' />
                </div>
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className='flex justify-between text-green-base mt-4'>
          <button
            aria-label='Previous step'
            className={buttonStyles}
            disabled={stepNumber === 0}
            onClick={() => dispatch(tutorialSlice.actions.moveToPrevious())}
          >
            <ChevronLeftIcon className='w-4 h-4' /> Prev
          </button>
          <div>
            <MobileStepper
              activeStep={stepNumber}
              backButton={undefined}
              nextButton={undefined}
              position='static'
              steps={totalSteps}
              sx={{ flexGrow: 1 }}
              variant='dots'
            />
          </div>
          <button
            aria-label='Next step'
            className={buttonStyles}
            disabled={stepNumber === totalSteps - 1}
            onClick={() =>
              dispatch(tutorialSlice.actions.moveToNext(totalSteps))
            }
          >
            Next <ChevronRightIcon className='w-4 h-4' />
          </button>
        </div>
      </div>
    </div>
  );
};

export const Tutorial: FC = () => {
  const [dashboardRef, setDashboardRef] = useState<HTMLElement | null>(null);
  const [inspectionsRef, setInspectionsRef] = useState<HTMLElement | null>(
    null,
  );
  const [manageContentRef, setManageContentRef] = useState<HTMLElement | null>(
    null,
  );
  const [organizationRef, setOrganizationRef] = useState<HTMLElement | null>(
    null,
  );

  const isTutorialOpen = useSelector(tutorialSlice.selectors.isTutorialOpen);
  const currentStep = useSelector(tutorialSlice.selectors.currentStep);

  useLayoutEffect(() => {
    setDashboardRef(document.getElementById('main-navigation-dashboard'));
    setInspectionsRef(document.getElementById('main-navigation-inspections'));
    setManageContentRef(
      document.getElementById('main-navigation-manage-content'),
    );
    setOrganizationRef(document.getElementById('main-navigation-organization'));
  }, []);

  return (
    <>
      {isTutorialOpen && dashboardRef && currentStep === 0 && (
        <TutorialPopover anchorEl={dashboardRef}>
          <TutorialStep
            buttonRef={dashboardRef}
            content={[
              'Review recent inspections results for your organization',
              'Quickly access your list of inspection assignments',
            ]}
            stepNumber={0}
            title='Dashboard'
          />
        </TutorialPopover>
      )}
      {isTutorialOpen && inspectionsRef && currentStep === 1 && (
        <TutorialPopover anchorEl={inspectionsRef}>
          <TutorialStep
            buttonRef={inspectionsRef}
            content={[
              'Review upcoming and completed inspections for your organization',
            ]}
            stepNumber={1}
            title='Inspections'
          />
        </TutorialPopover>
      )}
      {isTutorialOpen && manageContentRef && currentStep === 2 && (
        <TutorialPopover anchorEl={manageContentRef}>
          <TutorialStep
            buttonRef={manageContentRef}
            content={[
              'Review inspection questions provided by Dakota experts',
              'Add your own inspection questions to the library',
              'Build and assign reusable inspection templates',
              'Review groups and item types Dakota experts have provided',
              'Add your own groups and item types to inspect ',
            ]}
            stepNumber={2}
            title='Manage Content'
          />
        </TutorialPopover>
      )}
      {isTutorialOpen && organizationRef && currentStep === 3 && (
        <TutorialPopover anchorEl={organizationRef}>
          <TutorialStep
            buttonRef={organizationRef}
            content={[
              'Add users to the system',
              'Set up the facilities where you perform EHS related activities',
            ]}
            stepNumber={3}
            title='My Organization'
          />
        </TutorialPopover>
      )}
    </>
  );
};
