import { InspectionDetails } from '@dakota/platform-client';
import { PrinterIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button';
import { getAnswerTypes } from 'features/answers/answersActions';
import { configSlice } from 'features/config/configSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';

type PrintButtonProps = {
  inspectionDetails: InspectionDetails;
  saveCurrentResponses: () => Promise<void>;
};

export const PrintButton: FC<PrintButtonProps> = ({
  inspectionDetails,
  saveCurrentResponses,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const [loading, setLoading] = useState(false);

  const { setErrorMessage } = useToast();

  const handleOnClick = async () => {
    setLoading(true);

    await saveCurrentResponses().catch(() =>
      setErrorMessage('Failed to save responses'),
    );

    void dispatch(getAnswerTypes({ baseUrl, token }))
      .then(({ payload: answerTypes }) => {
        navigate('/print-inspection', {
          state: {
            answerTypes,
            inspection: inspectionDetails,
          },
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className='sm:basis-1/4'>
      <Button loading={loading} onClick={() => void handleOnClick()} secondary>
        <PrinterIcon className='w-4 h-4 mr-1.5' /> Print
      </Button>
    </div>
  );
};
