import {
  ReportRequest,
  ResponsesReport,
  StatusReport,
  Summary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import ClearAllButton from 'components/ClearAll';
import DatePicker from 'components/DatePicker';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import {
  getFailedItemTypesReport,
  getGradeReport,
  getResponsesReport,
  getStatusReport,
} from 'features/reports/reportsActions';
import { reportsSlice } from 'features/reports/reportsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { getMyAccessibleFacilities } from 'features/user/userActions';
import { userSlice } from 'features/user/userSlice';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { useAppDispatch } from 'store/store';
import { alphabeticalCompare } from 'utils/functional';

import ChartContainer from './ChartContainer';
import DashboardPieChart from './DashboardPieChart';
import DownloadDashboardButton from './DownloadDashboardButton';
import FailedResponsesChart from './FailedResponsesChart';
import SummaryCard from './SummaryCard';
import { ChartData } from './types';

const Charts: FC = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const appName = useSelector(configSlice.selectors.appName);

  const statusReport = useSelector(reportsSlice.selectors.statusReport);
  const isLoadingStatusReport = useSelector(
    reportsSlice.selectors.isLoadingStatusReport,
  );

  const gradeReport = useSelector(reportsSlice.selectors.gradeReport);
  const isLoadingGradeReport = useSelector(
    reportsSlice.selectors.isLoadingGradeReport,
  );

  const responsesReport = useSelector(reportsSlice.selectors.responsesReport);
  const isLoadingResponsesReport = useSelector(
    reportsSlice.selectors.isLoadingResponsesReport,
  );

  const failedItemTypesReport = useSelector(
    reportsSlice.selectors.failedItemTypesReport,
  );
  const isLoadingFailedItemTypesReport = useSelector(
    reportsSlice.selectors.isLoadingFailedItemTypesReport,
  );

  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const facilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  ).toSorted(alphabeticalCompare((f) => f.name));
  const isLoadingFacilities = useSelector(
    userSlice.selectors.isLoadingAccessibleFacilities,
  );

  const today = LocalDate.now();
  const defaultStartDate = today.minusDays(7).toString();
  const defaultEndDate = today.toString();

  const [selectedDateRange, setSelectedDateRange] = useState<DateValueType>({
    endDate: defaultEndDate,
    startDate: defaultStartDate,
  });

  const { stopTracking } = usePageLoadTracking();

  const [apiCallCount, setApiCallCount] = useState(0);

  useEffect(() => {
    // There are a total of 5 API calls to be made on this page. Once all 5
    // have completed, stop tracking.
    if (apiCallCount === 5) {
      stopTracking(DataStatus.Fetched);
    }
  }, [apiCallCount, stopTracking]);

  useEffect(() => {
    let ignore = false;

    void dispatch(getMyAccessibleFacilities({ baseUrl, token })).then(() => {
      if (!ignore) {
        setApiCallCount((prev) => prev + 1);
      }
    });

    return () => {
      ignore = true;
    };
  }, [baseUrl, dispatch, token]);

  useEffect(() => {
    if (
      !selectedDateRange?.startDate ||
      !selectedDateRange.endDate ||
      facilities.length === 0
    ) {
      return;
    }

    const startDate = LocalDate.parse(selectedDateRange.startDate.toString());
    const endDate = LocalDate.parse(selectedDateRange.endDate.toString());

    const allFacilitiesSelected =
      selectedFacilities.length === facilities.length;

    const body = {
      dateRange: {
        endDate: endDate.toString(),
        startDate: startDate.toString(),
      },
      facilities: allFacilitiesSelected
        ? []
        : selectedFacilities.map((f) => f.id),
    } as ReportRequest;

    void Promise.all([
      dispatch(getStatusReport({ baseUrl, body, token })),
      dispatch(getGradeReport({ baseUrl, body, token })),
      dispatch(getResponsesReport({ baseUrl, body, token })),
      dispatch(getFailedItemTypesReport({ baseUrl, body, token })),
    ]).then(() => setApiCallCount((prev) => prev + 4));
  }, [
    baseUrl,
    dispatch,
    facilities.length,
    selectedDateRange?.endDate,
    selectedDateRange?.startDate,
    selectedFacilities,
    token,
  ]);

  const byStatus = (report: StatusReport): (number | string)[][] => {
    const { canceled, completed, inProgress, overdue, pending } = report;

    /**
     * Data for the 'Breakdown by Status' chart *MUST* be passed
     * in the following order:
     *    Completed, In Progress, Overdue, Pending, Canceled.
     */
    return [
      ['Status', 'Value'],
      ['Completed', completed],
      ['In Progress', inProgress],
      ['Overdue', overdue],
      ['Pending', pending],
      ['Canceled', canceled],
    ];
  };

  const byResults = (report: ResponsesReport): (number | string)[][] => {
    const { fail, notApplicable, pass, warning } = report;

    /**
     * Data for the 'Breakdown by Results' chart *MUST* be passed
     * in the following order: Pass, Warning, Fail, N/A.
     */
    return [
      ['Status', 'Value'],
      ['Pass', pass],
      ['Warning', warning],
      ['Fail', fail],
      ['N/A', notApplicable],
    ];
  };

  const failedResponses: ChartData[] =
    failedItemTypesReport?.failingItemTypes.map((itemType) => ({
      name: itemType.name,
      value: itemType.count,
    })) ?? [];

  const gradeReportCompleted =
    isLoadingGradeReport || gradeReport?.completed === undefined
      ? undefined
      : gradeReport.completed;
  const passRate =
    isLoadingGradeReport || gradeReport?.grade === undefined
      ? undefined
      : Math.round(gradeReport.grade);
  const statusReportOverdue =
    isLoadingStatusReport || statusReport?.overdue === undefined
      ? undefined
      : statusReport.overdue;

  const deselectFacility = (facility: Summary) => {
    setSelectedFacilities(
      selectedFacilities.filter((f) => f.id !== facility.id),
    );
  };

  const clearFilters = () => {
    setSelectedFacilities([]);
    setSelectedDateRange({
      endDate: defaultEndDate,
      startDate: defaultStartDate,
    });
  };

  return (
    <div className='p-4 sm:p-8'>
      <Helmet>
        <title>Dashboard | Charts | {appName}</title>
      </Helmet>
      <div className='filters-container'>
        <Autocomplete
          className='max-sm:w-full w-44'
          getOptionKey={(facility) => facility.id}
          getOptionLabel={(facility) => facility.name}
          id='charts-facility-select'
          label='Facilities'
          loading={isLoadingFacilities}
          multiple
          onChange={setSelectedFacilities}
          options={facilities}
          value={selectedFacilities}
        />
        <DatePicker
          id='charts-date-picker'
          onChange={setSelectedDateRange}
          scrollOnFocus={false}
          showShortcuts
          useRange
          value={selectedDateRange}
        />
        {(selectedFacilities.length > 0 ||
          selectedDateRange?.startDate !== defaultStartDate ||
          selectedDateRange.endDate !== defaultEndDate) && (
          <ClearAllButton
            data-testid='clear-all-button'
            onClick={clearFilters}
          />
        )}
        <Chips
          containerClassName='mt-2'
          elements={selectedFacilities}
          getKey={(f) => f.id}
          getLabel={(f) => f.name}
          onRemove={deselectFacility}
          title='Facilities'
        />
        {selectedDateRange?.endDate && selectedDateRange.startDate && (
          // Hide button on mobile
          <div className='hidden sm:flex justify-end flex-grow'>
            <DownloadDashboardButton
              endDate={LocalDate.parse(selectedDateRange.endDate.toString())}
              selectedFacilityIds={selectedFacilities.map((f) => f.id)}
              startDate={LocalDate.parse(
                selectedDateRange.startDate.toString(),
              )}
            />
          </div>
        )}
      </div>
      <div className='grid grid-cols-3 gap-2 mt-2 sm:mt-8 mb-2'>
        <SummaryCard
          loading={isLoadingGradeReport}
          title='Completed'
          value={gradeReportCompleted}
        />
        <Tooltip
          arrow
          title='Skipped questions have been excluded from this calculation'
        >
          <div>
            <SummaryCard
              bgBreakpoints={[50, 80]}
              loading={isLoadingGradeReport}
              title='Weighted Pass Rate'
              type='percent'
              value={passRate}
            />
          </div>
        </Tooltip>
        <SummaryCard
          bgBreakpoints={[1, 5]}
          loading={isLoadingStatusReport}
          title='Overdue'
          value={statusReportOverdue}
        />
      </div>
      <div
        className='flex flex-col sm:grid sm:grid-cols-3 gap-2'
        data-testid='charts-container'
        id='charts-container'
      >
        <ChartContainer
          content={
            <DashboardPieChart
              data={statusReport ? byStatus(statusReport) : []}
              loading={isLoadingStatusReport}
              type='status'
            />
          }
          title='Breakdown by Status'
        />
        <ChartContainer
          content={
            <DashboardPieChart
              data={responsesReport ? byResults(responsesReport) : []}
              loading={isLoadingResponsesReport}
              type='results'
            />
          }
          title='Breakdown by Results'
        />
        <ChartContainer
          content={
            <FailedResponsesChart
              data={failedResponses}
              loading={isLoadingFailedItemTypesReport}
            />
          }
          title='Failed Responses'
        />
      </div>
    </div>
  );
};

export default Charts;
