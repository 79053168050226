import { Facility, TimeZone, UserSummary } from '@dakota/platform-client';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Input from 'components/Input';
import SidePanel from 'components/SidePanel';
import { WarningMessage } from 'components/WarningMessage';
import { configSlice } from 'features/config/configSlice';
import {
  addFacility,
  updateFacility,
} from 'features/facilities/facilitiesActions';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { listInspections } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { timeZoneSlice } from 'features/timeZones/timeZoneSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useZone } from 'hooks/useZone';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { allCountries, Country } from 'utils/countries';
import { alphabeticalCompare } from 'utils/functional';
import { usaStates, UsState } from 'utils/state';

export type AddEditFacilityProps = {
  /**
   * If present, the component will be in edit mode.
   * Otherwise it will be in add mode.
   */
  facility?: Facility;
  handleFailure: () => void;
  handleSuccess: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const AddEditFacility: FC<AddEditFacilityProps> = ({
  facility,
  handleFailure,
  handleSuccess,
  isOpen,
  setIsOpen,
}) => {
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const dispatch = useAppDispatch();

  const allFacilities = useSelector(facilitiesSlice.selectors.allFacilities);
  const isLoadingAllUserSummaries = useSelector(
    userSlice.selectors.isLoadingAllUserSummaries,
  );
  const timeZones = useSelector(timeZoneSlice.selectors.getTimeZones);
  const isLoadingTimeZones = useSelector(
    timeZoneSlice.selectors.isLoadingTimeZones,
  );

  //gets the user's timezone, if no match is found, defaults to UTC
  const userTimeZone =
    timeZones.find(
      (tz) => tz.id === Intl.DateTimeFormat().resolvedOptions().timeZone,
    ) ?? (timeZones.find((tz) => tz.id === 'UTC') as TimeZone);

  const usa = useMemo(
    () => allCountries.find((c) => c.code === 'US') as Country,
    [],
  );
  // in the future, the list of countries will come from the backend
  const sortedCountries = useMemo(
    () => allCountries.toSorted(alphabeticalCompare((c) => c.name)),
    [],
  );

  const sortedUsaStates = usaStates.toSorted(
    alphabeticalCompare((s) => s.name),
  );

  const [facilityName, setFacilityName] = useState('');
  const [nameError, setNameError] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [country, setCountry] = useState<Country>(usa);
  const [state, setState] = useState<null | UsState>(null);
  const [facilityContact, setFacilityContact] = useState<null | UserSummary>(
    null,
  );
  const [facilityTimeZone, setFacilityTimeZone] = useState<null | TimeZone>(
    userTimeZone,
  );
  const [saving, setSaving] = useState(false);

  const inspectionsPerFacility = useSelector(
    inspectionSlice.selectors.inspectionsPerFacility,
  );

  const isEdit = !!facility;

  const { activeUsers, allUserSummaries } = useZone(facility?.id);

  // To validate the form, we check that the name doesn't clash with
  // any existing facility
  let existingFacilities = allFacilities;
  if (facility) {
    existingFacilities = existingFacilities.filter((f) => f.id !== facility.id);
  }

  useEffect(() => {
    if (existingFacilities.find((f) => f.name === facilityName.trim())) {
      setNameError('There is already a facility with this name');
    } else {
      setNameError('');
    }
  }, [allFacilities, existingFacilities, facility?.id, isEdit, facilityName]);

  useEffect(() => {
    if (
      country.code === usa.code &&
      (!RegExp(/^\d*$/).exec(postalCode) || postalCode.length > 5)
    ) {
      setPostalCodeError('ZIP Code must contain 5 digits');
    } else {
      setPostalCodeError('');
    }
  }, [country.code, postalCode, usa.code]);

  useEffect(() => {
    if (country.code !== usa.code) {
      setState(null);
      setPostalCode('');
    }
  }, [country.code, usa.code]);

  useEffect(() => {
    // If we're editing a facility but we don't know yet if it has associated
    // inspections, we fetch all of them to know whether we need to show
    // a warning message to the user.
    if (facility && !inspectionsPerFacility.has(facility.id)) {
      void dispatch(listInspections({ baseUrl, facility: facility.id, token }));
    }
  }, [baseUrl, dispatch, facility, inspectionsPerFacility, token]);

  /**
   * We need a separate validation on blur because the current value
   * can be potentially valid but still incomplete, in which case we
   * let the user know so that they can come back to the field and fix it.
   */
  const validatePostalCodeOnBlur = () => {
    if (!isPostalCodeValid) {
      setPostalCodeError('ZIP Code must contain 5 digits');
    }
  };

  const isPostalCodeValid =
    country.code !== usa.code || RegExp(/^\d{5}$/).exec(postalCode);

  const isStateValid = country.code !== usa.code || !!state;

  useEffect(() => {
    if (facility) {
      setFacilityName(facility.name);
      setAddress1(facility.address?.street ?? '');
      setAddress2(facility.address?.addressLine2 ?? '');
      setCity(facility.address?.city ?? '');
      setPostalCode(facility.address?.postalCode ?? '');
      const facilityTZ = timeZones.find((tz) => tz.id === facility.timeZone);
      if (facilityTZ) {
        setFacilityTimeZone(facilityTZ);
      }
      const facilityCountry = allCountries.find(
        (c) => c.code === facility.address?.country,
      );
      if (facilityCountry) {
        setCountry(facilityCountry);
        if (facilityCountry.code === usa.code) {
          setState(
            usaStates.find((s) => s.abbreviation === facility.address?.state) ??
              null,
          );
        }
      }

      setFacilityContact(
        allUserSummaries.find((u) => u.id === facility.contact?.id) ?? null,
      );
    }
  }, [allUserSummaries, facility, timeZones, usa.code]);

  const clearFormData = useCallback(() => {
    setFacilityName('');
    setAddress1('');
    setAddress2('');
    setCity('');
    setPostalCode('');
    setCountry(usa);
    setState(null);
    setFacilityContact(null);
    setFacilityTimeZone(null);
  }, [usa]);

  // Form is valid if all required fields are present, including
  // postal code and state when country is USA
  const isFormValid =
    !!facilityName.trim() &&
    !existingFacilities.find((f) => f.name === facilityName.trim()) &&
    !!address1.trim() &&
    !!city.trim() &&
    isPostalCodeValid &&
    isStateValid &&
    !!facilityTimeZone;

  // Edit is valid if any field is different to its original
  const isEditValid =
    facilityName.trim() !== facility?.name ||
    address1.trim() !== facility.address?.street ||
    address2.trim() !== (facility.address.addressLine2 ?? '') ||
    city.trim() !== facility.address.city ||
    postalCode.trim() !== (facility.address.postalCode ?? '') ||
    country.code !== facility.address.country ||
    state?.abbreviation !== facility.address.state ||
    facilityContact?.id !== facility.contact?.id ||
    facilityTimeZone?.id !== facility.timeZone;

  const handleFacilityOperations = () => {
    if (isEditValid) {
      if (facility) {
        const updatedFacility = {
          address: {
            addressLine2: address2,
            city,
            country: country.code,
            postalCode,
            state: state?.abbreviation,
            street: address1,
          },
          contact: facilityContact ?? undefined,
          createdBy: facility.createdBy,
          id: facility.id,
          inactive: facility.inactive,
          name: facilityName,
          timeZone: facilityTimeZone?.id,
        } as Facility;

        const payload = { baseUrl, facility: updatedFacility, token };

        return dispatch(updateFacility(payload)).unwrap();
      } else {
        const newFacility = {
          address: {
            addressLine2: address2,
            city,
            country: country.code,
            postalCode,
            state: state?.abbreviation,
            street: address1,
          },
          contact: facilityContact ?? undefined,
          name: facilityName,
          timeZone: facilityTimeZone?.id,
        } as Facility;

        const payload = { baseUrl, body: newFacility, token };

        return dispatch(addFacility(payload)).unwrap();
      }
    }
    return Promise.resolve();
  };

  const submitHandler = () => {
    if (!isFormValid || (isEdit && !isEditValid)) {
      return;
    }

    setSaving(true);

    handleFacilityOperations()
      .then(handleSuccess)
      .catch(handleFailure)
      .finally(() => setSaving(false));
  };

  const cancelHandler = () => {
    if (!facility) {
      // Clear the form only if we are adding a new facility
      clearFormData();
    }
    setIsOpen(false);
  };

  return (
    <SidePanel
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      PanelTitle={
        <div className='flex text-green-base gap-2'>
          {facility ? (
            <PencilIcon className='w-6' />
          ) : (
            <PlusIcon className='w-6' />
          )}
          <p className='text-lg font-medium'>
            {facility ? 'Edit' : 'Add'} Facility
          </p>
        </div>
      }
      testId='add-edit-facility-panel'
    >
      <form
        className='h-full flex flex-col justify-between *:px-6'
        onSubmit={(e) => e.preventDefault()}
      >
        <div className='flex-1 overflow-y-auto py-6 flex flex-col gap-5'>
          {facility &&
            (inspectionsPerFacility.get(facility.id) ?? []).length > 0 && (
              <WarningMessage variant='light'>
                This Facility has Inspections associated with it. Any details
                changed here will be changed throughout the system.
              </WarningMessage>
            )}
          {!facility && (
            <WarningMessage variant='light'>
              Once this facility is created, you will be able to add zones.
            </WarningMessage>
          )}
          <Input
            className='w-full'
            errorExplanation={nameError}
            id='facility-name'
            label='Facility Name'
            onChange={(e) => {
              setFacilityName(e.target.value);
            }}
            required
            value={facilityName}
          />
          <Autocomplete
            autoComplete='none'
            getOptionLabel={(c) => c.name}
            id='facility-country'
            onChange={(c) => {
              setCountry(c);
            }}
            options={sortedCountries}
            outsideLabel='Country'
            required
            value={country}
          />
          <Input
            className='w-full'
            id='facility-address-1'
            label='Facility Address (line 1)'
            onChange={(e) => setAddress1(e.target.value)}
            required
            value={address1}
          />
          <Input
            className='w-full'
            id='facility-address-2'
            label='Facility Address (line 2)'
            onChange={(e) => setAddress2(e.target.value)}
            value={address2}
          />
          <Input
            className='w-full'
            id='facility-city'
            label='City'
            onChange={(e) => {
              setCity(e.target.value);
            }}
            required
            value={city}
          />
          <div className='grid grid-cols-2 gap-4'>
            <Autocomplete
              autoComplete='none'
              disabled={country.code !== usa.code}
              getOptionLabel={(e) => e.name}
              id='facility-state'
              onChange={setState}
              options={sortedUsaStates}
              outsideLabel='State'
              required={country.code === usa.code}
              value={state}
            />
            <Input
              className='w-full'
              disabled={country.code !== usa.code}
              errorExplanation={postalCodeError}
              id='facility-postal-code'
              label='ZIP Code'
              onBlur={validatePostalCodeOnBlur}
              onChange={(e) => setPostalCode(e.target.value)}
              required={country.code === usa.code}
              value={postalCode}
            />
          </div>
          <Autocomplete
            getOptionKey={(tz) => tz.id}
            getOptionLabel={(tz) => tz.displayName}
            id='facility-time-zone'
            loading={isLoadingTimeZones}
            onChange={setFacilityTimeZone}
            options={timeZones}
            outsideLabel='Facility Time Zone'
            required
            value={facilityTimeZone}
          />
          <Autocomplete
            clearable
            getOptionKey={(c) => c.id}
            getOptionLabel={(c) => c.name}
            label='Facility Contact'
            loading={isLoadingAllUserSummaries}
            onChange={setFacilityContact}
            options={activeUsers}
            value={facilityContact}
          />
        </div>
        <div className='flex-none flex gap-2.5 py-4 border-t border-gray-200'>
          <Button
            className='bg-green-base disabled:bg-gray-400'
            data-testid='add-facility-submit-button'
            disabled={!isFormValid || (isEdit && !isEditValid)}
            loading={saving}
            onClick={submitHandler}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
          <Button
            data-testid='add-facility-cancel-btn'
            onClick={cancelHandler}
            secondary
          >
            Cancel
          </Button>
        </div>
      </form>
    </SidePanel>
  );
};

export default AddEditFacility;
