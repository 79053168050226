import { Question } from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';
import { alphabeticalCompare } from 'utils/functional';

import {
  activateQuestion,
  addQuestion,
  addQuestions,
  deactivateQuestion,
  editQuestion,
  getAllQuestions,
} from './questionsActions';

export type QuestionsState = {
  /**
   * The list of questions that were added in the last multiple-add operation.
   */
  addedQuestions: Question[];
  /**
   * True when the user is adding multiple questions to the tenant.
   */
  isAddingQuestions: boolean;
  /**
   * True when the questions are being fetched from the backend.
   */
  isLoadingQuestions: boolean;
  /**
   * The full list of questions for the tenant.
   */
  questions: Question[];
};

export const initialState: QuestionsState = {
  addedQuestions: [],
  isAddingQuestions: false,
  isLoadingQuestions: false,
  questions: [],
};

export const questionsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAllQuestions.pending, (state) => {
      state.isLoadingQuestions = true;
    });
    builder.addCase(getAllQuestions.fulfilled, (state, action) => {
      state.isLoadingQuestions = false;
      state.questions = action.payload;
    });
    builder.addCase(getAllQuestions.rejected, (state) => {
      state.isLoadingQuestions = false;
    });
    builder.addCase(editQuestion.fulfilled, (state, action) => {
      state.questions = state.questions.map((question) =>
        question.id === action.payload.id ? action.payload : question,
      );
    });
    builder.addCase(addQuestion.fulfilled, (state, action) => {
      state.questions = [...state.questions, action.payload];
    });
    builder.addCase(deactivateQuestion.fulfilled, (state, action) => {
      state.questions = state.questions.map((question) =>
        question.id === action.payload
          ? { ...question, inactive: true }
          : question,
      );
    });
    builder.addCase(activateQuestion.fulfilled, (state, action) => {
      state.questions = state.questions.map((question) =>
        question.id === action.payload
          ? { ...question, inactive: false }
          : question,
      );
    });
    builder.addCase(addQuestions.fulfilled, (state, action) => {
      state.isAddingQuestions = false;
      const addedQuestions = action.payload
        .filter((result) => !!result.value)
        .map((result) => result.value as Question);
      state.addedQuestions = addedQuestions;
      state.questions = [...state.questions, ...addedQuestions].toSorted(
        alphabeticalCompare((q) => q.text),
      );
    });
    builder.addCase(addQuestions.pending, (state) => {
      state.isAddingQuestions = true;
    });
    builder.addCase(addQuestions.rejected, (state) => {
      state.isAddingQuestions = false;
    });
  },
  initialState,
  name: 'questions',
  reducers: {
    clearAddedQuestions(state) {
      state.addedQuestions = [];
    },
  },
  selectors: {
    addedQuestions: (state: QuestionsState) => state.addedQuestions,
    allQuestions: (state: QuestionsState) => state.questions,
    isAddingQuestions: (state: QuestionsState) => state.isAddingQuestions,
    isLoadingQuestions: (state: QuestionsState) => state.isLoadingQuestions,
  },
});
