import { FC } from 'react';
import Highlighter from 'react-highlight-words';

/**
 * Return a function that renders the text with a `.highlighted` class
 * for each match of a word in the search query (separated by spaces).
 */
export const getHighlighter = (searchQuery: string) => {
  const highlight: FC<string | undefined> = (text) => (
    <Highlighter
      autoEscape
      highlightClassName='highlighted'
      searchWords={searchQuery.replace(/(^")|("$)/g, '').split(' ')}
      textToHighlight={text ?? ''}
    />
  );

  return highlight;
};
