import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Loading';
import { logoutUser } from 'features/user/userSlice';
import { FC, useEffect } from 'react';
import { useAppDispatch } from 'store/store';

const Logout: FC = () => {
  const { logout } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logoutUser());
    void logout({ logoutParams: { returnTo: window.location.origin } });
  }, [dispatch, logout]);

  return <Loading />;
};

export default Logout;
