import { Prompt } from '@dakota/platform-client';
import { FC } from 'react';

import { Attachment } from './Attachment';

type AttachmentsProps = {
  prompt: Prompt;
  promptIndex: number;
  sectionIndex: number;
};

export const Attachments: FC<AttachmentsProps> = ({
  prompt,
  promptIndex,
  sectionIndex,
}) => {
  return (
    prompt.media.length > 0 && (
      <div className='grid max-sm:grid-cols-2 sm:grid-cols-5 gap-2 pt-2'>
        {prompt.media.map((attachment, attachmentIndex) => (
          <Attachment
            {...{ attachmentIndex, promptIndex, sectionIndex }}
            attachment={attachment}
            key={attachment.id}
          />
        ))}
      </div>
    )
  );
};
