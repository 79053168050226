import { TimeZone } from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';

import { getTimeZones } from './timeZoneActions';

export type TimeZoneState = {
  isLoadingTimeZones: boolean;
  timeZones: TimeZone[];
};

const initialState: TimeZoneState = {
  isLoadingTimeZones: false,
  timeZones: [],
};

export const timeZoneSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getTimeZones.pending, (state) => {
      state.isLoadingTimeZones = true;
    });
    builder.addCase(getTimeZones.fulfilled, (state, action) => {
      state.isLoadingTimeZones = false;
      state.timeZones = action.payload;
    });
    builder.addCase(getTimeZones.rejected, (state) => {
      state.isLoadingTimeZones = false;
    });
  },
  initialState,
  name: 'timeZones',
  reducers: {},
  selectors: {
    getTimeZones: (state: TimeZoneState) => state.timeZones,
    isLoadingTimeZones: (state: TimeZoneState) => state.isLoadingTimeZones,
  },
});
