import { ColumnProps } from './types';

/**
 * Comparator to sort columns by their `order` prop. Columns with
 * `undefined` order maintain their relative order, but are displayed
 * after all columns with a numeric order.
 */
export const columnComparator = <T>(
  lhs: ColumnProps<T>,
  rhs: ColumnProps<T>,
) => {
  if (lhs.order === undefined && rhs.order === undefined) {
    return 0;
  }
  if (lhs.order === undefined) {
    return 1;
  }
  if (rhs.order === undefined) {
    return -1;
  }
  return lhs.order - rhs.order;
};
