import { LookupClient } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getFeature = createAsyncThunk(
  'lookup/getFeature',
  async (params: { name: string } & ClientData) => {
    const client = getClient(LookupClient, params);
    const response = await client.getFeature(params.name);
    return response.result;
  },
);
