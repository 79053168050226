import { ReportRequest, ReportsClient } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getStatusReport = createAsyncThunk(
  'reports/getStatusReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(ReportsClient, params);
    const response = await client.getStatusReport(params.body);
    return response.result;
  },
);

export const getGradeReport = createAsyncThunk(
  'reports/getGradeReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(ReportsClient, params);
    const response = await client.getGradeReport(params.body);
    return response.result;
  },
);

export const getResponsesReport = createAsyncThunk(
  'reports/getResponsesReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(ReportsClient, params);
    const response = await client.getResponsesReport(params.body);
    return response.result;
  },
);

export const getFailedItemTypesReport = createAsyncThunk(
  'reports/getFailedItemTypesReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(ReportsClient, params);
    const response = await client.getFailedItemTypesReport(params.body);
    return response.result;
  },
);

export const getResponseDataReport = createAsyncThunk(
  'reports/getResponseDataReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(ReportsClient, params);
    const response = await client.getResponseDataReport(params.body);
    return response.result;
  },
);
