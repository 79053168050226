import { AnswerType, ItemType } from '@dakota/platform-client';
import {
  AutocompleteRenderGroupParams,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Autocomplete from 'components/Autocomplete';
import { questionsSlice } from 'features/questions/questionsSlice';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CategorizeQuestion } from './categorizeQuestion';
import { QuestionToAdd } from './context';
import { useAddMultipleContext } from './useContext';

export const CategorizeQuestions: FC = () => {
  const {
    getGroupName,
    questions,
    questionSelected,
    selectableAnswerTypes,
    selectableItemTypes,
    setQuestionExists,
    setQuestionIsDuplicate,
    setQuestions,
    setQuestionSelected,
  } = useAddMultipleContext();

  const allQuestions = useSelector(questionsSlice.selectors.allQuestions);

  const doesQuestionExist = useCallback(
    (question: QuestionToAdd) => {
      return allQuestions.some(
        (q) =>
          q.text === question.text && q.itemType.id === question.itemType.id,
      );
    },
    [allQuestions],
  );

  const isQuestionDuplicate = useCallback(
    (question: QuestionToAdd, index: number) => {
      const isDuplicate =
        questions.filter(
          (q) =>
            q.text === question.text && q.itemType.id === question.itemType.id,
        ).length > 1;

      // Don't return true for the first occurrence of a duplicate question
      if (isDuplicate) {
        return (
          questions.findIndex(
            (q) =>
              q.text === question.text &&
              q.itemType.id === question.itemType.id,
          ) !== index
        );
      }

      return false;
    },
    [questions],
  );

  useEffect(
    () => setQuestionExists(questions.map(doesQuestionExist)),
    [doesQuestionExist, questions, setQuestionExists],
  );

  useEffect(
    () => setQuestionIsDuplicate(questions.map(isQuestionDuplicate)),
    [isQuestionDuplicate, questions, setQuestionIsDuplicate],
  );

  const changeSelectedItemTypes = (itemType: ItemType) =>
    setQuestions((prev) =>
      prev.map((prevQuestion, i) =>
        questionSelected[i] ? { ...prevQuestion, itemType } : prevQuestion,
      ),
    );

  const changeSelectedAnswerTypes = (answerType: AnswerType) =>
    setQuestions((prev) =>
      prev.map((prevQuestion, i) =>
        questionSelected[i] ? { ...prevQuestion, answerType } : prevQuestion,
      ),
    );

  const renderGroup = (params: AutocompleteRenderGroupParams) => (
    <div key={params.key}>
      <div className='p-2 italic text-sm font-semibold text-gray-700'>
        {params.group}
      </div>
      <div>{params.children}</div>
    </div>
  );

  return (
    <div className='w-full space-y-2 py-2'>
      <div className='flex items-center gap-4'>
        <FormControlLabel
          control={
            <Checkbox
              checked={questionSelected.every(Boolean)}
              onChange={(e) =>
                setQuestionSelected((prev) => prev.map(() => e.target.checked))
              }
            />
          }
          label='Select all'
          labelPlacement='end'
        />
        <Autocomplete
          className='w-72 bg-white rounded-md'
          clearable
          disabled={questionSelected.every((selected) => !selected)}
          getOptionKey={(itemType) => itemType.id}
          getOptionLabel={(itemType) => itemType.name}
          groupBy={getGroupName}
          id='change-selected-item-types'
          isOptionEqualToValue={(a, b) => a.id === b.id}
          onChange={(type) => {
            changeSelectedItemTypes(type);
            document.getElementById('change-selected-item-types')?.blur();
          }}
          options={selectableItemTypes}
          placeholder='Change Item Type'
          renderGroup={renderGroup}
          value={null as ItemType | null}
        />
        <Autocomplete
          className='w-56 bg-white rounded-md'
          clearable
          disabled={questionSelected.every((selected) => !selected)}
          getOptionKey={(answerType) => answerType.id}
          getOptionLabel={(answerType) => answerType.name}
          id='change-selected-response-types'
          isOptionEqualToValue={(a, b) => a.id === b.id}
          onChange={(type) => {
            changeSelectedAnswerTypes(type);
            document.getElementById('change-selected-response-types')?.blur();
          }}
          options={selectableAnswerTypes}
          placeholder='Change Response Type'
          value={null as AnswerType | null}
        />
      </div>
      {questions.map((question, index) => (
        <CategorizeQuestion index={index} key={`${question.text}-${index}`} />
      ))}
    </div>
  );
};
