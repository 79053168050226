import {
  BuildingOfficeIcon,
  GlobeAmericasIcon,
} from '@heroicons/react/24/outline';
import { FC } from 'react';

import BinaryChoice from '../stepper/binaryChoice';

const GlobalOrFacilityChoice: FC<{
  selectFacility: () => void;
  selectGlobal: () => void;
}> = ({ selectFacility, selectGlobal }) => {
  return (
    <BinaryChoice
      left={{
        description:
          'Create a template that is available to every facility in your organization.',
        icon: <GlobeAmericasIcon />,
        id: 'global-choice',
        onSelect: selectGlobal,
        title: 'Global Template',
      }}
      right={{
        description:
          'Create a template that is available to a single facility in your organization.',
        icon: <BuildingOfficeIcon />,
        id: 'facility-choice',
        onSelect: selectFacility,
        title: 'Facility Template',
      }}
    />
  );
};

export default GlobalOrFacilityChoice;
