import { clsx } from 'clsx';
import { ReactNode } from 'react';

const TabNavigation = ({ children }: { children?: ReactNode }) => {
  return (
    <nav
      className={clsx(
        'flex justify-start gap-4 sm:gap-8 overflow-x-auto',
        'px-4 sm:px-8 pt-4 pb-0',
        'border-b border-gray-200',
        '[&>a]:py-4',
        '[&>a]:group [&>a]:inline-flex [&>a]:items-start [&>a]:border-b-2',
        '[&>a]:px-1 [&>a]:text-sm [&>a]:font-medium',
        '[&>a]:whitespace-nowrap',
        '[&>a.active]:text-green-base [&>a.active]:border-green-base',
        '[&>a:not(.active)]:border-transparent [&>a:not(.active)]:text-gray-500',
        '[&>a:not(.active):hover]:border-gray-300',
        '[&>a:not(.active):hover]:text-gray-700',
      )}
    >
      {children}
    </nav>
  );
};

export default TabNavigation;
