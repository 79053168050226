import { Question } from '@dakota/platform-client';

/**
 * Compares Question properties for sorting by group, name, and text (in that
 * order).
 */
export const compareQuestionProperties = (a: Question, b: Question) => {
  return (
    a.itemType.group.localeCompare(b.itemType.group) ||
    a.itemType.name.localeCompare(b.itemType.name) ||
    a.text.localeCompare(b.text)
  );
};
