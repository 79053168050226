import NavIcon from 'components/NavIcon';
import { HeroIcon } from 'components/NavIcon/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { noOp } from 'utils/functional';

type Props = {
  icon: HeroIcon;
  id: string;
  isMobile?: boolean;
  isSidebarOpen: boolean;
  title: string;
  to: string;
  toggleSidebar?: () => void;
};

const NavLink: FC<Props> = ({
  icon: Icon,
  id,
  isMobile,
  isSidebarOpen,
  title,
  to,
  toggleSidebar = noOp,
}) => {
  return (
    <Link id={id} onClick={toggleSidebar} to={to}>
      {isSidebarOpen || isMobile ? (
        <NavIcon expanded icon={Icon} title={title} />
      ) : (
        <NavIcon icon={Icon} title={title} />
      )}
    </Link>
  );
};

export default NavLink;
