import {
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const { theme: dakotaTheme } = resolveConfig(tailwindConfig);

/**
 * Wrapper around the MUI tooltip, with one addition: when `disabled` is `true`,
 * the tooltip will not be rendered, and the children will be rendered as-is.
 */
const Tooltip = styled(
  ({
    className,
    disabled,
    ...props
  }: TooltipProps & { disabled?: boolean }) => {
    if (disabled) {
      return props.children;
    }
    return <MuiTooltip {...props} classes={{ popper: className }} />;
  },
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: dakotaTheme.fontFamily.sans.join(','),
    fontSize: dakotaTheme.fontSize.xs,
    fontWeight: dakotaTheme.fontWeight.light,
  },
}));

export default Tooltip;
