import PermissionGuard from 'auth/PermissionGuard';
import TabNavigation from 'components/TabNavigation';
import { NavLink, Outlet } from 'react-router-dom';
import { Permission } from 'utils/permissions';

const Organization = () => {
  return (
    <div className={'h-full'}>
      <TabNavigation>
        <PermissionGuard permissions={Permission.CreateFacility}>
          <NavLink to='facilities'>Facilities</NavLink>
        </PermissionGuard>
        <PermissionGuard permissions={Permission.ManageUsers}>
          <NavLink to='users'>Users</NavLink>
        </PermissionGuard>
      </TabNavigation>
      <Outlet />
    </div>
  );
};

export default Organization;
