import { RoleType, UserDetails, UserSummary } from '@dakota/platform-client';

export const unassignedUser = UserDetails.fromJS({
  accessLevel: RoleType.Normal,
  canBeManaged: false,
  displayName: 'Unassigned',
  firstName: 'Unassigned',
  fullName: 'Unassigned',
  inactive: false,
  lastName: 'Unassigned',
});

export const toUserSummary = (user: UserDetails) =>
  ({
    email: user.email,
    id: user.id,
    inactive: user.inactive,
    name: user.displayName,
  }) as UserSummary;
