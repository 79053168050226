import TabNavigation from 'components/TabNavigation';
import { NavLink, Outlet } from 'react-router-dom';

const Inspections = () => {
  return (
    <div className='h-full bg-white' data-testid='inspections'>
      <TabNavigation>
        <NavLink to={'scheduled'}>Scheduled Inspections</NavLink>
        <NavLink to={'completed'}>Completed Inspections</NavLink>
      </TabNavigation>
      <Outlet />
    </div>
  );
};
export default Inspections;
