export const TelemetryData = {
  Application: 'Application',
  AzureEnvironment: 'AzureEnvironment',
  Environment: 'Environment',
  Version: 'ImageVersion',
} as const;

export const IdentityData = {
  OrgId: 'Identity.OrganizationId',
  OrgName: 'Identity.OrganizationName',
  UserId: 'Identity.UserId',
  UserName: 'Identity.UserName',
} as const;

Object.freeze(TelemetryData);
Object.freeze(IdentityData);
