import {
  CreateInspectionRequest,
  FormSummary,
  Inspection,
  Summary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { ClientData } from 'features/clientProvider';
import { configSlice } from 'features/config/configSlice';
import { createInspection } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { listFacilityUsers } from 'features/user/userActions';
import { userSlice } from 'features/user/userSlice';
import { zonesSlice } from 'features/zones/zonesSlice';
import useToast from 'hooks/useToast';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { useAppDispatch } from 'store/store';
import { unassignedUser } from 'utils/user';

export interface TemplateWorkflowProps {
  form: FormSummary;
  scheduledAssigneeId?: string;
  scheduledInspectionDate?: LocalDate;
}

export const useTemplateWorkflow = ({
  form,
  scheduledAssigneeId,
  scheduledInspectionDate,
}: TemplateWorkflowProps) => {
  const dispatch = useAppDispatch();
  const { setErrorMessage } = useToast();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const selectableFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );
  const usersPerFacility = useSelector(userSlice.selectors.usersPerFacility);
  const zonesPerInspection = useSelector(zonesSlice.selectors.orderedZones);

  const inspectionFacilityId = form.facility?.id;

  const [facility, setFacility] = useState<null | Summary>(
    form.facility ?? null,
  );
  const [date, setDate] = useState<DateValueType>(null);
  const [acknowledgmentDialogOpen, setAcknowledgmentDialogOpen] =
    useState(false);

  const [assignee, setAssignee] = useState(unassignedUser);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [scheduledInspection, setScheduledInspection] = useState<
    Inspection | undefined
  >(undefined);
  const [isAssigneeInitialized, setIsAssigneeInitialized] = useState(false);

  const selectableUsers = [unassignedUser].concat(
    usersPerFacility.get(facility?.id ?? '') ?? [],
  );

  useEffect(() => {
    if (inspectionFacilityId) {
      const targetFacility = selectableFacilities.find(
        (f) => f.id === inspectionFacilityId,
      );
      setFacility(targetFacility || null);
    }
  }, [inspectionFacilityId, selectableFacilities]);

  useEffect(() => {
    if (scheduledInspectionDate) {
      setDate({
        endDate: scheduledInspectionDate.toString(),
        startDate: scheduledInspectionDate.toString(),
      });
    }
  }, [scheduledInspectionDate]);

  useEffect(() => {
    // Only dispatch the action if the facility is selected
    // and the users for that facility are not already loaded
    if (facility && !usersPerFacility.get(facility.id)) {
      const listFacilityUsersData = {
        active: true,
        baseUrl,
        directFacilityOnly: false,
        facilityId: facility.id,
        orgWide: false,
        token,
      };

      void dispatch(listFacilityUsers(listFacilityUsersData));
    }
  }, [baseUrl, dispatch, facility, token, usersPerFacility]);

  // Go back to unassigned if the new facility doesn't have the selected
  // assignee
  useEffect(() => {
    if (
      !!facility &&
      !usersPerFacility
        .get(facility.id)
        ?.find((user) => user.id === assignee.id)
    ) {
      setAssignee(unassignedUser);
    }
  }, [usersPerFacility, assignee.id, facility]);

  useEffect(() => {
    if (isDialogOpen && !isAssigneeInitialized && inspectionFacilityId) {
      // Initialize assignee dropdown when dialog opens for the first time
      const targetUser = selectableUsers.find(
        (user) => user.id === scheduledAssigneeId,
      );
      setAssignee(targetUser || unassignedUser);
      setIsAssigneeInitialized(true);
    }
    if (!isDialogOpen) {
      setIsAssigneeInitialized(false);
    }
  }, [
    isDialogOpen,
    inspectionFacilityId,
    scheduledAssigneeId,
    selectableUsers,
    isAssigneeInitialized,
  ]);

  const scheduleInspection = async () => {
    if (!facility || !date?.startDate) {
      return;
    }
    setScheduling(true);
    try {
      const data = {
        baseUrl,
        facilityId: facility.id,
        formId: form.id,
        scheduledDate: date.startDate.toString(),
        token,
        userId: assignee.id !== unassignedUser.id ? assignee.id : undefined,
        zones: zonesPerInspection.map((zone) => zone.id),
      } as ClientData & CreateInspectionRequest;
      const result = await dispatch(createInspection(data)).then(unwrapResult);
      setScheduledInspection(result);
      setAcknowledgmentDialogOpen(true);
      // Only reset facility for global templates
      if (!form.facility) {
        setFacility(null);
      }
      setAssignee(unassignedUser);
      closeDialog();
    } catch (error) {
      setErrorMessage('Failed to schedule inspection.');
    } finally {
      setScheduling(false);
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setDate(null);
  };

  return {
    acknowledgmentDialogOpen,
    assignee,
    closeDialog,
    date,
    facility,
    isDialogOpen,
    scheduledInspection,
    scheduleInspection,
    scheduling,
    selectableFacilities,
    selectableUsers,
    setAcknowledgmentDialogOpen,
    setAssignee,
    setDate,
    setFacility,
    setIsDialogOpen,
    setScheduledInspection,
    setScheduling,
  };
};
