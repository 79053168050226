import { Question } from '@dakota/platform-client';
import { Skeleton } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { questionsSlice } from 'features/questions/questionsSlice';
import { templatesSlice } from 'features/templates/templatesSlice';
import useQuestionFilters from 'hooks/useQuestionFilters';
import useToast from 'hooks/useToast';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import LibraryQuestion from './libraryQuestion';

const Questions: FC = () => {
  const dispatch = useAppDispatch();
  const isLoadingQuestions = useSelector(
    questionsSlice.selectors.isLoadingQuestions,
  );

  /**
   * The IDs of all the cuestions currently added to the template,
   * in any section and any order.
   */
  const currentQuestions = useSelector(templatesSlice.selectors.sections);
  const currentQuestionIds = useMemo(
    () => currentQuestions.flatMap((s) => s.questions).map((q) => q.id),
    [currentQuestions],
  );

  const {
    clearAllButton,
    filteredQuestions,
    highlight,
    itemGroupChips,
    itemGroupFilter,
    itemTypeChips,
    itemTypeFilter,
    searchInput,
  } = useQuestionFilters({
    exclude: currentQuestionIds,
    itemGroupClasses: 'pr-1',
    itemTypeClasses: 'pl-1',
    useStatusFilter: true,
  });

  const { setInfoMessage } = useToast();

  const addQuestion = (question: Question) => {
    dispatch(
      templatesSlice.actions.addQuestionToTemplate({
        question: {
          id: question.id,
          itemTypeId: question.itemType.id,
          itemTypeName: question.itemType.name,
          order: 0,
          text: question.text,
        },
      }),
    );
    let text = question.text.slice(0, 30);
    if (question.text.length > 30) {
      text += '...';
    }
    setInfoMessage(`Question "${text}" added to template`);
  };

  const addAllQuestions = () => {
    const total = filteredQuestions.length;
    filteredQuestions.forEach(addQuestion);
    setInfoMessage(`Added ${total} questions to template`);
  };

  return (
    <div
      className={clsx(
        'w-96 h-full flex-none flex flex-col',
        'text-sm leading-5 text-gray-700',
        'border-r border-gray-100 *:p-3',
      )}
    >
      <div className='flex-none h-16 flex items-center text-base border-b'>
        Questions Library
      </div>
      <div className='flex-none'>
        {searchInput}
        <div className='flex mt-1 *:py-3 *:w-1/2'>
          {itemGroupFilter}
          {itemTypeFilter}
        </div>
        {clearAllButton}
        {itemGroupChips}
        {itemTypeChips}
        <div className='hidden sm:block'>
          {filteredQuestions.length > 0 && !isLoadingQuestions && (
            <Button
              className='text-xs'
              data-testid='add-all-questions'
              id='add-all-questions'
              onClick={addAllQuestions}
              secondary
            >
              Add All ({filteredQuestions.length})
            </Button>
          )}
        </div>
      </div>
      <div
        className='flex-1 overflow-y-auto overscroll-contain'
        id='questions-container'
      >
        {isLoadingQuestions ? (
          <div className='flex flex-col gap-4'>
            {[...Array(10).keys()].map((k) => (
              <Skeleton height={60} key={k} variant='rounded' />
            ))}
          </div>
        ) : (
          filteredQuestions.map((question) => (
            <LibraryQuestion
              highlight={highlight}
              key={question.id}
              onSelect={() => addQuestion(question)}
              question={question}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Questions;
