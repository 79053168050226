import { FC } from 'react';
import { PulseLoader } from 'react-spinners';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

const Loading: FC = () => {
  return (
    <div
      className={'h-screen w-screen flex justify-center items-center'}
      data-testid='loading'
    >
      <PulseLoader color={theme.colors.green.base} />
    </div>
  );
};

export default Loading;
