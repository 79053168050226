import { Summary, Zone } from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { Dialog } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { configSlice } from 'features/config/configSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { listZones } from 'features/zones/zonesActions';
import { zonesSlice } from 'features/zones/zonesSlice';
import {
  TemplateWorkflowProps,
  useTemplateWorkflow,
} from 'hooks/useTemplateWorkflow';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { formatBackendDate } from 'utils/date';

import DialogBody from './DialogBody';
import DialogFooter from './DialogFooter';
import DialogHeader from './DialogHeader';

type ScheduleComponentProps = TemplateWorkflowProps;

const Schedule = ({
  form,
  scheduledAssigneeId,
  scheduledInspectionDate,
}: ScheduleComponentProps) => {
  const {
    acknowledgmentDialogOpen,
    assignee,
    closeDialog,
    date,
    facility,
    isDialogOpen,
    scheduledInspection,
    scheduleInspection,
    scheduling,
    selectableFacilities,
    selectableUsers,
    setAssignee,
    setDate,
    setFacility,
    setIsDialogOpen,
    setScheduledInspection,
  } = useTemplateWorkflow({
    form,
    scheduledAssigneeId,
    scheduledInspectionDate,
  });

  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const zonesPerFacility = useSelector(zonesSlice.selectors.zonesPerFacility);
  const orderedZones = useSelector(zonesSlice.selectors.orderedZones);
  const isLoadingZones = useSelector(zonesSlice.selectors.isLoadingAllZones);
  const [isOpenOverdueDialog, setIsOpenOverdueDialog] = useState(false);
  const [shouldRenderZones, setShouldRenderZones] = useState(false);
  const [selectableZones, setSelectableZones] = useState<Zone[]>([]);

  const [isFacilityMenuOpen, setIsFacilityMenuOpen] = useState(false);

  useEffect(() => {
    if (facility && zonesPerFacility.has(facility.id)) {
      setSelectableZones(
        zonesPerFacility.get(facility.id)?.filter((zone) => !zone.inactive) ??
          [],
      );
    }
  }, [facility, zonesPerFacility]);

  useEffect(() => {
    // Only fetch zones for facility if we haven't done so already
    if (facility && !zonesPerFacility.has(facility.id)) {
      void dispatch(listZones({ baseUrl, facilityId: facility.id, token }));
    }
  }, [baseUrl, dispatch, facility, token, zonesPerFacility]);

  const setZones = (zones: Summary[]) => {
    dispatch(zonesSlice.actions.setZones(zones));
  };

  const canStartNow = !!facility;
  const canSchedule = canStartNow && !!date;

  const getAcknowledgmentMessage = () => {
    if (!scheduledInspection) {
      return 'Inspection details are currently unavailable.';
    }

    return `<strong>${
      scheduledInspection.form.name
    }</strong> has been successfully scheduled ${
      scheduledInspection.userName
        ? `for <strong>${scheduledInspection.userName}</strong> `
        : ''
    }on <strong>${formatBackendDate(
      scheduledInspection.timeline.scheduledDate,
    )}.</strong>`;
  };

  const handleScheduleInspection = () => {
    const todaysDate = LocalDate.now().toString();
    const startDate = date?.startDate;

    if (startDate && typeof startDate === 'string' && startDate < todaysDate) {
      setIsOpenOverdueDialog(true);
      return;
    }

    void scheduleInspection();

    setZones([]);
    setShouldRenderZones(false);
  };

  const confirmOverdueSchedule = () => {
    setIsOpenOverdueDialog(false);
    void scheduleInspection();
  };

  const onClose = () => {
    setZones([]);
    setShouldRenderZones(false);
    closeDialog();
  };

  return (
    <>
      {scheduledInspection && (
        <AcknowledgmentDialog
          acknowledgmentText={getAcknowledgmentMessage()}
          isOpen={acknowledgmentDialogOpen}
          onAcknowledgment={() => setScheduledInspection(undefined)}
          title='Inspection Scheduled'
        />
      )}
      {isOpenOverdueDialog && (
        <AcknowledgmentDialog
          acknowledgmentText='This inspection is being scheduled for a date in the past, and will immediately be overdue. Do you wish to proceed?'
          buttonText={scheduling ? 'Scheduling...' : 'Schedule'}
          cancelButtonText='Go back'
          isOpen={isOpenOverdueDialog}
          onAcknowledgment={confirmOverdueSchedule}
          onCloseDialog={() => setIsOpenOverdueDialog(false)}
          title='Schedule Inspection'
        />
      )}
      <Button
        data-testid='scheduler-button'
        disabled={form.inactive}
        onClick={() => setIsDialogOpen(true)}
        secondary
      >
        Schedule
      </Button>
      <Dialog
        data-testid='scheduler-dialog'
        fullWidth
        maxWidth={orderedZones.length > 1 ? 'md' : 'sm'}
        onClose={(_, reason) => reason !== 'backdropClick' && onClose()}
        open={isDialogOpen}
      >
        <div
          className={clsx('flex flex-col justify-between', {
            'min-h-[700px]': isFacilityMenuOpen,
          })}
        >
          <DialogHeader onClose={onClose} title={form.name} />
          <DialogBody
            assignee={assignee}
            date={date}
            facility={facility}
            form={form}
            isLoadingZones={isLoadingZones}
            orderedZones={orderedZones}
            selectableFacilities={selectableFacilities}
            selectableUsers={selectableUsers}
            selectableZones={selectableZones}
            setAssignee={setAssignee}
            setDate={setDate}
            setFacility={setFacility}
            setIsFacilityMenuOpen={setIsFacilityMenuOpen}
            setShouldRenderZones={setShouldRenderZones}
            setZones={setZones}
            shouldRenderZones={shouldRenderZones}
          />
          <DialogFooter
            canSchedule={canSchedule}
            handleScheduleInspection={handleScheduleInspection}
            onClose={onClose}
            scheduling={scheduling}
          />
        </div>
      </Dialog>
    </>
  );
};

export default Schedule;
