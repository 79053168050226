import { Group, ItemsClient } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getAllItemGroups = createAsyncThunk(
  'items/groups',
  async (params: ClientData) => {
    const client = getClient(ItemsClient, params);
    const response = await client.listItemGroups();
    return response.result;
  },
);

/**
 * @return the item group that was just added
 */
export const addItemGroup = createAsyncThunk(
  'items/groups/create',
  async (params: { itemGroup: Group } & ClientData) => {
    const client = getClient(ItemsClient, params);
    const response = await client.createItemGroup(params.itemGroup);
    return response.result;
  },
);

/**
 * @return the updated item group
 */
export const updateItemGroup = createAsyncThunk(
  'items/groups/update',
  async (params: { itemGroup: Group } & ClientData) => {
    const client = getClient(ItemsClient, params);
    const response = await client.updateItemGroups(
      params.itemGroup.id,
      params.itemGroup,
    );
    return response.result;
  },
);

/**
 * @return the id of the activated item group
 */
export const activateItemGroup = createAsyncThunk(
  'items/groups/activate',
  async (params: { id: string } & ClientData, { fulfillWithValue }) => {
    const client = getClient(ItemsClient, params);
    await client.reactivateGroup(params.id);
    return fulfillWithValue(params.id);
  },
);

/**
 * @return the id of the deactivated item group
 */
export const deactivateItemGroup = createAsyncThunk(
  'items/groups/deactivate',
  async (params: { id: string } & ClientData, { fulfillWithValue }) => {
    const client = getClient(ItemsClient, params);
    await client.deactivateItemGroup(params.id);
    return fulfillWithValue(params.id);
  },
);
