import { TaskInstance } from '@dakota/platform-client';
import { FC } from 'react';

import { taskPriority } from './style';

type MetaProps = {
  onClick: () => void;
  task: TaskInstance;
};

export const Meta: FC<MetaProps> = ({ onClick, task }) => {
  return (
    <button
      className='flex-1 flex sm:items-center gap-4 max-sm:flex-col'
      onClick={onClick}
    >
      <div>
        Due: <strong>{task.timeline.scheduledDate}</strong>
      </div>
      <div className='flex items-center gap-1'>
        Priority: {taskPriority(task)}
      </div>
      <div>
        Facility: <strong>{task.facility.name}</strong>
      </div>
      {task.zone && (
        <div>
          Zone: <strong>{task.zone.name}</strong>
        </div>
      )}
    </button>
  );
};
