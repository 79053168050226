import {
  ArrowDownIcon,
  EllipsisVerticalIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Popover } from '@mui/material';
import { clsx } from 'clsx';
import { FC, ReactNode, useState } from 'react';

interface CustomDropdownProps {
  buttonAriaLabel?: string;
  buttonTestId?: string;
  children: ReactNode;
  contentTestId?: string;
  footer?: ReactNode;
  header?: ReactNode;
}

const MenuDropdown: FC<CustomDropdownProps> = ({
  buttonAriaLabel,
  buttonTestId,
  children,
  contentTestId,
  footer,
  header,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = (
    <div>
      <div className='max-sm:hidden'>
        <EllipsisVerticalIcon />
      </div>
      <div className='sm:hidden inline-flex items-center gap-1'>
        Actions
        <ArrowDownIcon className='w-4 h-4' />
      </div>
    </div>
  );

  const close = (
    <div>
      <div className='max-sm:hidden'>
        <XMarkIcon />
      </div>
      <div className='sm:hidden inline-flex items-center gap-1'>
        Close
        <XMarkIcon className='w-4 h-4' />
      </div>
    </div>
  );

  return (
    <>
      <button
        aria-label={buttonAriaLabel}
        className='sm:w-6 max-sm:min-w-6 h-6 text-green-base'
        data-testid={buttonTestId}
        onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
      >
        {anchorEl ? close : open}
      </button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <div data-testid={contentTestId}>
          {header && (
            <div
              className={
                'flex items-center justify-center px-4 py-2 bg-gray-100'
              }
            >
              {header}
            </div>
          )}
          {children}
          {footer && (
            <div
              className={clsx(
                'flex items-center justify-center gap-2',
                'px-1 py-2 border-t rounded-b-md border-gray',
              )}
            >
              {footer}
            </div>
          )}
        </div>
      </Popover>
    </>
  );
};

export default MenuDropdown;
