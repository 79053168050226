import { clsx } from 'clsx';
import React from 'react';

type LabelProps = {
  color: string;
  label: string;
};

type LegendProps = {
  labels: LabelProps[];
};

const LegendLabel: React.FC<LabelProps> = ({ color, label }) => {
  return (
    <div className='px-3'>
      <svg className='block mx-auto mb-1' height='16' width='16'>
        <rect fill={color} height='16' width='16' />
      </svg>
      {label}
    </div>
  );
};

const Legend: React.FC<LegendProps> = ({ labels }) => {
  return (
    <div
      className={clsx(
        'flex-1 flex flex-wrap justify-center items-center gap-2',
        'text-xs text-gray-500 w-3/4',
      )}
    >
      {labels.map((label) => (
        <LegendLabel
          color={label.color}
          key={label.label}
          label={label.label}
        />
      ))}
    </div>
  );
};

export default Legend;
