import { Dialog } from '@mui/material';
import Button from 'components/Button';
import { FC, PropsWithChildren } from 'react';

type Props = {
  /**
   * Text for the aria-label attribute of the cancel button.
   */
  cancelLabel?: string;
  /**
   * Text for the cancel button.
   */
  cancelText: string;
  /**
   * Text for the aria-label attribute of the confirmation button.
   */
  confirmLabel?: string;
  /**
   * Text for the confirm button.
   */
  confirmText: string;
  /**
   * When `true`, the confirm button is disabled and shows a loading spinner.
   * @default `false`
   */
  loading?: boolean;
  /**
   * Function to call when the cancel button is clicked.
   */
  onCancel: () => void;
  /**
   * Function to call when the confirm button is clicked.
   */
  onConfirm: () => void;
  /**
   * Title for the confirmation dialog. Not rendered if not present.
   * @default `undefined`
   */
  title?: string;
};

const Confirmation: FC<PropsWithChildren<Props>> = ({
  cancelLabel,
  cancelText,
  children,
  confirmLabel,
  confirmText,
  loading = false,
  onCancel,
  onConfirm,
  title = undefined,
}) => {
  return (
    <Dialog onClose={onCancel} open>
      <div
        className='flex flex-col gap-4 p-8'
        data-testid='confirmation-dialog'
      >
        {title && <div className='text-xl text-gray-700'>{title}</div>}
        <div className='text-base text-gray-700 text-pretty'>{children}</div>
        <div className='flex gap-2'>
          <Button
            alert
            aria-label={confirmLabel}
            loading={loading}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
          <Button aria-label={cancelLabel} onClick={onCancel} secondary>
            {cancelText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default Confirmation;
