import { InspectionZone } from '@dakota/platform-client';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  BuildingOfficeIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { Dialog } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import {
  countSectionQuestions,
  sectionCounts,
} from 'features/inspections/counters';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { FC, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

type ZonesMenuProps = {
  zones: InspectionZone[];
};

export const ZonesMenu: FC<ZonesMenuProps> = ({ zones }) => {
  const dispatch = useAppDispatch();
  const [zoneListOpen, setZoneListOpen] = useState(false);

  const currentZoneIndex = useSelector(
    inspectionSlice.selectors.currentZoneIndex,
  );

  const currentZone = zones[currentZoneIndex];
  const { answered, total } = countSectionQuestions(currentZone.sections);

  const buttonStyles = clsx(
    'self-center flex gap-1 items-center text-green-base h-5',
    'disabled:opacity-50 disabled:cursor-not-allowed',
  );

  return (
    <div
      className={clsx(
        'flex-auto flex justify-between gap-2 sm:gap-4',
        'text-sm sm:text-base',
      )}
    >
      <button
        aria-label='Go to previous zone'
        className={buttonStyles}
        disabled={currentZoneIndex === 0}
        onClick={() => dispatch(inspectionSlice.actions.goToPreviousZone())}
      >
        <ArrowLongLeftIcon className='max-sm:hidden h-full' />
        <ChevronLeftIcon className='sm:hidden h-full' />
        <span className='max-sm:hidden'>Prev</span>
      </button>
      <button
        aria-label='Current zone'
        className={clsx(
          'flex-1 flex items-center gap-2 p-2',
          'border border-gray-300 rounded-md',
          'hover:bg-gray-100 focus:bg-gray-100',
        )}
        onClick={() => setZoneListOpen(true)}
      >
        <BuildingOfficeIcon className='h-6 text-green-base' />
        <div className='text-gray-700'>{currentZone.name}</div>
        <div className='text-gray-300'>
          {answered}/{total}
        </div>
      </button>
      <button
        aria-label='Go to next zone'
        className={buttonStyles}
        disabled={currentZoneIndex === zones.length - 1}
        onClick={() => dispatch(inspectionSlice.actions.goToNextZone())}
      >
        <span className='max-sm:hidden'>Next</span>
        <ArrowLongRightIcon className='max-sm:hidden h-full' />
        <ChevronRightIcon className='sm:hidden h-full' />
      </button>
      {zoneListOpen && (
        <Dialog onClose={() => setZoneListOpen(false)} open>
          <div
            className={clsx(
              'p-3 sm:p-6 grid grid-cols-3 gap-2 sm:gap-4',
              'text-gray-500 items-center',
            )}
          >
            <div className='font-medium text-gray-700'>Zone name</div>
            <div className='font-medium text-gray-700 text-center'>
              Questions
            </div>
            <div></div>
            {zones.map((zone, index) => (
              <Fragment key={zone.id}>
                <div className='italic'>{zone.name}</div>
                <div className='text-center'>
                  {sectionCounts(zone.sections)}
                </div>
                <div className='text-right'>
                  <Button
                    aria-label={`Go to ${zone.name}`}
                    className='text-xs'
                    disabled={index === currentZoneIndex}
                    onClick={() => {
                      dispatch(inspectionSlice.actions.goToZone(zone.id));
                      setZoneListOpen(false);
                    }}
                  >
                    Go <ChevronRightIcon className='w-4' />
                  </Button>
                </div>
              </Fragment>
            ))}
          </div>
        </Dialog>
      )}
    </div>
  );
};
