import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import Input from 'components/Input';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useEventListener, useOnClickOutside } from 'usehooks-ts';

import { EditableTextButton } from './button';

export type EditableTextProps = {
  /**
   * Allow an empty value to be saved.
   * @default `false`
   */
  allowEmpty?: boolean;
  /**
   * The classes to apply to the container.
   * @default 'w-96'
   */
  containerClasses?: string;
  /**
   * Whether the editing functionality is disabled.
   * @default `false`
   */
  disabled?: boolean;
  /**
   * If `true`, the component mounts in edit mode and the input is focused.
   * @default `false`
   */
  editOnMount?: boolean;
  /**
   * If present, this error message is displayed below the input field,
   * and saving is disabled.
   *
   * Validation is the responsibility of the caller. Note that validation
   * only happens when the users saves the text, and *not* each time a new
   * character is added or removed.
   * @default `undefined`
   */
  errorMessage?: string;
  /**
   * If `true`, the input button grows vertically to show the full text.
   * @default `false`
   */
  growVertically?: boolean;
  /**
   * The classes to apply to the icon.
   * @default 'w-4 h-4'
   */
  iconClasses?: string;
  /**
   * The value for data-testid and ID. It is used as the ID
   * of the overall container, and as a prefix for the text field
   * and the 'button' that contains the text.
   *
   * The ID of the button with the text is `${id}-button`, and
   * the ID of the text input to write a new value is is `${id}-input`.
   * @default 'editable-text-editor'
   */
  id?: string;
  /**
   * Initial value to display when editing. It defaults to the current text.
   *
   * When this initial value is the empty string (''), the value
   * of `text` is used as a placeholder in the `input` component.
   * @default `text`
   */
  initialEditableValue?: string;
  /**
   * If `true`, editing is disabled and a spinner is shown before the text.
   * @default `false`
   */
  loading?: boolean;
  /**
   * If present, defines the maximum length of the text.
   * @default `undefined`
   */
  maxLength?: number;
  /**
   * Optional function to call when the user cancels the edit without saving.
   * @default `undefined`
   */
  onCancelEdit?: () => void;
  /**
   * Function to call when the text is edited.
   */
  onEdit: (newText: string) => void;
  /**
   * If `true`, it adds a red asterisk at the end of the placeholder text.
   * @default `false`
   */
  required?: boolean;
  /**
   * If `true`, the text is saved when the `Enter` key is pressed.
   * @default `false`
   */
  saveOnEnter?: boolean;
  /**
   * Where to show the pencil icon that indicates editing capabilities.
   *
   * If `'inline'`, the icon is shown immediately next to the text.
   * If `'end'`, the icon is shown at the end of the container, separated
   * with justify-content space-between from the text.
   * If `false`, the icon is not shown.
   * @default 'end'
   */
  showIcon?: 'end' | 'inline' | false;
  /**
   * If `true`, the text is styled differently after the first save.
   * @default `false`
   */
  styleAfterSave?: boolean;
  /**
   * The text to display.
   */
  text: string;
  /**
   * The classes to apply to the text when *not* being edited.
   * @default ''
   */
  textClasses?: string;
};

const EditableText: FC<EditableTextProps> = ({
  allowEmpty = false,
  containerClasses = 'w-96',
  disabled = false,
  editOnMount = false,
  errorMessage = undefined,
  growVertically = false,
  iconClasses = 'w-4 h-4',
  id = 'editable-text-editor',
  loading = false,
  maxLength = undefined,
  text,
  initialEditableValue = text,
  onCancelEdit = undefined,
  onEdit,
  required = false,
  saveOnEnter = false,
  showIcon = 'end',
  styleAfterSave = false,
  textClasses = '',
}) => {
  const [isEditing, setIsEditing] = useState(editOnMount);
  const [unsavedValue, setUnsavedValue] = useState(initialEditableValue);
  const [savedValue, setSavedValue] = useState(initialEditableValue);

  // True until the user saves for the first time
  const [isFirstEdit, setIsFirstEdit] = useState(true);

  // Used to display a darker text color after the first save
  const [hasBeenSaved, setHasBeenSaved] = useState(false);

  // Used to display the number of characters left
  const charactersLeft = maxLength
    ? maxLength - unsavedValue.length
    : undefined;

  const isNewValueValid =
    (allowEmpty || unsavedValue !== '') && unsavedValue !== savedValue;

  /**
   * Stop editing the text, and call `onEdit` if the value has changed.
   */
  const saveAndStopEditing = () => {
    if (isEditing && isNewValueValid) {
      setSavedValue(unsavedValue);
      setIsFirstEdit(false);
      onEdit(unsavedValue);
    } else {
      cancelAndStopEditing();
    }
    setIsEditing(false);
  };

  /**
   * Cancel the current edit and discard the changes. `unsavedValue` is set
   * to the current `savedValue` so that the user sees that the next time
   * they try to edit the text.
   */
  const cancelAndStopEditing = () => {
    if (onCancelEdit) {
      onCancelEdit();
    }
    setIsEditing(false);
    setUnsavedValue(savedValue);
  };

  const containerRef = useRef(null);
  useOnClickOutside(containerRef, saveAndStopEditing);

  useEventListener(
    'keydown',
    (e) => {
      if (isEditing && saveOnEnter && e.key === 'Enter') {
        saveAndStopEditing();
      }
    },
    containerRef,
  );

  useLayoutEffect(() => {
    if (isEditing) {
      document.getElementById(`${id}-input`)?.focus();
    }
  }, [id, isEditing]);

  useEffect(() => {
    if (!hasBeenSaved && savedValue !== initialEditableValue) {
      setHasBeenSaved(true);
    }
  }, [savedValue, initialEditableValue, hasBeenSaved]);

  return (
    <div className={containerClasses} data-testid={id} id={id}>
      {isEditing ? (
        <div className='relative' ref={containerRef}>
          <Input
            className={clsx('w-full', {
              'focus:ring-red-base': !!errorMessage,
            })}
            data-testid={`${id}-input`}
            id={`${id}-input`}
            maxLength={maxLength}
            multiple={growVertically}
            onChange={(e) => setUnsavedValue(e.target.value)}
            placeholder={initialEditableValue === '' ? text : ''}
            type='text'
            value={unsavedValue}
          />
          <div className='absolute w-full flex justify-between gap-1 p-1 z-20'>
            <p
              className={clsx(
                'flex-1 max-w-40 p-1 m-0 rounded',
                'text-xs text-white bg-red-base',
              )}
              style={{
                opacity:
                  charactersLeft !== undefined && charactersLeft <= 30
                    ? 1.3 - charactersLeft / 30
                    : 0,
              }}
            >
              {maxLength && `${charactersLeft} characters remaining`}
            </p>
            <div className='flex-none space-x-1'>
              <Button
                aria-label='Save changes'
                className='w-7 h-7'
                disabled={!isNewValueValid}
                icon={<CheckIcon />}
                id={`${id}-save`}
                onClick={(e) => {
                  e.stopPropagation();
                  saveAndStopEditing();
                }}
                secondary
              />
              <Button
                aria-label='Cancel changes'
                className='w-7 h-7'
                icon={<XMarkIcon />}
                id={`${id}-cancel`}
                onClick={(e) => {
                  e.stopPropagation();
                  cancelAndStopEditing();
                }}
                secondary
              />
            </div>
          </div>
        </div>
      ) : (
        <EditableTextButton
          {...{
            disabled,
            errorMessage,
            growVertically,
            hasBeenSaved,
            iconClasses,
            id,
            initialEditableValue,
            isFirstEdit,
            loading,
            required,
            setIsEditing,
            setSavedValue,
            setUnsavedValue,
            showIcon,
            styleAfterSave,
            text,
            textClasses,
          }}
        />
      )}
    </div>
  );
};

export default EditableText;
