import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  AutocompleteOwnerState,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Autocomplete as MuiAutocomplete,
  TextField,
} from '@mui/material';
import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import { ReactNode, useEffect, useState } from 'react';

import { renderOptionWithCheckmark } from './renderFunctions';
import { autocompleteStyles } from './styles';

/**
 * Props for Autocomplete component.
 * `T` is the type of the options, while `U` indicates the type of the selected
 * values, which is `T` for single-value selects and `T[]` for
 * multi-value selects.
 *
 */
type AutocompleteProps<T, U = T> = U extends T | T[]
  ? {
      /**
       * The value for the autocomplete attribute passed to the input element.
       * @default undefined
       */
      autoComplete?: string;
      /**
       * Additional class names to apply to autocomplete component.
       */
      className?: string;
      /**
       * When 'true' the autocomplete is disabled.
       */
      disabled?: boolean;
      /**
       * If true, the input will take up the full width of its container.
       */
      fullWidth?: boolean;
      /**
       * Map each option to a unique key.
       * @default 'String(getOptionLabel)', that is, explicit coercion to string
       * of the value returned by `getOptionLabel`.
       */
      getOptionKey?: (option: T) => string;
      /**
       * Determine how to display an option in the list.
       * @default 'String(option)', that is, explicit coercion to string.
       */
      getOptionLabel?: (option: T) => string;
      /**
       * If provided, the options will be grouped under the returned string.
       * The groupBy value is also used as the text for group headings
       * when `renderGroup` is not provided.
       */
      groupBy?: (option: T) => string;
      /**
       * The ID of the container element. If testId is `undefined`,
       * `id` is also used as the `data-testid` attribute.
       */
      id?: string;
      /**
       * If present, it is used to compare options to check they're equal.
       * Uses strict equality by default.
       */
      isOptionEqualToValue?: (option: T, value: T) => boolean;
      /**
       * The label of the autocomplete component.
       * Also acts as the placeholder when no value is selected.
       */
      label?: string;
      /**
       * When 'true' the autocomplete is in a loading state.
       * Component will be disabled, display a spinner, and 'Loading...' text.
       */
      loading?: boolean;
      /**
       * When 'true', the autocomplete has no border
       * @default false
       */
      noBorder?: boolean;
      /**
       * Callback invoked when the selected value(s) change.
       */
      onChange: (value: U) => void;
      /**
       * Callback fired when the popup requests to be closed.
       * @default undefined
       */
      onClose?: (event: React.SyntheticEvent) => void;
      /**
       * Callback fired when the popup requests to be opened.
       * @default undefined
       */
      onOpen?: (event: React.SyntheticEvent) => void;
      /**
       * The list of options to display in the autocomplete list.
       */
      options: ReadonlyArray<T>;
      /**
       * The label to display above the autocomplete.
       * The default label will de hidden.
       * @default undefined
       */
      outsideLabel?: string;
      renderGroup?: (params: AutocompleteRenderGroupParams) => ReactNode;
      /**
       * If present, this function will be called instead of the default
       * renderOption implementation with checkmark and X icons.
       */
      renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: T,
        state: AutocompleteRenderOptionState,
        ownerState: AutocompleteOwnerState<T, boolean, boolean, false>,
      ) => ReactNode;
      /**
       * If present, it is used for `data-testid`.
       */
      testId?: string;
      /**
       * The current selected value(s). When `null`, the field is empty.
       */
      value: null | U;
    } & ClearableProps &
      PlaceholderProps
  : never;

type ClearableProps =
  /**
   * When clearable is true, multiple and required cannot be used.
   * An incompatibility error will be shown */
  | {
      /**
       * When 'true' and Multiple and Required are false, the clear button is
       * displayed. Allows for clearing the selected value in single select
       * mode.
       * @default false
       */
      clearable?: boolean;
      multiple?: never;
      required?: never;
    }
  /**
   * When multiple or required are true, clearable cannot be used.
   * An incompatibility error will be shown */
  | {
      clearable?: never;
      /**
       * When 'true' the autocomplete allows multiple selections and
       * disableCloseOnSelect. will be true and option list will not close
       * until user clicks outside.
       */
      multiple?: boolean;
      /**
       * When 'true' the autocomplete is required.
       * A warning message will be displayed if the field is empty or
       * if the user tries to remove the last selected option.
       */
      required?: boolean;
    };

/**
 * Props for Placeholder in textfield
 * Label use is preferred over placeholder
 * When multiple is true, placeholder cannot be used, because it persists next
 * to the selected values. When placeholder is used, multiple cannot be true.
 */
type PlaceholderProps =
  | {
      multiple?: boolean;
      placeholder?: never;
    }
  | {
      multiple?: never;
      placeholder?: string;
    };

const Autocomplete = <T, U>({
  autoComplete = undefined,
  className = '',
  clearable = false,
  disabled = false,
  fullWidth,
  getOptionKey = (t) => getOptionLabel(t),
  getOptionLabel = (t) => String(t),
  groupBy,
  id,
  isOptionEqualToValue,
  label = '',
  loading = false,
  multiple = false,
  noBorder = false,
  onChange,
  onClose = undefined,
  onOpen = undefined,
  options,
  outsideLabel = undefined,
  placeholder,
  renderGroup,
  renderOption,
  required = false,
  testId = undefined,
  value = null,
}: AutocompleteProps<T, U>) => {
  type SelectedType = typeof value;

  const [selectedOptions, setSelectedOptions] = useState<SelectedType>(value);
  const [showRequiredWarning, setShowRequiredWarning] = useState(false);

  useEffect(() => setSelectedOptions(value), [value]);

  useEffect(() => {
    if (showRequiredWarning) {
      const timer = setTimeout(() => setShowRequiredWarning(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [showRequiredWarning]);

  const canBeSelected = (option: Exclude<SelectedType, null>) => {
    if (!required) {
      // Can always select or deselect options when the field is not required
      return true;
    }
    if (multiple) {
      // Prevent removing when there's only one option selected
      return (value as T[]).length !== 1 || (option as T[]).length > 0;
    } else {
      // Prevent removing the (only) selected option
      return option !== value;
    }
  };

  const handleSelectionChange = (val: Exclude<SelectedType, null>) => {
    if (canBeSelected(val)) {
      onChange(val);
      setShowRequiredWarning(false);
    } else {
      setShowRequiredWarning(true);
    }
  };

  const spinner = (
    <div
      className={clsx(
        'flex items-center gap-1.5',
        'font-sans text-gray-400 text-sm',
      )}
    >
      <Spinner />
      Loading...
    </div>
  );

  const requiredText = (
    <span
      className={clsx(
        'font-sans text-red-base transition-all duration-500 absolute',
        showRequiredWarning
          ? 'opacity-100 translate-y-0'
          : 'opacity-0 -translate-y-1',
      )}
    >
      Field is required
    </span>
  );

  /**
   * By default, we render the string value of the option with a checkmark icon
   * for selected options, X for hovered selected options, and no icon for
   * unslected options.
   */
  const defaultRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState,
  ) => renderOptionWithCheckmark(props, getOptionLabel(option), state);

  const labelToShow = loading && !disabled ? spinner : label;

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <div>
      {outsideLabel && (
        <label
          className={clsx('text-gray-700 block mb-2', {
            'required-field': required,
          })}
          htmlFor={id}
        >
          {outsideLabel}
        </label>
      )}
      <TextField
        {...params}
        fullWidth
        helperText={required ? requiredText : undefined}
        hiddenLabel={!!outsideLabel}
        InputProps={{ ...params.InputProps, autoComplete }}
        label={outsideLabel ? '' : labelToShow}
        placeholder={placeholder}
        required={required}
        sx={noBorder ? { '& fieldset': { border: 'none' } } : {}}
        variant='outlined'
      />
    </div>
  );

  const renderTags = (values: T[]) => (
    <div
      className='truncate text-gray-700 font-normal ml-1'
      data-testid='selected-options'
    >
      {values.map(getOptionLabel).join(', ')}
    </div>
  );

  const popupIcon = (
    <ChevronDownIcon aria-hidden='true' className='w-5 h-5 text-green-base' />
  );

  return (
    <MuiAutocomplete
      className={className}
      data-testid={testId || id}
      disableClearable={multiple || required || !clearable}
      disableCloseOnSelect={multiple}
      disabled={disabled || loading}
      disablePortal
      fullWidth={fullWidth}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      id={id}
      isOptionEqualToValue={isOptionEqualToValue}
      // Force loading to false because that is handled in the disabled state
      loading={false}
      multiple={multiple}
      onChange={(_event, val) =>
        handleSelectionChange(val as Exclude<SelectedType, null>)
      }
      onClose={onClose}
      onOpen={onOpen}
      options={options}
      popupIcon={popupIcon}
      renderGroup={renderGroup}
      renderInput={renderInput}
      renderOption={renderOption ?? defaultRenderOption}
      renderTags={renderTags}
      size='small'
      sx={autocompleteStyles}
      value={selectedOptions as T[]}
    />
  );
};

export default Autocomplete;
