import { PendoConfig } from 'features/config/types';

import { UserTracker } from './userTracker';
import { Identity } from './util';

function getCookieDomain(value?: string): `.${string}` {
  if (value?.startsWith('.')) {
    value = value.substring(1);
  }

  if (!value) {
    value = window.location.host;
  }

  return `.${value}`;
}

let pendoLoaded: boolean = false;
export function loadPendo(config: PendoConfig) {
  if (pendoLoaded || !config.enabled) {
    return;
  }

  // configure only once
  pendoLoaded = true;

  /**
   * NOTE: To track anonymous users this flow will need to be different:
   * 1. call pendo.initialize() with the Anonymous Id (pendo.DEFAULT_VISITOR_ID)
   * 2. register a function with the UserTracker that:
   *   a. calls pendo.identify() with the new user
   *   b. invokes pendo.location.addTransforms manually (required because
   *   identify resets transforms)
   *
   * Since we aren't tracking anonymous users we can keep it simplified such
   * that we don't even initialize Pendo unless we have a real user.
   * This is the recommended flow by Pendo.
   * If we switch to the above then it also means we'll track regular users
   * under two different Ids because they'd get an anonymous ID at site load
   * before user flows are triggered.
   */

  const cookieDomain = getCookieDomain(config.cookieDomain);

  UserTracker.registerEvents({
    onClear: () => {
      // only clear if we aren't already 'logged out'.
      // This happens on initial page load even for a logged in user and logs a
      // bunch of warnings to the console we don't need if visitorId hasn't been
      // set, any visitor functions will log the error,
      // so skip
      if (pendo.visitorId && !pendo.isAnonymousVisitor()) {
        pendo.clearSession();
      }
    },
    onSet: (id: Identity) =>
      pendo.initialize({
        account: {
          // this property is used in data mappings as the account name by
          // ProActivity and we need to match it for existing reports
          companyName: id.orgName,
          // required
          id: id.orgId,
          // this is the official property
          name: id.orgName,
        },
        cookieDomain: cookieDomain,
        excludeAllText: true,
        location: {
          transforms: [
            { action: 'Replace', attr: 'pathname', data: trimIdFromUrls },
          ],
        },
        visitor: {
          // official property, required
          email: id.userName,
          // dakota custom property
          firstname: id.firstName,
          // official property, required
          id: id.userId,
          // dakota custom property
          lastname: id.lastName,
        },
      }),
  });
}

// For any URLs ending in "/<guid>", replace with "/--id--"
function trimIdFromUrls(current: string) {
  return current.replace(
    /\/[0-9A-F]{8}-([0-9A-F]{4}-){3}[0-9A-F]{12}$/i,
    '/--id--',
  );
}
