import { Question } from '@dakota/platform-client';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Menu } from '@mui/material';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import Button from 'components/Button';
import DakotaLibraryLogo from 'components/DakotaLibraryLogo';
import Table from 'components/Table';
import { ColumnProps } from 'components/Table/types';
import { getAnswerTypes } from 'features/answers/answersActions';
import { configSlice } from 'features/config/configSlice';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { itemTypesSlice } from 'features/items/itemTypesSlice';
import { questionsSlice } from 'features/questions/questionsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import useQuestionFilters from 'hooks/useQuestionFilters';
import FormBuilderStepperDialog from 'Pages/FormBuilder/stepper/stepperDialog';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import AddEditQuestions from './AddEditQuestions';
import { AddMultipleQuestions } from './AddMultipleQuestions';
import QuestionsMenu from './QuestionsMenu';

const InspectionsQuestions = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const appName = useSelector(configSlice.selectors.appName);

  const [pageDataLoaded, setPageDataLoaded] = useState(false);

  const { stopTracking } = usePageLoadTracking();

  /**
   * When multiple questions were added, they will be in this array.
   */
  const addedQuestions = useSelector(questionsSlice.selectors.addedQuestions);

  /**
   * Dispatch the action to get all the answer types.
   * Adding it here to prevent it from being called
   * every time the user adds or edits a question.
   */
  useEffect(() => {
    void dispatch(getAnswerTypes({ baseUrl, token })).then(() => {
      setPageDataLoaded(true);
    });
  }, [baseUrl, dispatch, token, stopTracking]);

  const isLoadingAllQuestions = useSelector(
    questionsSlice.selectors.isLoadingQuestions,
  );
  const isLoadingItemGroups = useSelector(
    itemGroupsSlice.selectors.isLoadingItemGroups,
  );
  const isLoadingItemTypes = useSelector(
    itemTypesSlice.selectors.isLoadingItemTypes,
  );

  const {
    clearAllButton,
    filteredQuestions,
    highlight,
    hookDataLoaded,
    itemGroupChips,
    itemGroupFilter,
    itemTypeChips,
    itemTypeFilter,
    searchInput,
    statusFilter,
  } = useQuestionFilters({
    itemGroupClasses: 'w-48',
    itemTypeClasses: 'w-56',
    statusClasses: 'w-28',
    useStatusFilter: true,
  });

  // We need to stop tracking when both the hook and page data have loaded
  useEffect(() => {
    if (hookDataLoaded && pageDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, pageDataLoaded, stopTracking]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isQuestionsOptionsOpen =
    Boolean(anchorEl) && addedQuestions.length === 0;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [isAddSingleModalOpen, setIsAddSingleModalOpen] = useState(false);
  const [isAddMultipleModalOpen, setIsAddMultipleModalOpen] = useState(false);

  const textGray400 = 'text-gray-400';
  const getInactiveClassName = (inactive: boolean) =>
    `flex flex-col space-y-1 ${inactive ? textGray400 : ''}`;

  const questionColumns: ReadonlyArray<ColumnProps<Question>> = [
    {
      cellDataTestId: 'item-type',
      key: 'itemType',
      render: (question) => {
        return (
          <>
            <div className={question.inactive ? textGray400 : 'text-gray-700'}>
              {highlight(question.itemType.name)}
            </div>
            <div className='text-xs font-normal text-gray-400'>
              {highlight(question.itemType.group)}
            </div>
          </>
        );
      },
      title: 'Item Type',
    },
    {
      cellDataTestId: 'question',
      key: 'text',
      // Show the question first on mobile
      order: 1,
      render: (question) => {
        return (
          <div
            className={`text-balance whitespace-pre-line ${
              question.inactive ? textGray400 : ''
            }`}
            data-testid={`question-${question.id}`}
          >
            {highlight(question.text)}
            {question.isPlatformDefined && <DakotaLibraryLogo />}
          </div>
        );
      },
      title: 'Question',
    },
    {
      cellDataTestId: 'citation',
      key: 'citation',
      render: (question) => {
        return (
          <div className={getInactiveClassName(question.inactive)}>
            {highlight(question.citation)}
          </div>
        );
      },
      title: 'Citation',
    },
    {
      cellDataTestId: 'answer-type',
      key: 'answerType',
      render: (question) => {
        return (
          <div className={question.inactive ? textGray400 : ''}>
            {question.answerType.label}
          </div>
        );
      },
      title: 'Response type',
    },
    {
      cellDataTestId: 'menu',
      key: 'menu',
      render: (question) =>
        !question.isPlatformDefined && <QuestionsMenu question={question} />,
      title: '',
    },
  ];

  return (
    <div className='p-4 md:p-8'>
      <Helmet>
        <title>Inspection Questions | Manage Content | {appName}</title>
      </Helmet>
      <div className='justify-between hidden w-full pb-8 md:flex'>
        <h2 className='text-2xl font-semibold'>Questions</h2>
        <div className='flex gap-2.5 items-center'>
          <PermissionGuard permissions={Permission.CreateQuestion}>
            <Button
              icon={<ChevronDownIcon />}
              iconPosition='right'
              onClick={handleClick}
            >
              Add Question
            </Button>
          </PermissionGuard>
        </div>
      </div>
      <div className='filters-container'>
        {searchInput}
        {itemGroupFilter}
        {itemTypeFilter}
        <PermissionGuard permissions={Permission.Admin}>
          {statusFilter}
        </PermissionGuard>
        {clearAllButton}
      </div>
      {itemGroupChips}
      {itemTypeChips}
      <Table
        columns={questionColumns}
        data={filteredQuestions}
        footer={`${filteredQuestions.length} results`}
        id='questions-table'
        loading={
          isLoadingAllQuestions || isLoadingItemGroups || isLoadingItemTypes
        }
      />
      {isQuestionsOptionsOpen && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          aria-labelledby='user-menu-button'
          id='user-menu'
          onClose={() => setAnchorEl(null)}
          open
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <div
            className={clsx(
              'min-w-32 py-2 px-4 flex flex-col items-start gap-2',
              '*:gap-2 *:w-full *:flex *:justify-between *:items-center',
            )}
          >
            <button
              className='group'
              onClick={() => setIsAddSingleModalOpen(true)}
            >
              Single question
              <CheckIcon className='h-5 opacity-0 group-hover:opacity-100 transition-opacity text-green-base' />
            </button>
            <button
              className='group'
              onClick={() => setIsAddMultipleModalOpen(true)}
            >
              Multiple questions
              <CheckIcon className='h-5 opacity-0 group-hover:opacity-100 transition-opacity text-green-base' />
            </button>
          </div>
        </Menu>
      )}
      {isAddSingleModalOpen && (
        <AddEditQuestions
          isOpen={isAddSingleModalOpen}
          setIsOpen={setIsAddSingleModalOpen}
        />
      )}
      {isAddMultipleModalOpen && (
        <AddMultipleQuestions
          onClose={() => setIsAddMultipleModalOpen(false)}
        />
      )}
      {addedQuestions.length > 0 && (
        <FormBuilderStepperDialog
          onClose={() => dispatch(questionsSlice.actions.clearAddedQuestions())}
          questions={addedQuestions}
        />
      )}
    </div>
  );
};

export default InspectionsQuestions;
