import { ItemType } from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';

import {
  activateItemType,
  addItemType,
  deactivateItemType,
  getAllItemTypes,
  updateItemType,
} from './itemTypesActions';

export type ItemTypesState = {
  isLoadingItemTypes: boolean;
  itemTypes: ItemType[];
};

const initialItemTypesState: ItemTypesState = {
  isLoadingItemTypes: false,
  itemTypes: [],
};

export const itemTypesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAllItemTypes.pending, (state) => {
      state.isLoadingItemTypes = true;
    });
    builder.addCase(getAllItemTypes.rejected, (state) => {
      state.isLoadingItemTypes = false;
    });
    builder.addCase(getAllItemTypes.fulfilled, (state, action) => {
      state.isLoadingItemTypes = false;
      state.itemTypes = action.payload;
    });
    builder.addCase(addItemType.fulfilled, (state, action) => {
      state.itemTypes.push(action.payload);
    });
    builder.addCase(updateItemType.fulfilled, (state, action) => {
      state.itemTypes = state.itemTypes.map((itemType) =>
        itemType.id === action.payload.id ? action.payload : itemType,
      );
    });
    builder.addCase(activateItemType.fulfilled, (state, action) => {
      state.itemTypes = state.itemTypes.map((itemType) =>
        itemType.id === action.payload
          ? { ...itemType, inactive: false }
          : itemType,
      );
    });
    builder.addCase(deactivateItemType.fulfilled, (state, action) => {
      state.itemTypes = state.itemTypes.map((itemType) =>
        itemType.id === action.payload
          ? { ...itemType, inactive: true }
          : itemType,
      );
    });
  },
  initialState: initialItemTypesState,
  name: 'itemTypes',
  reducers: {},
  selectors: {
    isLoadingItemTypes: (state: ItemTypesState) => state.isLoadingItemTypes,
    itemTypes: (state: ItemTypesState) => state.itemTypes,
  },
});
