import { FC } from 'react';

import { AddMultipleContextProvider } from './context';
import { AddMultipleQuestionsDialog } from './dialog';

type AddMultipleQuestionsProps = {
  onClose: () => void;
};

export const AddMultipleQuestions: FC<AddMultipleQuestionsProps> = ({
  onClose,
}) => {
  return (
    <AddMultipleContextProvider>
      <AddMultipleQuestionsDialog onClose={onClose} />
    </AddMultipleContextProvider>
  );
};
