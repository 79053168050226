import {
  ClipboardDocumentCheckIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { FC } from 'react';

import BinaryChoice from '../stepper/binaryChoice';

const NewOrTemplateChoice: FC<{
  selectExisting: () => void;
  selectNew: () => void;
}> = ({ selectExisting, selectNew }) => {
  return (
    <BinaryChoice
      left={{
        description: 'Create a template using your question library.',
        icon: <PlusIcon />,
        id: 'new-template-choice',
        onSelect: selectNew,
        title: 'Create New Template',
      }}
      right={{
        description: 'Start from an existing template from your library.',
        icon: <ClipboardDocumentCheckIcon />,
        id: 'existing-template-choice',
        onSelect: selectExisting,
        title: 'Use Existing Template',
      }}
    />
  );
};

export default NewOrTemplateChoice;
