import { compare, validate } from 'compare-versions';

const STORAGE_CACHE_KEY = 'cache-found-version' as const;
const STORAGE_TEST_KEY = 'cache-test-is-enabled' as const;

const trackerEnabled = (function () {
  if (typeof window === 'undefined' || !('sessionStorage' in window)) {
    return false;
  }

  try {
    window.sessionStorage.setItem(STORAGE_TEST_KEY, STORAGE_TEST_KEY);
    const ok =
      window.sessionStorage.getItem(STORAGE_TEST_KEY) === STORAGE_TEST_KEY;
    window.sessionStorage.removeItem(STORAGE_TEST_KEY);
    return ok;
  } catch {
    return false;
  }
})();

export function equalStrings(a: null | string, b: null | string) {
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return a.trim().toLowerCase() === b.trim().toLowerCase();
}

// before we reload the page we set a cache item matching the hardcoded value
// and the value from config if the key still exists with the same values it
// means we hit a loop. Don't try again
function isCycle(configVersion: string, currentVersion: string) {
  if (!trackerEnabled) {
    return false;
  }
  try {
    const fLocal = window.sessionStorage.getItem(STORAGE_CACHE_KEY);
    return equalStrings(fLocal, `${configVersion}|${currentVersion}`);
  } catch {
    return false;
  }
}

function setTracker(value: string) {
  if (!trackerEnabled) {
    return;
  }
  try {
    sessionStorage.setItem(STORAGE_CACHE_KEY, value);
  } catch {
    /* ok */
  }
}

function removeTracker() {
  if (!trackerEnabled) {
    return;
  }
  try {
    sessionStorage.removeItem(STORAGE_CACHE_KEY);
  } catch {
    /* ok */
  }
}

export function isNewVersion(configVersion: string, currentVersion: string) {
  configVersion = (configVersion || '').trim();
  currentVersion = (currentVersion || '').trim();

  if (!validate(configVersion) || !validate(currentVersion)) {
    return false;
  }

  return !compare(configVersion, currentVersion, '=');
}

const tracker = {
  enabled: trackerEnabled,
  isCycle: isCycle,
  remove: removeTracker,
  set: setTracker,
};
Object.freeze(tracker);

export { tracker };
