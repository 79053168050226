import { PencilIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import { Dispatch, FC, SetStateAction } from 'react';

type EditableTextButtonProps = {
  disabled?: boolean;
  errorMessage?: string;
  growVertically?: boolean;
  hasBeenSaved: boolean;
  iconClasses?: string;
  id?: string;
  initialEditableValue?: string;
  isFirstEdit: boolean;
  loading?: boolean;
  required?: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  setSavedValue: Dispatch<SetStateAction<string>>;
  setUnsavedValue: Dispatch<SetStateAction<string>>;
  showIcon?: 'end' | 'inline' | false;
  styleAfterSave?: boolean;
  text: string;
  textClasses?: string;
};

export const EditableTextButton: FC<EditableTextButtonProps> = ({
  disabled,
  errorMessage,
  growVertically,
  hasBeenSaved,
  iconClasses,
  id,
  initialEditableValue,
  isFirstEdit,
  loading,
  required,
  setIsEditing,
  setSavedValue,
  setUnsavedValue,
  showIcon,
  styleAfterSave,
  text,
  textClasses,
}) => {
  const errorClasses = errorMessage
    ? clsx(
        'border border-red-base',
        'bg-red-lightest hover:bg-red-lighter',
        'text-red-dark hover:text-red-darker',
      )
    : '';

  return (
    <div>
      <button
        className={clsx(
          'flex items-center gap-2 p-2 rounded text-left',
          showIcon === 'end' ? 'justify-between' : '',
          showIcon === 'inline' ? 'justify-start' : '',
          styleAfterSave && hasBeenSaved ? 'text-gray-700' : 'text-gray-400',
          'hover:text-gray-700 hover:bg-gray-100 w-full min-h-9',
          disabled ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer',
          textClasses,
          errorClasses,
        )}
        data-testid={`${id}-button`}
        disabled={disabled || loading}
        id={`${id}-button`}
        onClick={(e) => {
          e.stopPropagation();
          setUnsavedValue(
            isFirstEdit ? (initialEditableValue as string) : text,
          );
          setSavedValue(text);
          setIsEditing(!disabled);
        }}
      >
        {loading && (
          <div>
            <Spinner className='w-4 h-4' />
          </div>
        )}
        <div
          className={clsx(
            { 'required-field': required && isFirstEdit },
            { 'text-gray-300 animate-pulse': loading },
            required && isFirstEdit ? 'required-field' : '',
            growVertically ? 'text-pretty break-words' : 'inline truncate',
          )}
        >
          {text}
        </div>
        {!!showIcon && (
          <PencilIcon
            aria-label='Edit'
            className={clsx('flex-none', iconClasses)}
          />
        )}
      </button>
      {errorMessage && (
        <div className='relative p-1 z-20 text-xs text-red-base'>
          {errorMessage}
        </div>
      )}
    </div>
  );
};
