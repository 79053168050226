import { Summary } from '@dakota/platform-client';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Autocomplete from 'components/Autocomplete';
import { configSlice } from 'features/config/configSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { getMyAccessibleFacilities } from 'features/user/userActions';
import { userSlice } from 'features/user/userSlice';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { alphabeticalCompare } from 'utils/functional';

const FacilityChoice: FC<{
  facility: null | Summary;
  onChange: (facility: Summary) => void;
}> = ({ facility, onChange }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const facilities = useSelector(userSlice.selectors.accessibleFacilities);
  const isLoadingFacilities = useSelector(
    userSlice.selectors.isLoadingAccessibleFacilities,
  );

  // Filter out inactive facilities and sort by name to display
  const selectableFacilities = facilities
    .filter((f) => !f.inactive)
    .toSorted(alphabeticalCompare((e) => e.name));

  useEffect(() => {
    void dispatch(getMyAccessibleFacilities({ baseUrl, token }));
  }, [baseUrl, dispatch, token]);

  return (
    <div className='flex flex-col h-72 items-center justify-center gap-0.5'>
      <BuildingOfficeIcon className='w-12 h-12 text-green-base mb-4' />
      <div className='text-sm text-black'>Select a Facility</div>
      <div className='text-xs leading-5 text-gray-400'>
        Select the location where the inspection with be conducted.
      </div>
      <Autocomplete
        className='mt-4 w-96'
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.name}
        id='facility-selector'
        loading={isLoadingFacilities}
        onChange={onChange}
        options={selectableFacilities}
        value={facility}
      />
    </div>
  );
};

export default FacilityChoice;
