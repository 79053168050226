import { AnswerType, Question } from '@dakota/platform-client';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import EditableText from 'components/EditableText';
import { Spinner } from 'components/Spinner';
import Tooltip from 'components/Tooltip';
import { answersSlice } from 'features/answers/answersSlice';
import { configSlice } from 'features/config/configSlice';
import { itemTypesSlice } from 'features/items/itemTypesSlice';
import { addQuestion } from 'features/questions/questionsActions';
import {
  BuilderQuestion,
  templatesSlice,
} from 'features/templates/templatesSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { StrictModeDroppable } from 'utils/StrictModeDroppable';

import SectionQuestion from './sectionQuestion';

type Props = {
  itemTypeId: string;
  questions: BuilderQuestion[];
};

const SectionContent: FC<Props> = ({ itemTypeId, questions }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const answerTypes = useSelector(answersSlice.selectors.answerTypes);
  const loadingAnswerTypes = useSelector(
    answersSlice.selectors.isLoadingAnswerTypes,
  );

  const itemTypes = useSelector(itemTypesSlice.selectors.itemTypes);
  const itemTypeName = itemTypes.find((it) => it.id === itemTypeId)?.name;

  const [newQuestionText, setNewQuestionText] = useState('');
  const [answerType, setAnswerType] = useState<AnswerType | null>(null);
  const [saving, setSaving] = useState(false);
  const [editingKey, setEditingKey] = useState<number>(0);

  const { setInfoMessage, setSuccessMessage, setWarningMessage } = useToast();

  const createAndAddQuestion = () => {
    setSaving(true);

    dispatch(
      addQuestion({
        baseUrl,
        question: Question.fromJS({
          answerType,
          itemType: { id: itemTypeId },
          text: newQuestionText,
        }),
        token,
      }),
    )
      .unwrap()
      .then((question) => {
        setNewQuestionText('');
        setAnswerType(null);
        setEditingKey((prev) => prev + 1);
        setSuccessMessage('Question added to your library');

        dispatch(
          templatesSlice.actions.addQuestionToTemplate({
            question: {
              id: question.id,
              itemTypeId: question.itemType.id,
              itemTypeName: question.itemType.name,
              order: 0,
              text: question.text,
            },
          }),
        );
        setInfoMessage('New question added to the template');
      })
      .catch(() => {
        setWarningMessage('Failed to add question to your library');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const moveQuestion = (onEnd: DropResult) => {
    const fromIndex = onEnd.source.index;
    const toIndex = onEnd.destination?.index;
    if (toIndex === undefined) {
      return;
    }

    dispatch(
      templatesSlice.actions.moveSectionQuestion({
        fromIndex,
        sectionId: itemTypeId,
        toIndex,
      }),
    );
  };

  return (
    <div
      className={clsx(
        'flex flex-col w-full',
        'border border-gray-200 rounded-lg',
      )}
    >
      <div className='text-gray-500 bg-gray-50 rounded-t-lg text-xs uppercase p-3'>
        {questions.length === 1
          ? '1 Question'
          : `${questions.length} Questions`}
      </div>
      <DragDropContext onDragEnd={moveQuestion}>
        <StrictModeDroppable droppableId={`section-${itemTypeId}`}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              className={clsx(
                'select-none bg-white *:p-3 *:border-b',
                snapshot.isDraggingOver ? 'shadow-2xl' : '',
              )}
              ref={provided.innerRef}
            >
              {questions.map((q, idx) => (
                <SectionQuestion index={idx} key={q.id} question={q} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
      <div
        className={clsx(
          'flex justify-stretch items-center gap-2 !p-0 *:p-1',
          'rounded-b-lg bg-white text-gray-500',
        )}
      >
        <EditableText
          containerClasses='flex-1'
          disabled={saving}
          id={`new-question-${itemTypeId}`}
          initialEditableValue=''
          key={editingKey}
          onEdit={(text) => setNewQuestionText(text)}
          saveOnEnter
          showIcon={false}
          styleAfterSave
          text={newQuestionText || 'Type a new question...'}
        />
        <div className='min-w-40 max-w-64 border-l'>
          <Autocomplete
            className='w-full text-l'
            clearable
            disabled={saving}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) =>
              option.answers.map((a) => a.answer).join(', ')
            }
            id={`answer-type-${itemTypeId}`}
            loading={loadingAnswerTypes}
            noBorder
            onChange={(option) => setAnswerType(option)}
            options={answerTypes}
            placeholder='Response Type'
            value={answerType}
          />
        </div>
        <div
          className={clsx(
            'w-10 h-11 inline-flex justify-center items-center border-l',
            'bg-inherit has-[:enabled]:bg-green-lightest rounded-br-lg',
            'has-[:enabled]:hover:bg-green-lighter',
          )}
        >
          <Tooltip
            title={
              !newQuestionText || !answerType
                ? ''
                : 'This question will be added to your library under the ' +
                  `item type "${itemTypeName}", and it will be added to ` +
                  'this section as well.'
            }
          >
            <button
              aria-label='Add question'
              className='group'
              data-testid={`new-question-submit-${itemTypeId}`}
              disabled={!newQuestionText || !answerType}
              onClick={createAndAddQuestion}
            >
              {saving ? (
                <Spinner />
              ) : (
                <PlusCircleIcon
                  className={clsx(
                    'w-6 h-6 text-gray-200',
                    'group-enabled:scale-110',
                    'group-enabled:text-gray-400',
                    'group-enabled:hover:text-gray-800',
                    'group-enabled:animate-spin',
                    'group-disabled:cursor-not-allowed',
                  )}
                />
              )}
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SectionContent;
