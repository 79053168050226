import {
  FailedItemTypesReport,
  GradeReport,
  ResponsesReport,
  StatusReport,
} from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';

import {
  getFailedItemTypesReport,
  getGradeReport,
  getResponseDataReport,
  getResponsesReport,
  getStatusReport,
} from './reportsActions';

export type ReportsState = {
  failedItemTypesReport?: FailedItemTypesReport;
  gradeReport?: GradeReport;
  isLoadingFailedItemTypesReport: boolean;
  isLoadingGradeReport: boolean;
  isLoadingResponseDataReport: boolean;
  isLoadingResponsesReport: boolean;
  isLoadingStatusReport: boolean;
  responsesReport?: ResponsesReport;
  statusReport?: StatusReport;
};

export const initialState: ReportsState = {
  failedItemTypesReport: undefined,
  gradeReport: undefined,
  isLoadingFailedItemTypesReport: false,
  isLoadingGradeReport: false,
  isLoadingResponseDataReport: false,
  isLoadingResponsesReport: false,
  isLoadingStatusReport: false,
  responsesReport: undefined,
  statusReport: undefined,
};

export const reportsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getStatusReport.fulfilled, (state, action) => {
      state.isLoadingStatusReport = false;
      state.statusReport = action.payload;
    });
    builder.addCase(getStatusReport.pending, (state) => {
      state.isLoadingStatusReport = true;
    });
    builder.addCase(getStatusReport.rejected, (state) => {
      state.isLoadingStatusReport = false;
    });
    builder.addCase(getGradeReport.fulfilled, (state, action) => {
      state.isLoadingGradeReport = false;
      state.gradeReport = action.payload;
    });
    builder.addCase(getGradeReport.pending, (state) => {
      state.isLoadingGradeReport = true;
    });
    builder.addCase(getGradeReport.rejected, (state) => {
      state.isLoadingGradeReport = false;
    });
    builder.addCase(getResponsesReport.fulfilled, (state, action) => {
      state.isLoadingResponsesReport = false;
      state.responsesReport = action.payload;
    });
    builder.addCase(getResponsesReport.pending, (state) => {
      state.isLoadingResponsesReport = true;
    });
    builder.addCase(getResponsesReport.rejected, (state) => {
      state.isLoadingResponsesReport = false;
    });
    builder.addCase(getFailedItemTypesReport.fulfilled, (state, action) => {
      state.isLoadingFailedItemTypesReport = false;
      state.failedItemTypesReport = action.payload;
    });
    builder.addCase(getFailedItemTypesReport.pending, (state) => {
      state.isLoadingFailedItemTypesReport = true;
    });
    builder.addCase(getFailedItemTypesReport.rejected, (state) => {
      state.isLoadingFailedItemTypesReport = false;
    });
    builder.addCase(getResponseDataReport.pending, (state) => {
      state.isLoadingResponseDataReport = true;
    });
    builder.addCase(getResponseDataReport.fulfilled, (state) => {
      state.isLoadingResponseDataReport = false;
    });
    builder.addCase(getResponseDataReport.rejected, (state) => {
      state.isLoadingResponseDataReport = false;
    });
  },
  initialState,
  name: 'reports',
  reducers: {},
  selectors: {
    failedItemTypesReport: (state: ReportsState) => state.failedItemTypesReport,
    gradeReport: (state: ReportsState) => state.gradeReport,
    isLoadingFailedItemTypesReport: (state: ReportsState) =>
      state.isLoadingFailedItemTypesReport,
    isLoadingGradeReport: (state: ReportsState) => state.isLoadingGradeReport,
    isLoadingResponseDataReport: (state: ReportsState) =>
      state.isLoadingResponseDataReport,
    isLoadingResponsesReport: (state: ReportsState) =>
      state.isLoadingResponsesReport,
    isLoadingStatusReport: (state: ReportsState) => state.isLoadingStatusReport,
    responsesReport: (state: ReportsState) => state.responsesReport,
    statusReport: (state: ReportsState) => state.statusReport,
  },
});
