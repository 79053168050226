/* eslint-disable perfectionist/sort-objects */
import { LocalDate } from '@js-joda/core';

const today = LocalDate.now();

export const shortcutsInThePast = {
  today: {
    period: {
      end: today.toString(),
      start: today.toString(),
    },
    text: 'Today',
  },
  last7Days: {
    period: {
      end: today.toString(),
      start: today.minusDays(7).toString(),
    },
    text: 'Last 7 days',
  },
  last30Days: {
    period: {
      end: today.toString(),
      start: today.minusDays(30).toString(),
    },
    text: 'Last 30 days',
  },
  last60Days: {
    period: {
      end: today.toString(),
      start: today.minusDays(60).toString(),
    },
    text: 'Last 60 days',
  },
  last365Days: {
    period: {
      end: today.toString(),
      start: today.minusDays(365).toString(),
    },
    text: 'Last 365 days',
  },
};

export const shortcutsInTheFuture = {
  today: {
    period: {
      end: today.toString(),
      start: today.toString(),
    },
    text: 'Today',
  },
  next7Days: {
    period: {
      end: today.plusDays(7).toString(),
      start: today.toString(),
    },
    text: 'Next 7 days',
  },
  next30Days: {
    period: {
      end: today.plusDays(30).toString(),
      start: today.toString(),
    },
    text: 'Next 30 days',
  },
  next60Days: {
    period: {
      end: today.plusDays(60).toString(),
      start: today.toString(),
    },
    text: 'Next 60 days',
  },
  next365Days: {
    period: {
      end: today.plusDays(365).toString(),
      start: today.toString(),
    },
    text: 'Next 365 days',
  },
};
