import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    await loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
  };

  return (
    <Button
      onClick={(event) => {
        const returnedLogin = handleLogin;
        void returnedLogin(event);
      }}
    >
      Log In
    </Button>
  );
};

export default LoginButton;
