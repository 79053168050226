import { IPromptStats } from '@dakota/platform-client';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { FC } from 'react';

type ResultsBarProps = {
  prompts: IPromptStats;
};

export const CompletedResultsBar: FC<ResultsBarProps> = ({ prompts }) => {
  const total = prompts.total;

  // Calculate percentages and format them to 1 decimal place
  const passedPercentage = ((prompts.passed / total) * 100).toFixed(1);
  const warningPercentage = ((prompts.warning / total) * 100).toFixed(1);
  const failedPercentage = ((prompts.failed / total) * 100).toFixed(1);
  const skippedPercentage = ((prompts.skipped / total) * 100).toFixed(1);

  const tooltipTitle = (
    <div className='grid grid-flow-row grid-cols-2 grid-rows-5 even:*:text-right'>
      <div className='font-medium'>Total Questions</div>
      <div className='font-medium'>{total}</div>
      <div>Passed</div>
      <div>
        {prompts.passed} ({passedPercentage}%)
      </div>
      <div>Failed</div>
      <div>
        {prompts.failed} ({failedPercentage}%)
      </div>
      <div>Warning</div>
      <div>
        {prompts.warning} ({warningPercentage}%)
      </div>
      <div>Skipped</div>
      <div>
        {prompts.skipped} ({skippedPercentage}%)
      </div>
    </div>
  );
  return (
    // Each child div represents a category of results (passed, warning,
    // failed, skipped) The flexGrow style is dynamically set based on the
    // prompts stats to visually represent the results
    <Tooltip placement='top' title={tooltipTitle}>
      <div
        className='flex w-full h-4 rounded-lg overflow-hidden border border-solid border-gray-300'
        data-testid='completed-results-bar'
      >
        <div
          className='bg-green-base'
          data-testid='completed-results-bar-passed'
          style={{ flexGrow: prompts.passed }}
        ></div>
        <div
          className='bg-yellow-base'
          data-testid='completed-results-bar-warning'
          style={{ flexGrow: prompts.warning }}
        ></div>
        <div
          className='bg-red-base'
          data-testid='completed-results-bar-failed'
          style={{ flexGrow: prompts.failed }}
        ></div>
        <div
          className='bg-gray-400'
          data-testid='completed-results-bar-skipped'
          style={{ flexGrow: prompts.skipped }}
        ></div>
      </div>
    </Tooltip>
  );
};

export const InProgressResultsBar: FC<ResultsBarProps> = ({ prompts }) => {
  const total = prompts.total;
  const responded = prompts.answered + prompts.skipped;
  const pending = prompts.pending;

  // Calculate percentages and format them to 1 decimal place
  const respondedPercentage = ((responded / total) * 100).toFixed(1);
  const pendingPercentage = ((pending / total) * 100).toFixed(1);

  const tooltipTitle = (
    <div className='grid grid-flow-row grid-cols-2 grid-rows-4 even:*:text-right'>
      <div className='font-medium'>Total Questions</div>
      <div className='font-medium'>{total}</div>
      <div>Responded</div>
      <div>
        {responded} ({respondedPercentage}%)
      </div>
      <div>Not Responded</div>
      <div>
        {pending} ({pendingPercentage}%)
      </div>
      <div className='col-span-2 italic'>* Final results are pending</div>
    </div>
  );

  return (
    <Tooltip placement='top' title={tooltipTitle}>
      <div
        className='flex w-full h-4 rounded-lg overflow-hidden border border-solid border-gray-300'
        data-testid='in-progress-results-bar'
      >
        <div
          className='bg-gray-400'
          data-testid='in-progress-results-bar-responded'
          style={{ flexGrow: responded }}
        ></div>
        <div
          className='bg-white'
          data-testid='in-progress-results-bar-pending'
          style={{ flexGrow: prompts.pending }}
        ></div>
      </div>
    </Tooltip>
  );
};

const emptyBar = (borderColor: string, testId: string, tooltip: string) => (
  <Tooltip arrow placement='top' title={tooltip}>
    <div
      className={clsx('rounded-lg h-4 border', borderColor)}
      data-testid={testId}
    />
  </Tooltip>
);

export const PendingResultsBar = () =>
  emptyBar(
    'border-gray-300',
    'pending-results-bar',
    'This inspection has not yet been started.',
  );

export const OverdueResultsBar = () =>
  emptyBar(
    'border-red-base',
    'overdue-results-bar',
    'This inspection is overdue.',
  );
