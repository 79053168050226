import { DistributedTracingModes } from '@microsoft/applicationinsights-common';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  IConfig,
  IConfiguration,
} from '@microsoft/applicationinsights-web';
import axios from 'axios';
import { AppInfo, TelemetryConfig } from 'features/config/types';

import {
  addUserTelemetry,
  createAddAppTelemetry,
  createdropRequestTelemetry,
} from './inititalizers';
import { UserTracker } from './userTracker';
import { Identity } from './util';

function cleanName(n: string) {
  // Parameters must not contain spaces or ,;=|
  // https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
  if (!n) {
    return '';
  }
  return n.replace(/[,;=|]|\s/g, '');
}

function getStorageNameModifier(...values: string[]) {
  // finds the first value in the input that is not empty/null after trimming
  // leading/trailing whitespace and underscores a special-case value of 'none'
  // is recognized as a short-circuit since blank/null would move on to the next
  // item, we can specify options: { storageNameModifier:
  // 'none' } and force no-prefix (instead of falling back to env name)
  for (const val of values) {
    if (!val) {
      continue;
    }
    const temp = val.replace(/(^[_\s]{1,3})|([_\s]{1,3}$)/g, '').toLowerCase();
    if (temp) {
      return temp === 'none' ? undefined : temp;
    }
  }
  return undefined;
}

function ensureProtocol(url?: string) {
  if (!url) {
    return undefined;
  }

  if (url.match(/^https?:\/\//i)) {
    return url;
  }

  return `https://${url}`;
}

function tryGetHost(url?: string) {
  url = ensureProtocol(url);

  try {
    return url ? new URL(url).host : null;
  } catch {
    return null;
  }
}

function getSet(...values: string[]) {
  return new Set(values.filter((c) => !!c).map((c) => c.toLowerCase()));
}

const reactPlugin = new ReactPlugin();

// for explaination of all configuration settings, see:
// https://github.com/microsoft/ApplicationInsights-JS/blob/master/README.md
const baseOptions: IConfiguration & IConfig = {
  autoTrackPageVisitTime: true,
  diagnosticLogInterval: 60000,

  disableAjaxTracking: false,

  disableCorrelationHeaders: false,

  disableFetchTracking: false,

  disableTelemetry: true,

  disableXhr: false,

  distributedTracingMode: DistributedTracingModes.AI_AND_W3C,

  enableAjaxErrorStatusText: true,

  enableAjaxPerfTracking: true,

  enableAutoRouteTracking: false, // we will track route changes ourselves

  enableCorsCorrelation: true,

  enableRequestHeaderTracking: true,

  enableResponseHeaderTracking: true,

  excludeRequestFromAutoTrackingPatterns: [/config\.json$/i],
  // disable until load
  extensions: [reactPlugin],
  instrumentationKey: '',
  // internal telemetry
  loggingLevelConsole: 0,

  loggingLevelTelemetry: 1,

  maxBatchInterval: 15000,
  samplingPercentage: 100, // internal telemetry
};

const appInsights = new ApplicationInsights({
  config: baseOptions,
});

export function loadAppInsights(config: TelemetryConfig, appInfo: AppInfo) {
  if (appInsights.core.isInitialized?.() ?? true) {
    return;
  }

  const instrumentationKey = config.instrumentationKey || '';
  const isEnabled: boolean = !!config.enabled && !!instrumentationKey;
  const nameAdjust: string | undefined = getStorageNameModifier(
    '',
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    appInfo?.environment || '',
  );
  const cookieSuffix: string | undefined = nameAdjust
    ? `_${nameAdjust}`
    : undefined;
  const corsExcludes: Set<string> = getSet(
    '*.auth0.com',
    ...(config.correlationHeaderExcludes || []),
  );
  const corsDomains: Set<string> = getSet(
    ...(config.correlationHeaderDomains || []),
  );
  const disableCookies = !!config.disableCookies;

  const endpointHost = tryGetHost(config.endpointUrl);
  const backendHost = tryGetHost(axios.defaults.baseURL);

  endpointHost && corsDomains.add(endpointHost);
  backendHost && corsDomains.add(backendHost);

  const aiConfig = appInsights.config;

  aiConfig.disableTelemetry = !isEnabled;
  // cors
  aiConfig.correlationHeaderDomains = corsDomains.size
    ? [...corsDomains]
    : undefined;
  aiConfig.correlationHeaderExcludedDomains = corsExcludes.size
    ? [...corsExcludes]
    : undefined;
  // cookies/session
  aiConfig.namePrefix = nameAdjust;
  aiConfig.disableCookiesUsage = disableCookies;
  aiConfig.cookieDomain = config.cookieDomain;
  aiConfig.userCookiePostfix = cookieSuffix;
  aiConfig.sessionCookiePostfix = cookieSuffix;
  // connection
  aiConfig.endpointUrl = ensureProtocol(config.endpointUrl);
  aiConfig.instrumentationKey = instrumentationKey;
  aiConfig.appId = config.appId;

  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer(createAddAppTelemetry(appInfo));
  appInsights.addTelemetryInitializer(addUserTelemetry);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const configUrl = (window?.location?.origin || '') + '/config.json';

  appInsights.addDependencyInitializer(createdropRequestTelemetry(configUrl));

  if (appInfo.version) {
    appInsights.context.application.ver = appInfo.version;
  }

  appInsights.getCookieMgr().setEnabled(!disableCookies);

  UserTracker.registerEvents({
    onClear: () => appInsights.clearAuthenticatedUserContext(),
    onSet: (id: Identity) =>
      appInsights.setAuthenticatedUserContext(
        cleanName(id.userName),
        cleanName(id.orgId),
        false /* store in cookie */,
      ),
  });
}

function isTelemetryEnabled() {
  return (
    !!appInsights.core.isInitialized?.() && !appInsights.config.disableTelemetry
  );
}

export { isTelemetryEnabled, reactPlugin as appInsights };
