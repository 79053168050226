import {
  ListTasksParams,
  TaskInstanceDetails,
  TasksClient,
} from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';
import { mockTasks } from 'Pages/Tasks/mockTasks';

export const listTasks = createAsyncThunk(
  'tasks/listTasks',
  async (params: ClientData & ListTasksParams) => {
    // TODO return the backend response when implementation is ready
    /*const client =*/ getClient(TasksClient, params);
    // const response = await client.listTasks(params);
    // return response.result;
    return Promise.resolve(mockTasks);
  },
);

export const getTaskDetails = createAsyncThunk(
  'tasks/getTaskDetails',
  async (params: { date: string; seriesId: string } & ClientData) => {
    // TODO return the backend response when implementation is ready
    /*const client =*/ getClient(TasksClient, params);
    // const response = await client.getTaskInstance(
    //   params.seriesId,
    //   params.date,
    // );
    // return response.result;
    return Promise.resolve(TaskInstanceDetails.fromJS(mockTasks[0]));
  },
);
