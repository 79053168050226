import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const { theme: dakotaTheme } = resolveConfig(tailwindConfig);

const borderColor = dakotaTheme.colors.gray[300];
const textColor = dakotaTheme.colors.gray[700];

export const autocompleteStyles = {
  '& .Mui-disabled': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor,
    },
    backgroundColor: dakotaTheme.colors.gray[200],
    cursor: 'not-allowed',
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: dakotaTheme.colors.blue.base,
    },
    '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: `${dakotaTheme.colors.blue.base} !important`,
      },
  },
  '& .Mui-required': {
    position: 'absolute',
  },
  '& .MuiAutocomplete-input': {
    '&::placeholder': {
      color: textColor,
    },
    color: textColor,
  },
  '& .MuiAutocomplete-inputFocused': {
    boxShadow: 'none',
    paddingLeft: '4px !important',
  },
  '& .MuiAutocomplete-inputRoot': {
    flexWrap: 'nowrap',
    fontFamily: dakotaTheme.fontFamily.sans.join(','),
    fontSize: '14px',
    lineHeight: '20px',
  },
  '& .MuiAutocomplete-listbox': {
    color: textColor,
  },
  '& .MuiFormLabel-root': {
    '&.Mui-focused': {
      color: dakotaTheme.colors.green.base,
    },
    color: dakotaTheme.colors.gray[400],
    fontFamily: dakotaTheme.fontFamily.sans.join(','),
    fontSize: '14px',
    fontWeight: dakotaTheme.fontWeight.light,
    lineHeight: '20px',
  },
  '& .MuiInputBase-root': {
    borderRadius: dakotaTheme.borderRadius.md,
  },
  '& .MuiInputLabel-asterisk': {
    color: dakotaTheme.colors.red.base,
  },
  '& .MuiInputLabel-shrink': {
    backgroundColor: dakotaTheme.colors.white,
    color: dakotaTheme.colors.gray[400],
    fontFamily: dakotaTheme.fontFamily.sans.join(','),
    fontSize: '16px',
    fontWeight: dakotaTheme.fontWeight.normal,
    lineHeight: '16px',
    paddingRight: '4px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: dakotaTheme.borderRadius.md,
    fontWeight: dakotaTheme.fontWeight.normal,
  },
  '& + .MuiAutocomplete-popper': {
    '.MuiAutocomplete-option': {
      backgroundColor: dakotaTheme.colors.white,
    },
    '.MuiAutocomplete-option:hover': {
      backgroundColor: dakotaTheme.colors.green.base,
    },
    backgroundColor: dakotaTheme.colors.white,
    borderColor,
    borderRadius: dakotaTheme.borderRadius.md,
    borderWidth: '1px',
    boxShadow: dakotaTheme.boxShadow.md,
    marginY: '4px !important',
  },
  '& + .MuiAutocomplete-popper .MuiAutocomplete-paper': {
    borderRadius: dakotaTheme.borderRadius.md,
    boxShadow: 'none',
  },
  '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor,
  },
};
