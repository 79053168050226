import { InspectionDetails } from '@dakota/platform-client';
import {
  ArrowDownOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Dialog } from '@mui/material';
import { useCheckPermission } from 'auth/AuthHelper';
import { clsx } from 'clsx';
import Confirmation from 'components/SimpleConfirmation';
import { configSlice } from 'features/config/configSlice';
import { deleteAttachment } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import useToast from 'hooks/useToast';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { useEventListener } from 'usehooks-ts';
import { saveBlobToDisk } from 'utils/file';
import { Permission } from 'utils/permissions';

import { AttachmentContent } from './AttachmentContent';
import { CarouselButton } from './CarouselButton';
import { CarouselData } from './CarouselData';

export const Carousel: FC<CarouselData> = ({
  attachment,
  attachmentIndex,
  promptIndex,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { setErrorMessage } = useToast();

  const inspection = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  ) as InspectionDetails;

  const isAdmin = useCheckPermission(Permission.Admin);
  const canEdit = isAdmin || attachment.userId === currentUser.id;

  const getFileByAttachmentId = useSelector(
    inspectionSlice.selectors.getFileByAttachmentId,
  );
  const file = getFileByAttachmentId(attachment.id);

  const closeCarousel = () => {
    dispatch(inspectionSlice.actions.closeCarousel());
  };

  const doDeleteAttachment = () => {
    setIsDeleting(true);

    dispatch(
      deleteAttachment({
        attachmentId: attachment.id,
        attachmentIndex,
        baseUrl,
        inspectionId: inspection.id,
        promptId: attachment.promptId,
        promptIndex,
        sectionIndex,
        token,
      }),
    )
      .unwrap()
      .then(() => setConfirmDelete(false))
      .catch(() => setErrorMessage('Failed to delete media'))
      .finally(() => setIsDeleting(false));
  };

  const downloadAttachment = () => {
    if (file) {
      fetch(file.fileUrl)
        .then((response) => {
          if (response.status >= 400) {
            throw new Error('Error downloading file');
          }
          return response.blob();
        })
        .then((blob) => saveBlobToDisk(blob, attachment.originalName))
        .catch(() => setErrorMessage('Failed to download media'));
    }
  };

  const goToNext = useCallback(() => {
    dispatch(inspectionSlice.actions.goToNextAttachment());
  }, [dispatch]);

  const goToPrev = useCallback(() => {
    dispatch(inspectionSlice.actions.goToPreviousAttachment());
  }, [dispatch]);

  useEventListener('keydown', (event) => {
    if (event.key === 'ArrowRight') {
      goToNext();
    } else if (event.key === 'ArrowLeft') {
      goToPrev();
    }
  });

  return (
    <Dialog fullScreen onClose={closeCarousel} open>
      <div
        className={clsx(
          'w-full h-full bg-black text-white',
          'flex flex-col justify-between border border-white',
        )}
      >
        <div className='flex-none h-16 flex justify-between items-center p-4'>
          <div className='flex-1 flex flex-col'>
            <div aria-label='File name'>{attachment.originalName}</div>
            {attachment.description && <div>{attachment.description}</div>}
          </div>
          <div className='flex-none flex'>
            <CarouselButton
              aria-label='Download attachment'
              disabled={
                attachment.securityRisk || !attachment.filePath || !file
              }
              onClick={downloadAttachment}
            >
              <ArrowDownOnSquareIcon className='w-6 text-white' />
            </CarouselButton>
            <CarouselButton
              aria-label='Delete attachment'
              disabled={!canEdit}
              onClick={() => setConfirmDelete(true)}
            >
              <TrashIcon className='w-6 text-red-base' />
            </CarouselButton>
            <CarouselButton aria-label='Close carousel' onClick={closeCarousel}>
              <XMarkIcon className='w-6 text-white' />
            </CarouselButton>
          </div>
        </div>
        <div className='flex-1 flex justify-between items-center *:p-4'>
          <div className='flex-none'>
            <CarouselButton
              aria-label='Go to previous attachment'
              onClick={goToPrev}
            >
              <ChevronLeftIcon className='w-12 text-white' />
            </CarouselButton>
          </div>
          <div className='flex-1 h-full place-content-center'>
            <AttachmentContent
              attachment={attachment}
              downloadAttachment={downloadAttachment}
            />
          </div>
          <div className='flex-none'>
            <CarouselButton
              aria-label='Go to next attachment'
              onClick={goToNext}
            >
              <ChevronRightIcon className='w-12 text-white' />
            </CarouselButton>
          </div>
        </div>
      </div>
      {confirmDelete && (
        <Confirmation
          cancelText='Cancel'
          confirmLabel='Confirm delete attachment'
          confirmText='Delete Media'
          loading={isDeleting}
          onCancel={() => setConfirmDelete(false)}
          onConfirm={doDeleteAttachment}
        >
          Are you sure you want to delete this media?
          <br />
          This action cannot be reverted.
        </Confirmation>
      )}
    </Dialog>
  );
};
