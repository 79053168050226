import { Role, RoleType, Summary } from '@dakota/platform-client';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getAllRoles } from './rolesActions';

export type RolesState = {
  isLoadingRoles: boolean;

  // Full list of roles for the tenant.
  roles: Role[];
};

export const initialState: RolesState = {
  isLoadingRoles: false,
  roles: [],
};

export const rolesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAllRoles.pending, (state) => {
      state.isLoadingRoles = true;
    });
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.isLoadingRoles = false;
      state.roles = action.payload;
    });
    builder.addCase(getAllRoles.rejected, (state) => {
      state.isLoadingRoles = false;
    });
  },
  initialState,
  name: 'roles',
  reducers: {},
  selectors: {
    // This assumes there's exactly one role with type `RoleType.Admin`.
    adminRoleId: (state: RolesState) =>
      state.roles.find((role) => role.type === RoleType.Admin)?.id || '',
    allRoles: (state: RolesState) => state.roles,
    allRolesSummaries: createSelector(
      (state: RolesState) => state.roles,
      (roles) =>
        roles.map(
          ({ description, id, name }) =>
            ({ description, id, name }) as Summary & { description: string },
        ),
    ),
    isLoadingRoles: (state: RolesState) => state.isLoadingRoles,
  },
});
