import PermissionGuard from 'auth/PermissionGuard';
import TabNavigation from 'components/TabNavigation';
import { NavLink, Outlet } from 'react-router-dom';
import { Permission } from 'utils/permissions';

const Manage = () => {
  return (
    <div className={'h-full'}>
      <TabNavigation>
        <PermissionGuard permissions={Permission.CreateItemMetadata}>
          <NavLink to='item-groups'>Item Groups</NavLink>
          <NavLink to='item-types'>Item Types</NavLink>
        </PermissionGuard>
        <PermissionGuard permissions={Permission.CreateQuestion}>
          <NavLink to='inspection-questions'>Inspection Questions</NavLink>
        </PermissionGuard>
        <PermissionGuard
          permissions={[
            Permission.CreateInspection,
            Permission.CreateGlobalForm,
          ]}
        >
          <NavLink to='inspection-templates'>Inspection Templates</NavLink>
        </PermissionGuard>
      </TabNavigation>
      <Outlet />
    </div>
  );
};

export default Manage;
