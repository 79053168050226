import { Summary } from '@dakota/platform-client';
import {
  BuildingOfficeIcon,
  GlobeAmericasIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC, useLayoutEffect, useMemo, useRef } from 'react';

type Props = {
  facility?: Summary;
  onClose: () => void;
  title: string;
};

const Header: FC<Props> = ({ facility, onClose, title }) => {
  const titleContainerRef = useRef<HTMLDivElement>(null);
  const closeContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (titleContainerRef.current && closeContainerRef.current) {
      closeContainerRef.current.style.width =
        titleContainerRef.current.getBoundingClientRect().width + 'px';
    }
  }, []);

  const centerInfo = useMemo(() => {
    const iconProps = { className: 'w-[24px] h-[24px] text-gray-400' };
    const icon = facility ? (
      <BuildingOfficeIcon {...iconProps} />
    ) : (
      <GlobeAmericasIcon {...iconProps} />
    );
    const text = facility?.name ?? 'Global';

    return (
      <div
        className={clsx(
          'flex justify-center items-center gap-2',
          'bg-white rounded py-2 pl-3 pr-4 text-sm',
        )}
        id='template-scope'
      >
        {icon} <div className='truncate text-gray-800 font-medium'>{text}</div>
      </div>
    );
  }, [facility]);

  return (
    <div
      className={clsx(
        'flex-none flex justify-stretch items-center',
        'h-16 bg-green-base text-white',
      )}
    >
      <div
        className='pl-4 text-xl font-semibold truncate'
        id='form-builder-title'
        ref={titleContainerRef}
      >
        {title}
      </div>
      <div className='flex-1 flex justify-center'>
        <div className='w-max max-w-72 sm:max-w-full'>{centerInfo}</div>
      </div>
      <div
        className='flex justify-end items-center text-right pr-4'
        ref={closeContainerRef}
      >
        <button id='close-form-builder' onClick={onClose}>
          <XMarkIcon className='h-6 w-6' />
        </button>
      </div>
    </div>
  );
};

export default Header;
