import { Question } from '@dakota/platform-client';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import DakotaLibraryLogo from 'components/DakotaLibraryLogo';
import { FC } from 'react';

type Props = {
  highlight: FC<string | undefined>;
  onSelect: () => void;
  question: Question;
};

const LibraryQuestion: FC<Props> = ({ highlight, onSelect, question }) => {
  return (
    <button
      className={clsx(
        'flex w-full items-center border rounded-lg p-3 mb-4 group',
        'hover:bg-green-lightest hover:cursor-pointer',
        'active:bg-green-lighter',
      )}
      data-testid='library-question'
      key={question.id}
      onClick={onSelect}
    >
      <div className='pr-3'>
        <PlusCircleIcon
          className={clsx(
            'w-6 h-6 text-gray-300 transition-all group-hover:opacity-80',
            'group-hover:text-green-base group-hover:scale-125',
          )}
        />
      </div>
      <div className='w-full text-left'>
        {highlight(question.text)}
        {question.isPlatformDefined && <DakotaLibraryLogo />}
        <div className='flex justify-between items-center gap-2 mt-3 text-xs'>
          <div className='text-green-base'>{question.itemType.group}</div>
          <div className='text-gray-400 text-right'>
            {question.itemType.name}
          </div>
        </div>
      </div>
    </button>
  );
};

export default LibraryQuestion;
