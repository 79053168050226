import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/20/solid';
import Button from 'components/Button';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const ExpandCollapse: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className='flex gap-3'>
      <Button
        className='space-x-1'
        disabled={useSelector(inspectionSlice.selectors.areAllSectionsExpanded)}
        onClick={() => dispatch(inspectionSlice.actions.expandAll())}
        secondary
      >
        <ArrowsPointingOutIcon className='h-4' /> <span>Expand all</span>
      </Button>
      <Button
        className='space-x-1'
        disabled={useSelector(
          inspectionSlice.selectors.areAllSectionsCollapsed,
        )}
        onClick={() => dispatch(inspectionSlice.actions.collapseAll())}
        secondary
      >
        <ArrowsPointingInIcon className='h-4' /> <span>Collapse all</span>
      </Button>
    </div>
  );
};
