import { UserProfile } from '@dakota/platform-client';
import { Avatar } from '@mui/material';
import { FC } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

type Props = {
  user: UserProfile;
};

export const UserAvatar: FC<Props> = ({ user }: Props) => {
  const first = user.firstName;
  const last = user.lastName;
  const avatarInitials =
    first && last ? `${first.charAt(0)}${last.charAt(0)}` : '';

  return (
    <Avatar data-testid='user-avatar' sx={{ bgcolor: theme.colors.green.base }}>
      {avatarInitials}
    </Avatar>
  );
};
