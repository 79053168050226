import { Group, ItemType } from '@dakota/platform-client';
import { SetStateAction } from 'react';
import { alphabeticalCompare } from 'utils/functional';

export const filterItemTypes = (
  selectedGroups: Group[],
  setSelectableItemTypes: (value: SetStateAction<ItemType[]>) => void,
  allItemTypes: ItemType[],
) => {
  if (selectedGroups.length > 0) {
    const selectableItemTypesIds = selectedGroups
      .flatMap((group) => group.itemTypes)
      .map((itemType) => itemType.id);
    setSelectableItemTypes(
      allItemTypes
        .filter((itemType) => !itemType.inactive)
        .filter((itemType) => selectableItemTypesIds.includes(itemType.id)),
    );
  } else {
    setSelectableItemTypes(
      allItemTypes.toSorted(alphabeticalCompare((e) => e.name)),
    );
  }
};
