import { useAuth0 } from '@auth0/auth0-react';
import dakotaLogo from 'assets/DakotaLogo_wht.png';
import { clsx } from 'clsx';
import AuthButton from 'components/AuthButton';
import Button from 'components/Button';
import { WarningMessage } from 'components/WarningMessage';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Errors } from 'types';

const Home = () => {
  const location = useLocation();
  const { logout } = useAuth0();
  const [errorMessage, setErrorMessage] = useState('');
  const [isWarningAcknowledged, setIsWarningAcknowledged] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const error = queryParams.get('error');
    const decodedErrorDescription = decodeURIComponent(
      queryParams.get('error_description') ?? '',
    );

    const isUnauthorized = error === 'unauthorized';
    const isAccessDenied = error === 'access_denied';
    const isUserBlocked = decodedErrorDescription === 'user is blocked';

    if (
      error &&
      decodedErrorDescription &&
      ((isUnauthorized && !isUserBlocked) || !isAccessDenied)
    ) {
      setErrorMessage(Errors.LOGIN_FAILED);
    }

    if (isAccessDenied && decodedErrorDescription) {
      setErrorMessage(decodedErrorDescription);
    }

    if (isUnauthorized && isUserBlocked) {
      setErrorMessage(Errors.BLOCKED_USER);
    }

    // Clear the URL, we no longer need this information
    window.history.replaceState(null, '', '/');

    // Delete the Auth0 cookie, allowing the user to navigate back to <Login />
    void logout({ openUrl: false });
  }, [location, logout]);

  return (
    <div className='flex flex-col h-screen' data-testid='home'>
      <div className='w-full p-2 bg-green-base'>
        <img
          alt='white dakota logo'
          className='object-contain h-6 md:h-10'
          src={dakotaLogo}
        />
      </div>
      <div
        className={clsx(
          'flex flex-col items-center justify-center flex-grow',
          ' gap-4 text-center bg-center bg-no-repeat bg-cover bg-homeBackdrop',
        )}
      >
        <h1 className='text-4xl font-bold text-green-base md:text-7xl'>
          Dakota Software
        </h1>
        <AuthButton />
        {errorMessage && !isWarningAcknowledged && (
          <WarningMessage renderIcon={false}>
            <div>
              <div>{errorMessage}</div>
              <div className='text-center'>
                <Button
                  alert
                  className='w-12 mt-2'
                  onClick={() => setIsWarningAcknowledged(true)}
                >
                  Ok
                </Button>
              </div>
            </div>
          </WarningMessage>
        )}
      </div>
    </div>
  );
};
export default Home;
