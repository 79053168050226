import { CheckIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC, useMemo } from 'react';

type StepProps = {
  /**
   * The number of the current active step.
   */
  current: number;
  /**
   * The number of this step.
   */
  number: number;
  /**
   * The text of this step.
   */
  text: string;
};

export const Step: FC<StepProps> = ({ current, number, text }) => {
  const textColor = useMemo(() => {
    if (current >= number) {
      return 'text-green-base';
    } else {
      return 'text-gray-300';
    }
  }, [current, number]);

  const borderColor = useMemo(() => {
    if (current === number) {
      return 'border-green-light';
    } else if (current > number) {
      return 'border-green-base';
    } else {
      return 'border-gray-200';
    }
  }, [current, number]);

  return (
    <div className={clsx('inline-flex items-center gap-2', textColor)}>
      <div
        className={clsx(
          'w-10 h-10 inline-flex justify-center items-center',
          borderColor,
          { 'bg-green-base': current > number },
          'border-2 rounded-full font-bold',
        )}
      >
        {current > number ? (
          <CheckIcon className='w-5 h-5 text-white' />
        ) : (
          <span className={textColor}>{number}</span>
        )}
      </div>
      {text}
    </div>
  );
};
