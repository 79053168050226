import { Modal } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { ReactNode, useEffect } from 'react';
import { noOp } from 'utils/functional';

type AcknowledgmentDialogProps = Readonly<{
  /**
   * Disable the acknowledgment button *and* the cancel button when `true`.
   * @default false
   */
  acknowledgmentDisabled?: boolean;
  /**
   * The main text to display in the dialog. HTML tags are allowed,
   * so things like bold and emphasis can be used.
   */
  acknowledgmentText: string;
  /**
   * Optional properties for an additional button.
   * This can be used to add an extra button to the dialog.
   */
  additionalButton?: {
    className?: string;
    onClick: () => void;
    render?: () => ReactNode;
    text: string;
  };
  /**
   * The text to display on the acknowledgment button.
   * @default 'OK'
   */
  buttonText?: string;
  /**
   * Optional text to display on the cancel button. If provided,
   * a cancel button will be rendered which closes the dialog.
   */
  cancelButtonText?: string;

  /**
   * Whether the dialog is open.
   */
  isOpen: boolean;
  /**
   * Callback to run when the user clicks the acknowledgment button.
   * The dialog will be closed first, and then the callback will be run.
   * @default no-op, the dialog is closed with no further action.
   */
  onAcknowledgment?: () => void;
  /**
   * Callback to run when the dialog is requested to be closed, either by
   * clicking the cancel button or by other means such as clicking outside the dialog.
   * @default no-op, the dialog is closed with no further action.
   */
  onCloseDialog?: () => void;
  /**
   * stack buttons in a column on small screens
   */
  stackButtons?: boolean;

  /**
   * Short title for the dialog.
   */
  title: string;
}>;

/**
 * Dialog with a title, a line of text, an acknowledgment button with an action,
 * and optional cancel and additional buttons. The cancel button, if provided,
 * simply closes the dialog using the onCloseDialog callback.
 */
export default function AcknowledgmentDialog({
  acknowledgmentDisabled = false,
  acknowledgmentText,
  additionalButton,
  buttonText = 'OK',
  cancelButtonText,
  isOpen,
  onAcknowledgment = noOp,
  onCloseDialog = noOp,
  stackButtons = false,
  title,
}: AcknowledgmentDialogProps) {
  useEffect(() => {
    if (!isOpen) {
      onCloseDialog();
    }
  }, [isOpen, onCloseDialog]);

  return (
    <Modal
      data-testid='acknowledgment-dialog'
      onClose={onCloseDialog}
      open={isOpen}
    >
      <div
        className={clsx(
          'flex flex-col gap-3 p-6 bg-white top-1/2 left-1/2',
          'transform -translate-x-1/2 -translate-y-1/2 rounded-md',
          'absolute max-sm:text-center max-sm:items-center w-4/5 sm:w-1/2',
        )}
      >
        <div className='text-lg font-semibold'>{title}</div>
        <p
          className={`text-sm text-gray-500 ${
            acknowledgmentDisabled ? 'opacity-50' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: acknowledgmentText }}
          data-testid='acknowledgment-text'
        />
        <div
          className={clsx('flex sm:justify-end gap-2', {
            'flex-col mt-2 sm:flex-row': stackButtons,
          })}
        >
          <Button
            data-testid='acknowledge-button'
            disabled={acknowledgmentDisabled}
            onClick={onAcknowledgment}
          >
            {buttonText}
          </Button>
          {additionalButton &&
            (additionalButton.render ? (
              additionalButton.render()
            ) : (
              <Button
                className={additionalButton.className ?? ''}
                data-testid='additional-button'
                onClick={additionalButton.onClick}
                secondary
              >
                {additionalButton.text}
              </Button>
            ))}
          {cancelButtonText && (
            <Button
              data-testid='cancel-button'
              disabled={acknowledgmentDisabled}
              onClick={onCloseDialog}
              secondary
            >
              {cancelButtonText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
