/**
 *
 * @param blob data to save to disk, in the form of a Blob
 * @param fileName name of the file to save
 */
export function saveBlobToDisk(blob: Blob, fileName: string = 'download') {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener';
  link.setAttribute('download', fileName);

  document.body.appendChild(link);

  setTimeout(() => window.URL.revokeObjectURL(link.href), 1000);
  setTimeout(() => {
    link.click();
    link.remove();
  }, 0);
}
