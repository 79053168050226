import { TaskState } from '@dakota/platform-client';

export const getStatusLabel = (status: TaskState) => {
  switch (status) {
    case TaskState.Completed:
      return 'Completed';
    case TaskState.InProgress:
      return 'In Progress';
    case TaskState.Open:
      return 'Open';
    case TaskState.Canceled:
      return 'Canceled';
  }
};
