import PermissionGuard from 'auth/PermissionGuard';
import TabNavigation from 'components/TabNavigation';
import { NavLink, Outlet } from 'react-router-dom';
import { Permission } from 'utils/permissions';

const Tasks = () => {
  return (
    <div className='h-full bg-white' data-testid='tasks'>
      <TabNavigation>
        <PermissionGuard permissions={Permission.ReadTasks}>
          <NavLink to={'scheduled'}>Scheduled Tasks</NavLink>
          <NavLink to={'completed'}>Completed Tasks</NavLink>
        </PermissionGuard>
      </TabNavigation>
      <Outlet />
    </div>
  );
};
export default Tasks;
