import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import { exportInspectionNotes } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { saveBlobToDisk } from 'utils/file';

type ExportNotesProps = {
  disabled: boolean;
  inspectionId: string;
};

export const ExportNotes: FC<ExportNotesProps> = ({
  disabled,
  inspectionId,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [downloading, setDownloading] = useState(false);

  const tooltip = disabled
    ? 'No notes to export'
    : 'Download CSV with Inspection Notes';

  const downloadNotes = () => {
    setDownloading(true);
    dispatch(exportInspectionNotes({ baseUrl, id: inspectionId, token }))
      .unwrap()
      .then((result) => {
        const filename = result.fileName || 'inspection-notes.csv';
        saveBlobToDisk(result.data, filename);
        setSuccessMessage(`Notes exported to ${filename}`);
      })
      .catch(() => setErrorMessage('Unable to export notes'))
      .finally(() => setDownloading(false));
  };

  return (
    <Tooltip arrow placement='top' title={tooltip}>
      <div>
        <Button
          aria-label='Export Notes'
          disabled={disabled}
          icon={<ArrowDownOnSquareIcon />}
          loading={downloading}
          onClick={downloadNotes}
          secondary
        />
      </div>
    </Tooltip>
  );
};
