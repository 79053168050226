import { AnswerType } from '@dakota/platform-client';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getAnswerTypes } from './answersActions';

export type AnswerTypeState = {
  answerTypes: AnswerType[];
  isLoadingAnswerTypes: boolean;
};

const initialState: AnswerTypeState = {
  answerTypes: [],
  isLoadingAnswerTypes: false,
};

export const answersSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAnswerTypes.fulfilled, (state, action) => {
      state.isLoadingAnswerTypes = false;
      state.answerTypes = action.payload;
    });
    builder.addCase(getAnswerTypes.pending, (state) => {
      state.isLoadingAnswerTypes = true;
    });
    builder.addCase(getAnswerTypes.rejected, (state) => {
      state.isLoadingAnswerTypes = false;
    });
  },
  initialState,
  name: 'answers',
  reducers: {},
  selectors: {
    answerTypes: (state: AnswerTypeState) => state.answerTypes,
    getAnswerType: (state: AnswerTypeState) => getAnswerType(state),
    isLoadingAnswerTypes: (state: AnswerTypeState) =>
      state.isLoadingAnswerTypes,
  },
});

/**
 * Returns the answer type with the given id.
 * This function assumes that the ID is valid, meaning there actually is
 * an answer type with that ID, so it never returns `undefined`.
 */
const getAnswerType = createSelector(
  (state: AnswerTypeState) => state.answerTypes,
  (answerTypes) => (id: string) =>
    answerTypes.find((type) => type.id === id) as AnswerType,
);
