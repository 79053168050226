import { InspectionSection } from '@dakota/platform-client';
import { FC } from 'react';

import { Section } from './Section';

type SectionsProps = {
  /**
   * The sections currently on display.
   * Corresponds to the sections of an unzoned inspection,
   * and the sections for the current zone in a zoned inspection.
   */
  sections: InspectionSection[];
};

export const Sections: FC<SectionsProps> = ({ sections }) => {
  return sections.map((section, sectionIndex) => (
    <Section
      key={section.sectionNumber}
      section={section}
      sectionIndex={sectionIndex}
    />
  ));
};
