import { Group, ItemType } from '@dakota/platform-client';
import { PencilIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import MenuDropdown from 'components/MenuDropdown';
import Toggle from 'components/Toggle';
import { configSlice } from 'features/config/configSlice';
import {
  activateItemType,
  deactivateItemType,
} from 'features/items/itemTypesActions';
import { questionsSlice } from 'features/questions/questionsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import AddEditItemType from './addItemType';

const ItemTypeMenu: FC<{
  itemGroup: Group;
  itemType: ItemType;
}> = ({ itemGroup, itemType }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const allQuestions = useSelector(questionsSlice.selectors.allQuestions);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [active, setActive] = useState(!itemType.inactive);
  const [processingActivation, setProcessingActivation] = useState(false);
  const [isEditItemTypePanelOpen, setIsEditItemTypePanelOpen] = useState(false);

  const questionsPerItemType = allQuestions.filter(
    (question) => question.itemType.id === itemType.id,
  );
  const isItemTypeInUse = questionsPerItemType.some(
    (question) => !question.inactive,
  );
  const isToggleDisabled = itemType.inactive ? false : isItemTypeInUse;

  const processActivation = (newActiveState: boolean) => {
    setProcessingActivation(true);

    setActive(newActiveState);

    if (newActiveState) {
      dispatch(activateItemType({ baseUrl, id: itemType.id, token }))
        .unwrap()
        .then(() => {
          setSuccessMessage('Item type activated successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to activate item type');
        });
    } else {
      dispatch(deactivateItemType({ baseUrl, id: itemType.id, token }))
        .unwrap()
        .then(() => {
          setSuccessMessage('Item type deactivated successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to deactivate item type');
        });
    }

    setProcessingActivation(false);
  };

  const ItemTypeToggle = (
    <Toggle
      disabled={itemGroup.inactive || processingActivation || isToggleDisabled}
      id='item-type-status-toggle'
      isOn={active}
      label='Active'
      onToggle={processActivation}
      showIcon={true}
    />
  );

  const tooltipMessage = useMemo(() => {
    if (itemGroup.inactive) {
      return 'Cannot reactivate an Item Type that belongs to an inactive Item Group.';
    }

    return (
      <>
        This Item Type cannot be disabled because there are active questions
        associated with it.
        <br />
        These questions must be deactivated before the Item Type can be
        disabled.
      </>
    );
  }, [itemGroup.inactive]);

  return (
    <div data-testid={`item-type-menu-${itemType.id}`}>
      <MenuDropdown
        buttonTestId='item-type-menu-button'
        contentTestId='item-type-contextual-menu'
      >
        <div
          className={clsx(
            'bg-white w-44 flex flex-col p-4 gap-3',
            'rounded-md shadow-md text-sm font-medium text-gray-800',
          )}
        >
          <button
            className={'flex gap-2 cursor-pointer'}
            data-testid='edit-item-type-button'
            onClick={() => setIsEditItemTypePanelOpen(true)}
          >
            <PencilIcon className='w-4' />
            <p>Edit</p>
          </button>
          <PermissionGuard permissions={Permission.DeactivateItemMetadata}>
            {isToggleDisabled || itemGroup.inactive ? (
              <Tooltip placement='bottom' title={tooltipMessage}>
                <div>{ItemTypeToggle}</div>
              </Tooltip>
            ) : (
              ItemTypeToggle
            )}
          </PermissionGuard>
        </div>
      </MenuDropdown>
      {isEditItemTypePanelOpen && (
        <AddEditItemType
          itemType={itemType}
          onClose={() => setIsEditItemTypePanelOpen(false)}
        />
      )}
    </div>
  );
};

export default ItemTypeMenu;
