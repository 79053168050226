import { PromptQuestionDetails } from '@dakota/platform-client';
import { FC } from 'react';

type QuestionSummaryProps = {
  question: PromptQuestionDetails;
};

export const QuestionSummary: FC<QuestionSummaryProps> = ({ question }) => {
  return (
    <div className='bg-gray-100 p-4 space-y-1'>
      <div className='font-bold text-pretty'>{question.text}</div>
      {question.citation && <div className='text-xs'>{question.citation}</div>}
      <div className='text-sm italic text-gray-400'>{question.itemType}</div>
    </div>
  );
};
