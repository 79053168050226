import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { DakotaConfig } from './types';

// NOTE: add an interceptor to configClient to retry on failed config request
export const fetchConfig = createAsyncThunk('config/fetchConfig', async () => {
  const response = await axios.get<DakotaConfig>('/config.json', {
    baseURL: window.location.origin,
  });
  return response.data;
});
