import { FormSummary } from '@dakota/platform-client';
import { PencilIcon } from '@heroicons/react/24/outline';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import MenuDropdown from 'components/MenuDropdown';
import Toggle from 'components/Toggle';
import { configSlice } from 'features/config/configSlice';
import { activateForm, deactivateForm } from 'features/forms/formsActions';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import FormBuilder from 'Pages/FormBuilder';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

const TemplatesMenu = ({ form }: { form: FormSummary }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [active, setActive] = useState(!form.inactive);
  const [editing, setEditing] = useState(false);

  const onToggle = () => {
    if (active) {
      dispatch(
        deactivateForm({
          baseUrl,
          id: form.id,
          token,
          type: form.type,
        }),
      )
        .unwrap()
        .then(() => {
          setActive(!active);
          setSuccessMessage('Form successfully deactivated');
        })
        .catch(() => {
          setErrorMessage('Failed to deactivate form');
        });
    } else {
      dispatch(
        activateForm({
          baseUrl,
          id: form.id,
          token,
          type: form.type,
        }),
      )
        .unwrap()
        .then(() => {
          setActive(!active);
          setSuccessMessage('Form successfully activated');
        })
        .catch(() => {
          setErrorMessage('Failed to activate form');
        });
    }
  };

  return (
    <>
      <MenuDropdown>
        <div
          className={clsx(
            'bg-white w-44 flex flex-col p-4 gap-3',
            'rounded-md shadow-md text-sm font-medium text-gray-800',
          )}
        >
          <button
            className='flex items-center gap-1'
            onClick={() => setEditing(true)}
          >
            <PencilIcon height={24} width={24} />
            <span>Edit</span>
          </button>
          <PermissionGuard
            permissions={[
              Permission.DeactivateFacilityForm,
              Permission.DeactivateGlobalForm,
            ]}
          >
            <Toggle
              isOn={active}
              label='Active'
              onToggle={onToggle}
              showIcon={true}
            />
          </PermissionGuard>
        </div>
      </MenuDropdown>
      {editing && (
        <FormBuilder
          facility={form.facility}
          formId={form.id}
          onClose={() => setEditing(false)}
        />
      )}
    </>
  );
};

export default TemplatesMenu;
