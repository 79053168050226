import {
  Inspection,
  InspectionStatus,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import ClearAllButton from 'components/ClearAll';
import DatePicker from 'components/DatePicker';
import InspectionsTable from 'components/Inspections/InspectionsTable';
import SearchInput from 'components/SearchInput';
import { configSlice } from 'features/config/configSlice';
import { listInspections } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import Fuse from 'fuse.js';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { useAppDispatch } from 'store/store';
import { alphabeticalCompare } from 'utils/functional';
import { getStatusLabel } from 'utils/inspectionStatusLabel';

type DateRangeType = {
  endDate?: LocalDate;
  startDate?: LocalDate;
};

const CompletedInspections: React.FC = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const accessibleFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );
  const isLoadingAccessibleFacilities = useSelector(
    userSlice.selectors.isLoadingAccessibleFacilities,
  );
  const selectableFacilities = accessibleFacilities.toSorted(
    alphabeticalCompare((e) => e.name),
  );

  const allInspections = useSelector(inspectionSlice.selectors.inspections);
  const loadingInspections = useSelector(
    inspectionSlice.selectors.isLoadingInspections,
  );
  const allUserSummaries = useSelector(userSlice.selectors.allUserSummaries);
  const activeUsers = allUserSummaries
    .filter((u) => !u.inactive)
    .sort(alphabeticalCompare((u) => u.name));
  const isLoadingUserSummaries = useSelector(
    userSlice.selectors.isLoadingAllUserSummaries,
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredInspections, setFilteredInspections] = useState<Inspection[]>(
    [],
  );

  const statuses = [InspectionStatus.Completed, InspectionStatus.Canceled];
  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<InspectionStatus>(
    InspectionStatus.Completed,
  );

  const currentDate = LocalDate.now();
  const thirtyDaysAgo = currentDate.minusDays(30);

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>({
    endDate: currentDate,
    startDate: thirtyDaysAgo,
  });
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);

  const { stopTracking } = usePageLoadTracking();

  useEffect(
    () =>
      void dispatch(
        listInspections({
          baseUrl,
          status: [InspectionStatus.Completed, InspectionStatus.Canceled],
          token,
        }),
      ).then(() => stopTracking(DataStatus.Fetched)),
    [baseUrl, dispatch, stopTracking, token],
  );

  useEffect(() => {
    let newInspections = allInspections;

    if (selectedStatus === InspectionStatus.Completed) {
      newInspections = newInspections.filter(
        (q) => q.status === InspectionStatus.Completed,
      );
    } else {
      newInspections = newInspections.filter(
        (q) => q.status === InspectionStatus.Canceled,
      );
    }

    if (selectedFacilities.length > 0) {
      newInspections = newInspections.filter((inspection) =>
        selectedFacilities.find(
          (facility) => facility.id === inspection.facility.id,
        ),
      );
    }

    if (selectedAssignees.length > 0) {
      newInspections = newInspections.filter((inspection) =>
        selectedAssignees.find((assignee) => assignee.id === inspection.userId),
      );
    }

    if (selectedDateRange.startDate && selectedDateRange.endDate) {
      const startDate = selectedDateRange.startDate;

      const endDate = selectedDateRange.endDate;

      newInspections = newInspections.filter((inspection) => {
        const inspectionEndDate = LocalDate.parse(
          inspection.timeline.endDate as string,
        );

        return (
          startDate.compareTo(inspectionEndDate) <= 0 &&
          inspectionEndDate.compareTo(endDate) <= 0
        );
      });
    }

    if (searchQuery) {
      const fuse = new Fuse(newInspections, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'form.name', weight: 1 },
          { name: 'facility.name', weight: 1 },
          { name: 'userName', weight: 0.8 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newInspections = fuse.search(searchQuery).map((e) => e.item);
    }

    /**
     * This codeblock handles sorting the completed inspections by the date on
     * which they were completed. More recently completed inspections are at
     * the top, sorted in descending order.
     */
    newInspections.sort((a, b) =>
      (b.timeline.endDate as string).localeCompare(
        a.timeline.endDate as string,
      ),
    );

    setFilteredInspections(newInspections);
  }, [
    allInspections,
    searchQuery,
    selectedAssignees,
    selectedDateRange.endDate,
    selectedDateRange.startDate,
    selectedFacilities,
    selectedStatus,
  ]);

  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.endDate && newValue.startDate) {
      const formattedDate = {
        endDate: LocalDate.parse(newValue.endDate.toString()),
        startDate: LocalDate.parse(newValue.startDate.toString()),
      };
      setSelectedDateRange(formattedDate);
    } else {
      setSelectedDateRange({ endDate: undefined, startDate: undefined });
    }
  };

  const removeFacility = (facility: Summary) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const removeAssignee = (assignee: UserSummary) => {
    setSelectedAssignees((prev) => prev.filter((f) => f.id !== assignee.id));
  };

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedAssignees([]);
    setSelectedFacilities([]);
    setSelectedStatus(InspectionStatus.Completed);
    setSelectedDateRange({
      endDate: currentDate,
      startDate: thirtyDaysAgo,
    });
  };

  useEffect(() => {
    setIsClearButtonVisible(
      selectedAssignees.length > 0 ||
        selectedFacilities.length > 0 ||
        selectedStatus !== InspectionStatus.Completed ||
        (selectedDateRange.startDate &&
          selectedDateRange.startDate.compareTo(thirtyDaysAgo) !== 0) ||
        (selectedDateRange.endDate &&
          selectedDateRange.endDate.compareTo(currentDate) !== 0) ||
        !!searchQuery,
    );
  }, [
    currentDate,
    searchQuery,
    selectedAssignees.length,
    selectedDateRange.endDate,
    selectedDateRange.startDate,
    selectedFacilities.length,
    selectedStatus,
    thirtyDaysAgo,
  ]);

  const setDatePickerValue = () => {
    if (selectedDateRange.endDate && selectedDateRange.startDate) {
      return {
        endDate: selectedDateRange.endDate.toString(),
        startDate: selectedDateRange.startDate.toString(),
      };
    } else {
      return {
        endDate: null,
        startDate: null,
      };
    }
  };

  return (
    <div className='p-4 md:p-8'>
      <Helmet>
        <title>Completed | Inspections | Dakota Software</title>
      </Helmet>
      <div className='justify-between hidden w-full pb-8 md:flex'>
        <h2 className='text-2xl font-semibold'>Completed</h2>
      </div>
      <div className='filters-container'>
        <SearchInput
          className='w-full'
          data-testid='search-input'
          onSearch={setSearchQuery}
          value={searchQuery}
        />
        <DatePicker
          id='completed-inspections-date'
          onChange={handleValueChange}
          placeholder='date range'
          popoverDirection='down'
          showShortcuts
          useRange
          value={setDatePickerValue()}
        />
        <Autocomplete
          className='max-sm:w-60 w-40'
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          id='facilities-selector'
          label='Facilites'
          loading={isLoadingAccessibleFacilities}
          multiple
          onChange={setSelectedFacilities}
          options={selectableFacilities}
          value={selectedFacilities}
        />
        <Autocomplete
          className='max-sm:w-60 w-40'
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          id='assignees-selector'
          label='Assignee'
          loading={isLoadingUserSummaries}
          multiple
          onChange={setSelectedAssignees}
          options={activeUsers}
          value={selectedAssignees}
        />
        <Autocomplete
          className='w-36'
          getOptionLabel={getStatusLabel}
          id='status-selector'
          label='Status'
          onChange={setSelectedStatus}
          options={statuses}
          value={selectedStatus}
        />
        {isClearButtonVisible && <ClearAllButton onClick={resetFilters} />}
      </div>
      <Chips
        elements={selectedFacilities}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeFacility}
      />
      <Chips
        elements={selectedAssignees}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeAssignee}
      />
      <InspectionsTable
        data={filteredInspections}
        loading={loadingInspections}
        searchQuery={searchQuery}
        showCompletedDate
      />
    </div>
  );
};

export default CompletedInspections;
