import {
  DocumentIcon,
  PaperClipIcon,
  PhotoIcon,
  TableCellsIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { FC, useEffect, useMemo, useState } from 'react';

type FileIconProps = {
  file: File;
};

export const FileIcon: FC<FileIconProps> = ({ file }) => {
  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  const icon = useMemo(() => {
    switch (file.type) {
      case 'application/pdf':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <DocumentIcon aria-label='Document' />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'text/csv':
        return <TableCellsIcon aria-label='Spreadsheet' />;
      case 'image/jpeg':
      case 'image/png':
        return <img alt={file.name} className='rounded-md' src={previewUrl} />;
      case 'image/gif':
        return <PhotoIcon aria-label='GIF' />;
      case 'video/mp4':
        return <VideoCameraIcon aria-label='Video' />;
      default:
        return <PaperClipIcon aria-label='Other documents' />;
    }
  }, [file.name, file.type, previewUrl]);

  return (
    <div className='*:w-16 *:h-16 *:object-cover text-gray-300'>{icon}</div>
  );
};
