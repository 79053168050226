import { clsx } from 'clsx';
import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';

export const CarouselButton: FC<
  PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>
> = ({ children, ...props }) => {
  return (
    <button
      {...props}
      className={clsx(
        'p-2 flex justify-center items-center rounded-full',
        'hover:bg-white hover:bg-opacity-20',
      )}
    >
      {children}
    </button>
  );
};
