import { FacilitiesClient, Facility } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getAllFacilities = createAsyncThunk(
  'facilities/getAllFacilities',
  async (params: ClientData) => {
    const client = getClient(FacilitiesClient, params);
    const response = await client.listFacilities();
    return response.result;
  },
);

export const addFacility = createAsyncThunk(
  'facilities/addFacility',
  async (params: { body: Facility } & ClientData) => {
    const client = getClient(FacilitiesClient, params);
    const response = await client.createFacility(params.body);
    return response.result;
  },
);

export const updateFacility = createAsyncThunk(
  'facilities/updateFacility',
  async (params: { facility: Facility } & ClientData) => {
    const client = getClient(FacilitiesClient, params);
    const response = await client.updateFacilities(
      params.facility.id,
      params.facility,
    );
    return response.result;
  },
);

export const activateFacility = createAsyncThunk(
  'facilities/activateFacility',
  async (params: { id: string } & ClientData) => {
    const client = getClient(FacilitiesClient, params);
    const response = await client.reactivateFacility(params.id);
    return response.result;
  },
);

/**
 * @return id of the deactivated facility
 */
export const deactivateFacility = createAsyncThunk(
  'facilities/deactivateFacility',
  async (params: { id: string } & ClientData, { fulfillWithValue }) => {
    const client = getClient(FacilitiesClient, params);
    await client.deleteOrDeactivateFacility(params.id, false);
    return fulfillWithValue(params.id);
  },
);

/**
 * @return id of the deleted facility
 */
export const deleteFacility = createAsyncThunk(
  'facilities/deleteFacility',
  async (params: { id: string } & ClientData, { fulfillWithValue }) => {
    const client = getClient(FacilitiesClient, params);
    await client.deleteOrDeactivateFacility(params.id, true);
    return fulfillWithValue(params.id);
  },
);

// Returns summary of all active facilities for the organization
export const getActiveOrgFacilities = createAsyncThunk(
  'facilities/getActiveOrgFacilities',
  async (params: ClientData) => {
    const client = getClient(FacilitiesClient, params);
    const response = await client.listFacilitiesForAdministrativeUserSearch();
    return response.result;
  },
);
