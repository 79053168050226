import { DesktopTable } from './desktopTable';
import { MobileTable } from './mobileTable';
import { KeyedObject, TableProps } from './types';

const Table = <T extends KeyedObject>(props: TableProps<T>) => {
  return (
    <>
      <DesktopTable {...props} />
      <MobileTable {...props} />
    </>
  );
};

export default Table;
