import { clsx } from 'clsx';
import { FC } from 'react';
import { noOp } from 'utils/functional';

const ClearAllButton: FC<{ onClick?: () => void; text?: string }> = ({
  onClick = noOp,
  text = 'clear all',
}) => {
  return (
    <button
      className={clsx(
        'max-sm:inline-flex text-red-base px-1 outline-none',
        'focus:ring-2 focus:ring-red-base focus:rounded-md focus:ring-inset',
      )}
      data-testid='clear-all-button'
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default ClearAllButton;
