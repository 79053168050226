import { TaskState } from '@dakota/platform-client';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import { FC } from 'react';

import { TaskInstanceWithId } from '../idWrap';
import { getStatusLabel } from '../types';
import { CompleteButton } from './complete';
import { Counters } from './counters';
import { Meta } from './meta';

const statuses: TaskState[] = [
  TaskState.Open,
  TaskState.InProgress,
  TaskState.Completed,
  TaskState.Canceled,
];

type TaskCardProps = {
  completeTaskInstance: (task: TaskInstanceWithId) => void;
  openTaskInstance: (task: TaskInstanceWithId) => void;
  setTaskInstanceStatus: (task: TaskInstanceWithId, status: TaskState) => void;
  task: TaskInstanceWithId;
};

export const TaskCard: FC<TaskCardProps> = ({
  completeTaskInstance,
  openTaskInstance,
  setTaskInstanceStatus,
  task,
}) => {
  const completeTask = () => completeTaskInstance(task);

  const openTask = () => openTaskInstance(task);

  const setStatus = (status: TaskState) => setTaskInstanceStatus(task, status);

  return (
    <div
      className={clsx(
        'border border-gray-100 rounded-md group',
        'flex max-sm:flex-col justify-between text-gray-700',
        'hover:border-gray-300 *:group-hover:border-gray-300',
      )}
    >
      <div className='flex-1 flex flex-col'>
        <div
          className={clsx(
            'flex-none min-h-12 px-4 py-2 flex items-center justify-between',
            'border-b group-hover:border-gray-300 rounded-tl-md font-bold',
            { 'rounded-tr-md': task.status === TaskState.Completed },
            'max-sm:rounded-tr-md',
            task.overdue
              ? 'bg-red-base bg-opacity-10 group-hover:bg-opacity-20'
              : 'bg-gray-50 group-hover:bg-gray-100',
          )}
        >
          <button className='w-full text-start' onClick={openTask}>
            {task.title}
          </button>
          {task.overdue && (
            <button
              className={clsx(
                'flex-none w-32 flex items-center justify-end gap-1',
                'font-medium text-red-base',
              )}
              onClick={openTask}
            >
              <ExclamationCircleIcon className='w-5 h-5' /> Overdue
            </button>
          )}
        </div>
        <div
          className={clsx(
            'flex-1 min-h-24 p-4 max-sm:flex-col',
            'flex sm:items-center sm:justify-between gap-4',
          )}
        >
          <button className='w-full text-start' onClick={openTask}>
            <div className='text-sm font-semibold'>Description</div>
            <div className='text-balance'>{task.description}</div>
          </button>
          <div className='flex-none w-40'>
            <Autocomplete
              fullWidth
              getOptionLabel={getStatusLabel}
              onChange={setStatus}
              options={statuses}
              testId='task-status'
              value={task.status}
            />
          </div>
        </div>
        <div
          className={clsx(
            'flex-none px-4 py-2 flex sm:items-center sm:justify-between',
            'border-t group-hover:border-gray-300',
            'max-sm:flex-col max-sm:gap-4',
          )}
        >
          <Meta onClick={openTask} task={task} />
          <Counters onClick={openTask} task={task} />
        </div>
      </div>
      <CompleteButton onComplete={completeTask} task={task} />
    </div>
  );
};
