import { TaskInstance, TaskPriority } from '@dakota/platform-client';
import {
  Bars2Icon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/16/solid';

export const taskPriority = (task: TaskInstance) => {
  const props = { className: 'flex items-center font-bold' };

  if (task.priority === TaskPriority.Low) {
    return (
      <span {...props}>
        <ChevronDownIcon className='w-5 h-5 text-blue-base' />
        low
      </span>
    );
  }
  if (task.priority === TaskPriority.Medium) {
    return (
      <span {...props}>
        <Bars2Icon className='w-4 h-4 pe-0.5 text-yellow-base' />
        medium
      </span>
    );
  }
  return (
    <span {...props}>
      <ChevronUpIcon className='w-5 h-5 text-red-base' />
      high
    </span>
  );
};
