import { useCheckPermission } from 'auth/AuthHelper';
import { FC, PropsWithChildren } from 'react';

interface PermissionGuardProps {
  /**
   * @default `false`
   */
  matchAll?: boolean;
  /**
   * When passing a single permission rather than an array of permissions,
   * always opt to pass a single string rather than an array of a strings.
   */
  permissions: Array<string> | string;
}

const PermissionGuard: FC<PropsWithChildren<PermissionGuardProps>> = ({
  children,
  matchAll = false,
  permissions,
}) => {
  const valid = useCheckPermission(permissions, matchAll);

  return valid ? children : <></>;
};

export default PermissionGuard;
