import { Prompt } from '@dakota/platform-client';
import {
  ChatBubbleBottomCenterTextIcon,
  PaperClipIcon,
} from '@heroicons/react/24/outline';
import MenuDropdown from 'components/MenuDropdown';
import { FC, useState } from 'react';

import { AddAttachments } from './AddAttachments';
import { AddNote } from './AddNote';

type PromptMenuProps = {
  prompt: Prompt;
  promptIndex: number;
  sectionIndex: number;
};

export const PromptMenu: FC<PromptMenuProps> = ({
  prompt,
  promptIndex,
  sectionIndex,
}) => {
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [isAddingAttachments, setIsAddingAttachments] = useState(false);

  const buttonClasses =
    'flex items-center gap-2 cursor-pointer disabled:text-gray-400';

  return (
    <>
      <MenuDropdown buttonAriaLabel='Open options menu'>
        <div className='p-4 flex flex-col gap-3 text-gray-700'>
          <button
            className={buttonClasses}
            onClick={() => setIsAddingNote(true)}
          >
            <ChatBubbleBottomCenterTextIcon className='w-5' />
            Add a note
          </button>
          <button
            className={buttonClasses}
            onClick={() => setIsAddingAttachments(true)}
          >
            <PaperClipIcon className='w-5' />
            Add attachments
          </button>
        </div>
      </MenuDropdown>
      {isAddingNote && (
        <AddNote
          onClose={() => setIsAddingNote(false)}
          promptId={prompt.id}
          promptIndex={promptIndex}
          question={prompt.question}
          sectionIndex={sectionIndex}
        />
      )}
      {isAddingAttachments && (
        <AddAttachments
          currentCount={prompt.media.length}
          onClose={() => setIsAddingAttachments(false)}
          promptId={prompt.id}
          promptIndex={promptIndex}
          question={prompt.question}
          sectionIndex={sectionIndex}
        />
      )}
    </>
  );
};
