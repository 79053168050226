import { toastSlice } from 'features/toast/toastSlice';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/store';

const useToast = () => {
  const dispatch = useAppDispatch();

  const setErrorMessage = useCallback(
    (msg: string) => {
      dispatch(toastSlice.actions.setErrorMessage(msg));
    },
    [dispatch],
  );

  const setInfoMessage = useCallback(
    (msg: string) => {
      dispatch(toastSlice.actions.setInfoMessage(msg));
    },
    [dispatch],
  );

  const setSuccessMessage = useCallback(
    (msg: string) => {
      dispatch(toastSlice.actions.setSuccessMessage(msg));
    },
    [dispatch],
  );

  const setWarningMessage = useCallback(
    (msg: string) => {
      dispatch(toastSlice.actions.setWarningMessage(msg));
    },
    [dispatch],
  );

  return {
    setErrorMessage,
    setInfoMessage,
    setSuccessMessage,
    setWarningMessage,
  };
};

export default useToast;
