import { FacilitiesClient, Zone } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const createZone = createAsyncThunk(
  'zones/createZone',
  async (params: { body: Zone; facilityId: string } & ClientData) => {
    const client = getClient(FacilitiesClient, params);
    const response = await client.createZone(params.facilityId, params.body);
    return { facilityId: params.facilityId, zone: response.result };
  },
);

export const listZones = createAsyncThunk(
  'zones/listZones',
  async (params: { facilityId: string } & ClientData) => {
    const client = getClient(FacilitiesClient, params);
    const response = await client.listZones(params.facilityId);
    return { facilityId: params.facilityId, zones: response.result };
  },
);

export const updateZone = createAsyncThunk(
  'zones/updateZone',
  async (
    params: { body: Zone; facilityId: string; id: string } & ClientData,
  ) => {
    const client = getClient(FacilitiesClient, params);
    const response = await client.updateZones(
      params.facilityId,
      params.id,
      params.body,
    );
    return { facilityId: params.facilityId, zone: response.result };
  },
);

export const reactivateZone = createAsyncThunk(
  'zones/reactivateZone',
  async (params: { facilityId: string; id: string } & ClientData) => {
    const client = getClient(FacilitiesClient, params);
    await client.reactivateZone(params.id, params.facilityId);
  },
);

export const deactivateZone = createAsyncThunk(
  'zones/deactivateZone',
  async (params: { facilityId: string; id: string } & ClientData) => {
    const client = getClient(FacilitiesClient, params);
    await client.deactivateZone(params.facilityId, params.id);
  },
);
