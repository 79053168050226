import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { configSlice } from 'features/config/configSlice';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface Props {
  children?: ReactNode;
}

const Auth0ProviderWithHistory = ({ children }: Props) => {
  const navigate = useNavigate();

  const config = useSelector(configSlice.selectors.config);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!config) {
    return <></>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { audience, clientId, domain } = config.auth || {};

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return domain && clientId && audience ? (
    <Auth0Provider
      authorizationParams={{
        audience: audience,
        redirect_uri: window.location.origin,
        scope: 'openid profile email',
      }}
      clientId={clientId}
      domain={domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      //cacheLocation="memory" // can also be 'localstorage' alternatively you can make your own ICache implementation (store in redux?)
    >
      {children}
    </Auth0Provider>
  ) : (
    <></>
  );
};

export default Auth0ProviderWithHistory;
