import { Inspection, InspectionStatus, Summary } from '@dakota/platform-client';
import { Dialog, Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import Table from 'components/Table';
import { ColumnProps } from 'components/Table/types';
import { userSlice } from 'features/user/userSlice';
import useFetchZones from 'hooks/useFetchZones';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatBackendDate } from 'utils/date';
import { getHighlighter } from 'utils/highlighter';

import ActionMenu from './ActionMenu';
import { Print } from './Print';
import {
  CompletedResultsBar,
  InProgressResultsBar,
  OverdueResultsBar,
  PendingResultsBar,
} from './ResultsBar';

interface InspectionsTableProps {
  data: Inspection[];
  loading: boolean;
  /**
   * If provided, it will highlight the search query in a few columns.
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If `true`, the Due Date column will be replaced with the Completed Date
   * column The Completed Date column works similarly to Due Date, but the
   * former renders inspection.timeline.endDate whereas the latter renders
   * inspection.timeline.scheduledDate
   * @default `false`
   */
  showCompletedDate?: boolean;
}

const statusDisplayMap: { [key: string]: string } = {
  canceled: 'Canceled',
  completed: 'Completed',
  inprogress: 'In Progress',
  overdue: 'Overdue',
  pending: 'Pending',
};

const getDisplayStatus = (status: string): string => {
  const lowerCaseStatus = status.toLowerCase();
  return statusDisplayMap[lowerCaseStatus];
};

const InspectionsTable: FC<InspectionsTableProps> = ({
  data,
  loading,
  searchQuery = undefined,
  showCompletedDate = false,
}) => {
  const { isLoadingAllZones } = useFetchZones();
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const [zoneSummaries, setZoneSummaries] = useState<Summary[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inspectionName, setInspectionName] = useState<null | string>(null);

  const highlight = getHighlighter(searchQuery ?? '');

  const renderZoneCell = (inspection: Inspection) => {
    const zoneText =
      inspection.zones.length > 1
        ? `${inspection.zones.length} zones`
        : '1 zone';

    return inspection.zones.length > 0 ? (
      <button
        className={clsx(
          'text-green-dark bg-green-lighter rounded-md',
          'text-xs p-2 hover:bg-green-light',
        )}
        onClick={() => {
          setZoneSummaries(inspection.zones);
          setInspectionName(inspection.form.name);
          setDialogOpen(true);
        }}
      >
        {zoneText}
      </button>
    ) : (
      <Tooltip
        arrow
        enterTouchDelay={0}
        title={`No zones on ${inspection.form.name}`}
      >
        <div
          className={clsx(
            'inline-block text-xs rounded-md p-2',
            'bg-gray-100 text-gray-500 hover:cursor-help',
          )}
        >
          General
        </div>
      </Tooltip>
    );
  };

  const handleClose = () => {
    setInspectionName(null);
    setZoneSummaries([]);
    setDialogOpen(false);
  };

  const renderResultsBar = (inspection: Inspection) => {
    switch (inspection.status) {
      case InspectionStatus.Completed:
        return <CompletedResultsBar prompts={inspection.prompts} />;
      case InspectionStatus.InProgress:
        return <InProgressResultsBar prompts={inspection.prompts} />;
      case InspectionStatus.Overdue:
        return <OverdueResultsBar />;
      default:
        return <PendingResultsBar />;
    }
  };

  const columns: ReadonlyArray<ColumnProps<Inspection>> = [
    {
      cellDataTestId: 'title',
      key: 'title',
      render: (inspection) =>
        inspection.status !== InspectionStatus.Canceled &&
        (currentUser.id === inspection.userId ||
          inspection.status === InspectionStatus.Completed) ? (
          <Link
            className='text-green-base hover:text-green-darker'
            to={`/inspections/${inspection.id}?source=${location.pathname}`}
          >
            {highlight(inspection.form.name)}
          </Link>
        ) : (
          highlight(inspection.form.name)
        ),
      title: 'Inspection Title',
    },
    showCompletedDate
      ? {
          cellDataTestId: 'completedDate',
          key: 'completedDate',
          render: (inspection) =>
            formatBackendDate(inspection.timeline.endDate as string),
          title: 'Completed Date',
        }
      : {
          cellDataTestId: 'dueDate',
          key: 'dueDate',
          render: (inspection) =>
            formatBackendDate(inspection.timeline.scheduledDate),
          title: 'Due Date',
        },
    {
      cellDataTestId: 'facility',
      key: 'facility',
      render: (inspection) => highlight(inspection.facility.name),
      title: 'Facility',
    },
    {
      cellDataTestId: 'zone',
      key: 'zone',
      render: renderZoneCell,
      title: 'Zone',
    },
    {
      cellDataTestId: 'assignee',
      key: 'assignee',
      render: (inspection) => highlight(inspection.userName ?? 'Unassigned'),
      title: 'Assignee',
    },
    {
      cellDataTestId: 'status',
      key: 'status',
      render: (inspection) => getDisplayStatus(inspection.status),
      title: 'Status',
    },
    {
      cellDataTestId: 'results',
      key: 'results',
      render: renderResultsBar,
      title: 'Results',
    },
    {
      cellDataTestId: 'actions',
      key: 'actions',
      render: (inspection) => {
        if (inspection.status === InspectionStatus.Completed) {
          return <Print inspection={inspection} />;
        } else if (inspection.status !== InspectionStatus.Canceled) {
          return <ActionMenu inspection={inspection} />;
        }
      },
      title: 'Actions',
    },
  ];

  return (
    <>
      <Table
        {...{ columns, data, loading }}
        footer={`${data.length} results`}
        id='inspections-table'
      />
      {dialogOpen && (
        <Dialog fullWidth maxWidth='xs' onClose={handleClose} open={dialogOpen}>
          <div className='flex flex-col gap-3 p-6 text-gray-500'>
            <div className='text-xl font-semibold text-gray-900'>
              Zones for {inspectionName}
            </div>
            {isLoadingAllZones ? (
              <div className='flex justify-center items-center'>
                <Spinner />
              </div>
            ) : (
              <ol className='list-decimal list-inside' data-testid='zone-list'>
                {zoneSummaries.map((zone) => (
                  <li key={zone.id}>{zone.name}</li>
                ))}
              </ol>
            )}
          </div>
        </Dialog>
      )}
    </>
  );
};

export default InspectionsTable;
