import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { FC } from 'react';

type Props = {
  /**
   * Whether the logo is displayed in a dropdown.
   * When `true`, it styles the hover state appropriately.
   * @default `false`.
   */
  inDropdown?: boolean;
};

const DakotaLibraryLogo: FC<Props> = ({ inDropdown = false }) => {
  return (
    <Tooltip arrow title='Dakota Library'>
      <CheckBadgeIcon
        aria-label='Dakota Library'
        className={clsx('inline ml-1 mb-1 text-green-base min-w-4 h-4', {
          'group-hover:text-white group-[.is-active]:text-white': inDropdown,
        })}
      />
    </Tooltip>
  );
};

export default DakotaLibraryLogo;
