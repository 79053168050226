import { Group } from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';

import {
  activateItemGroup,
  addItemGroup,
  deactivateItemGroup,
  getAllItemGroups,
  updateItemGroup,
} from './itemGroupsActions';

export type GroupsState = {
  isLoadingItemGroups: boolean;
  itemGroups: Group[];
};

const initialState: GroupsState = {
  isLoadingItemGroups: false,
  itemGroups: [],
};

export const itemGroupsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAllItemGroups.pending, (state) => {
      state.isLoadingItemGroups = true;
    });
    builder.addCase(getAllItemGroups.rejected, (state) => {
      state.isLoadingItemGroups = false;
    });
    builder.addCase(getAllItemGroups.fulfilled, (state, action) => {
      state.isLoadingItemGroups = false;
      state.itemGroups = action.payload;
    });
    builder.addCase(addItemGroup.fulfilled, (state, action) => {
      state.itemGroups.push(action.payload);
    });
    builder.addCase(updateItemGroup.fulfilled, (state, action) => {
      state.itemGroups = state.itemGroups.map((itemGroup) =>
        itemGroup.id === action.payload.id ? action.payload : itemGroup,
      );
    });
    builder.addCase(activateItemGroup.fulfilled, (state, action) => {
      state.itemGroups = state.itemGroups.map((itemGroup) =>
        itemGroup.id === action.payload
          ? { ...itemGroup, inactive: false }
          : itemGroup,
      );
    });
    builder.addCase(deactivateItemGroup.fulfilled, (state, action) => {
      state.itemGroups = state.itemGroups.map((itemGroup) =>
        itemGroup.id === action.payload
          ? { ...itemGroup, inactive: true }
          : itemGroup,
      );
    });
  },
  initialState,
  name: 'itemGroups',
  reducers: {},
  selectors: {
    isLoadingItemGroups: (state: GroupsState) => state.isLoadingItemGroups,
    itemGroups: (state: GroupsState) => state.itemGroups,
  },
});
