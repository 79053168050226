import { EyeSlashIcon } from '@heroicons/react/24/outline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DialogContent, DialogTitle } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import { templatesSlice } from 'features/templates/templatesSlice';
import { FC } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import resolveConfig from 'tailwindcss/resolveConfig';
import { StrictModeDroppable } from 'utils/StrictModeDroppable';

import tailwindConfig from '../../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

type Props = {
  onClose: () => void;
};

const ReorderSections: FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const sections = useSelector(templatesSlice.selectors.sections);

  const moveSection = (onEnd: DropResult) => {
    const fromIndex = onEnd.source.index;
    const toIndex = onEnd.destination?.index;
    if (toIndex === undefined) {
      return;
    }

    dispatch(
      templatesSlice.actions.moveSection({
        fromIndex,
        toIndex,
      }),
    );
  };

  return (
    <div
      className={clsx('min-w-96 text-gray-700 text-lg')}
      data-testid='reorder-sections-container'
      id='reorder-sections-container'
    >
      <DialogTitle>
        Reorder Sections
        <div className='text-sm text-gray-500'>
          Drag and drop to change the order of sections on your template.
        </div>
      </DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={moveSection}>
          <StrictModeDroppable droppableId='sections'>
            {(droppableProvided) => (
              <div
                {...droppableProvided.droppableProps}
                className='flex flex-col gap-1 select-none'
                ref={droppableProvided.innerRef}
              >
                {sections.map((s, index) => (
                  <Draggable
                    draggableId={s.itemTypeId}
                    index={index}
                    key={s.itemTypeId}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={clsx(
                          'p-2 border rounded flex items-center justify-between',
                          snapshot.isDragging ? 'bg-gray-100' : '',
                          snapshot.dropAnimation ? 'bg-green-lightest' : '',
                        )}
                        ref={provided.innerRef}
                      >
                        <div
                          className={clsx(
                            'flex items-center gap-2',
                            s.headerVisible ? 'text-gray-700' : 'text-gray-400',
                          )}
                          key={s.itemTypeId}
                        >
                          <DragIndicatorIcon
                            sx={{ color: theme.colors.gray[400] }}
                          />
                          {s.header}
                        </div>
                        {!s.headerVisible && (
                          <div className='pr-2'>
                            <Tooltip
                              placement='bottom'
                              title='This header will not be visible on Inspection'
                            >
                              <EyeSlashIcon className='w-5 h-5 text-gray-400' />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        <Button className='mt-8 text-xs' onClick={onClose} secondary>
          Close
        </Button>
      </DialogContent>
    </div>
  );
};

export default ReorderSections;
