import TabNavigation from 'components/TabNavigation';
import { NavLink, Outlet } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div className='h-full' data-testid='dashboard'>
      <TabNavigation>
        <NavLink to={'charts'}>Dashboard Charts</NavLink>
        <NavLink to={'assignments'}>My Assignments</NavLink>
      </TabNavigation>
      <Outlet />
    </div>
  );
};
export default Dashboard;
