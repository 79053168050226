import {
  ChevronDoubleUpIcon,
  ClipboardDocumentIcon,
  ClipboardIcon,
} from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { formsSlice } from 'features/forms/formsSlice';
import { templatesSlice } from 'features/templates/templatesSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import Section from './section';

type Props = {
  templateId?: string;
};

const Sections: FC<Props> = ({ templateId }) => {
  const sections = useSelector(templatesSlice.selectors.sections);
  const isLoadingTemplate = useSelector(formsSlice.selectors.isLoadingForm);

  return (
    <div
      className={clsx(
        'bg-gray-100 h-full p-5',
        'overflow-y-auto overscroll-y-contain',
      )}
      id='sections-container'
    >
      {sections.length === 0 && !templateId && (
        <div
          className={clsx(
            'flex flex-col w-full h-full items-center gap-4 pt-8',
            'text-lg text-gray-500 text-center',
          )}
        >
          <ClipboardIcon className='w-24 h-24' />
          <p>
            The template has no questions.
            <br />
            Add questions from the left panel.
          </p>
        </div>
      )}
      {templateId && isLoadingTemplate && (
        <div
          className={clsx(
            'flex flex-col w-full h-full items-center gap-4 pt-8',
            'text-lg text-gray-500 text-center',
          )}
        >
          <ClipboardDocumentIcon className='w-24 h-24 animate-pulse' />
          <p>Loading template...</p>
        </div>
      )}
      {sections.length > 0 && (
        <>
          <div className='flex flex-col gap-7'>
            {sections.map((section, index) => (
              <Section
                index={index}
                key={section.itemTypeId}
                section={section}
              />
            ))}
          </div>
          <div className='flex justify-end mt-8'>
            <Button
              hasShadow={false}
              icon={<ChevronDoubleUpIcon />}
              onClick={() =>
                document
                  .getElementById('section-0')
                  ?.scrollIntoView({ behavior: 'smooth' })
              }
              secondary
            >
              Go to top
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Sections;
