import { configSlice } from 'features/config/configSlice';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

const CompletedTasks = () => {
  const appName = useSelector(configSlice.selectors.appName);

  return (
    <div className='p-4 sm:p-8'>
      <Helmet>
        <title>Completed Tasks | {appName}</title>
      </Helmet>
      <div className='max-sm:hidden pb-8'>
        <h2 className='text-2xl font-semibold'>Completed Tasks</h2>
      </div>
    </div>
  );
};

export default CompletedTasks;
