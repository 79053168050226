import { useAuth0 } from '@auth0/auth0-react';
import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { logoutUser } from 'features/user/userSlice';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/store';

export const Forbidden = () => {
  const dispatch = useAppDispatch();
  const { logout } = useAuth0();

  // "This message will self-destruct in 10 seconds!"
  const [time, setTime] = useState(10);

  const doLogout = useCallback(() => {
    // Clear the 'forbidden' flag to allow the user to log in again
    localStorage.removeItem('isForbidden');

    dispatch(logoutUser());
    void logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }, [dispatch, logout]);

  useEffect(() => {
    const interval = setInterval(() => setTime((prev) => prev - 1), 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (time === 0) {
      doLogout();
    }
  }, [doLogout, time]);

  return (
    <div
      className={clsx(
        'flex h-svh w-full justify-center items-center',
        'bg-red-lightest bg-opacity-10 text-gray-800',
      )}
    >
      <div
        className={clsx(
          'flex gap-8 justify-between items-center p-8 rounded-lg text-xl',
          'border-2 border-red-base bg-red-lightest',
        )}
      >
        <div className='text-red-base'>
          <ShieldExclamationIcon className='w-16 h-16' />
        </div>
        <div className='space-y-4 w-80'>
          <div className='font-bold'>
            To protect your account security, you will be logged out.
          </div>
          <div className='text-sm text-gray-600'>
            If you have questions, please contact your system administrator.
          </div>
          <Button aria-label='Acknowledge' onClick={doLogout}>
            Acknowledge ({time})
          </Button>
        </div>
      </div>
    </div>
  );
};
