import { convert, LocalDate } from '@js-joda/core';

/**
 * Convert a date string as returned by the backend
 * to a string in the user's locale.
 */
export const formatBackendDate = (date: string): string => {
  return convert(LocalDate.parse(date)).toDate().toLocaleDateString();
};

/**
 * Convert a date+time string as returned by the backend
 * to a string in the user's locale.
 */
export const formatBackendDateTime = (
  dateTime: string,
  displayTime = true,
): string => {
  const dateObj = new Date(dateTime);
  return displayTime ? dateObj.toLocaleString() : dateObj.toLocaleDateString();
};

/**
 * Comparator for date+time strings containing timezone information
 * (these are not convertible to @js-joda's `LocalDateTime` type).
 * Compares the two dates in ascending order (oldest first).
 */
export const compareByDateTime = (lhs: string, rhs: string): number => {
  return new Date(lhs).getTime() - new Date(rhs).getTime();
};
