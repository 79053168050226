import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';
import { FC, InputHTMLAttributes, useState } from 'react';

interface CustomSearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  onSearch: (value: string) => void;
  placeholder?: string;
}

const SearchInput: FC<CustomSearchInputProps> = ({
  className = '',
  onSearch,
  placeholder = 'Search',
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const baseInputStyles = clsx(
    'text-base text-sans text-sm',
    'py-2 rounded-md shadow-sm',
    'placeholder:text-gray-400 leading-5',
    'disabled:bg-gray-200 disabled:cursor-not-allowed',
    'pl-9 pr-18 border-none ring-1 ring-gray-300',
    'text-gray-700',
    'border-none ring-1 ring-gray-300',
    'focus:ring-2 focus:ring-inset focus:ring-blue-base',
  );

  const inputStyles = `${baseInputStyles} ${className}`;

  return (
    <div className='relative'>
      <MagnifyingGlassIcon className='absolute w-5 h-5 text-gray-700 left-2 top-2' />
      <input
        className={inputStyles}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          onSearch(e.target.value);
        }}
        placeholder={placeholder}
        type='search'
        value={searchTerm}
        {...rest}
      />
    </div>
  );
};

export default SearchInput;
