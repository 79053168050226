import { ItemsClient, ItemType } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getAllItemTypes = createAsyncThunk(
  'items/types',
  async (params: ClientData) => {
    const client = getClient(ItemsClient, params);
    const response = await client.listItemTypes();
    return response.result;
  },
);

/**
 * @return the item type that was just added
 */
export const addItemType = createAsyncThunk(
  'items/types/create',
  async (params: { itemType: ItemType } & ClientData) => {
    const client = getClient(ItemsClient, params);
    const response = await client.createItemType(params.itemType);
    return response.result;
  },
);

/**
 * @return the updated item type
 */
export const updateItemType = createAsyncThunk(
  'items/types/update',
  async (params: { itemType: ItemType } & ClientData, { fulfillWithValue }) => {
    const client = getClient(ItemsClient, params);
    await client.updateItemType(params.itemType.id, params.itemType);
    return fulfillWithValue(params.itemType);
  },
);

/**
 * @return the id of the activated item type
 */
export const activateItemType = createAsyncThunk(
  'items/types/activate',
  async (params: { id: string } & ClientData, { fulfillWithValue }) => {
    const client = getClient(ItemsClient, params);
    await client.reactivateItemType(params.id);
    return fulfillWithValue(params.id);
  },
);

/**
 * @return the id of the deactivated item type
 */
export const deactivateItemType = createAsyncThunk(
  'items/types/deactivate',
  async (params: { id: string } & ClientData, { fulfillWithValue }) => {
    const client = getClient(ItemsClient, params);
    await client.deactivateItemType(params.id);
    return fulfillWithValue(params.id);
  },
);
