import { ClientBase, IConfig } from '@dakota/client-common';
import axios, { AxiosInstance } from 'axios';

/**
 * When creating a client instance, we need to pass two parameters:
 * the base URL for the axios calls, which comes from the configuration
 * object, and the token, which comes from Auth0.
 */
export type ClientData = {
  baseUrl: string;
  token: string;
};

/**
 * We only need a single axios instance that sets `withCredentials` to `true`.
 * The URL to be called will be passed by the clients when the calls are made.
 */
const axiosInstance = axios.create({ withCredentials: true });

/**
 * Generic function to create a client instance with the token and base URL
 * to be used in the axios calls. Instead of creating clients directly,
 * we should always obtain them through this function.
 */
export function getClient<T extends ClientBase>(
  ctor: new (config: IConfig, baseUrl?: string, instance?: AxiosInstance) => T,
  clientData: ClientData,
): T {
  return new ctor(
    { getAuthorization: clientData.token },
    clientData.baseUrl.replace(/\/$/, ''),
    axiosInstance,
  );
}
