import { useAuth0 } from '@auth0/auth0-react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Button from 'components/Button';
import { logoutUser } from 'features/user/userSlice';
import { FC, useState } from 'react';
import { useAppDispatch } from 'store/store';

type Props = {
  showIcon?: boolean;
};

const LogoutButton: FC<Props> = ({ showIcon = false }) => {
  const { logout } = useAuth0();
  const dispatch = useAppDispatch();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoggingOut(true);
    try {
      dispatch(logoutUser());
      await logout({ logoutParams: { returnTo: window.location.origin } });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <Button
      disabled={isLoggingOut}
      icon={showIcon ? <LogoutOutlinedIcon /> : undefined}
      onClick={(event) => {
        const returnedLogout = handleLogout;
        void returnedLogout(event);
      }}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
