import { InspectionDetails, InspectionStatus } from '@dakota/platform-client';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { configSlice } from 'features/config/configSlice';
import { completeInspection } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { ExportNotes } from './ExportNotes';

type FooterProps = {
  inspectionDetails: InspectionDetails;
  onClose: () => void;
  saveCurrentResponses: () => Promise<void>;
};

export const Footer: FC<FooterProps> = ({
  inspectionDetails,
  onClose,
  saveCurrentResponses,
}) => {
  const dispatch = useAppDispatch();
  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const hasUnsavedChanges = useSelector(
    inspectionSlice.selectors.hasUnsavedChanges,
  );
  const isSavingResponses = useSelector(
    inspectionSlice.selectors.isSavingResponses,
  );
  const isCompletingInspection = useSelector(
    inspectionSlice.selectors.isCompletingInspection,
  );
  const totalAnsweredQuestions = useSelector(
    inspectionSlice.selectors.totalAnsweredQuestions,
  );
  const totalQuestions = useSelector(inspectionSlice.selectors.totalQuestions);
  const totalNotes = useSelector(inspectionSlice.selectors.totalNotes);

  const saveChanges = () => {
    dispatch(inspectionSlice.actions.setSavingResponses(true));
    saveCurrentResponses()
      .then(() => setSuccessMessage('Inspection saved'))
      .catch(() => setErrorMessage('Unable to save inspection'))
      .finally(() =>
        dispatch(inspectionSlice.actions.setSavingResponses(false)),
      );
  };

  const complete = async () => {
    dispatch(inspectionSlice.actions.setIsCompleting(true));

    try {
      if (hasUnsavedChanges) {
        // Need to save unsaved responses before completing
        await saveCurrentResponses();
      }

      await dispatch(
        completeInspection({
          baseUrl,
          id: inspectionDetails.id,
          token,
        }),
      ).unwrap();
      setSuccessMessage('Inspection completed');
      onClose();
    } catch {
      setErrorMessage('Unable to complete inspection');
    } finally {
      dispatch(inspectionSlice.actions.setIsCompleting(false));
    }
  };

  return (
    <div
      className={clsx(
        'h-14 sm:h-16 flex-none px-4 sm:px-24',
        'border-t border-gray-300 *:text-sm',
        'flex items-center gap-2 text-sm sm:text-base',
      )}
    >
      <Button
        aria-label='Complete Inspection'
        disabled={
          isSavingResponses ||
          totalAnsweredQuestions !== totalQuestions ||
          inspectionDetails.status !== InspectionStatus.InProgress
        }
        loading={isCompletingInspection}
        onClick={() => void complete()}
      >
        Complete
      </Button>
      <Button
        aria-label='Save Inspection'
        disabled={!hasUnsavedChanges}
        loading={isSavingResponses}
        onClick={saveChanges}
        secondary
      >
        Save
      </Button>
      <Button
        alert
        aria-label='Cancel Inspection'
        disabled={
          inspectionDetails.status === InspectionStatus.Completed ||
          isCompletingInspection
        }
        onClick={() =>
          dispatch(inspectionSlice.actions.openCancelConfirmationDialog())
        }
      >
        <span className='max-sm:hidden'>Cancel Inspection</span>
        <span className='sm:hidden'>Cancel</span>
      </Button>
      <ExportNotes
        disabled={totalNotes === 0}
        inspectionId={inspectionDetails.id}
      />
    </div>
  );
};
