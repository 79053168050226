import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TokenState = {
  token: string;
};

const initialState: TokenState = {
  token: '',
};

export const tokenSlice = createSlice({
  initialState,
  name: 'token',
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
  selectors: {
    token: (state: TokenState) => state.token,
  },
});
