import { clsx } from 'clsx';
import { FC, ReactNode } from 'react';

type ChoiceProps = {
  description: string;
  icon: ReactNode;
  id: string;
  onSelect: () => void;
  title: string;
};

const Choice: FC<ChoiceProps> = ({
  description,
  icon,
  id,
  onSelect,
  title,
}) => {
  return (
    <button
      className={clsx(
        'flex flex-col items-center justify-center w-[367px] h-[200px]',
        'border border-gray-400 rounded-md px-4 mt-12',
        'hover:border-gray-500 cursor-pointer',
        'hover:bg-green-lightest focus:bg-green-lightest',
      )}
      data-testid={id}
      id={id}
      onClick={onSelect}
    >
      <div className='text-green-base w-11 h-11 mb-4'>{icon}</div>
      <div className='text-black text-sm'>{title}</div>
      <div className='text-gray-400 text-xs leading-5 text-center'>
        {description}
      </div>
    </button>
  );
};

const BinaryChoice: FC<{ left: ChoiceProps; right: ChoiceProps }> = ({
  left,
  right,
}) => {
  return (
    <div className='flex justify-center gap-8'>
      <Choice {...left} />
      <Choice {...right} />
    </div>
  );
};

export default BinaryChoice;
